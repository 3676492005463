import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { MoistureAnalyticsChart } from './MoistureAnalyticsChart';
import { MoistureAnalyticsFilters } from './MoistureAnalyticsFilters';
import { useGetMoistureAnalyticsQuery } from '../../api/analyticsApi';
import { MoistureAnalyticsTable } from './MoistureAnalyticsTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { splitValueOrEmptyArray } from '../analyticsHelper';

export const MoistureAnalytics = () => {
  const { filter } = useSelector((state: RootState) => state.moistureAnalytics);
  const queryData = useMemo(
    () => ({
      ...filter,
      consumedPackageNumber: splitValueOrEmptyArray(filter.consumedPackageNumber),
      purchaseDocumentNumber: splitValueOrEmptyArray(filter.purchaseDocumentNumber)
    }),
    [filter]
  );
  const { data, isLoading, isFetching } = useGetMoistureAnalyticsQuery(queryData);
  const isPackageSearch = !!(filter.consumedPackageNumber || filter.purchaseDocumentNumber)?.length;

  return (
    <Grid container>
      <MoistureAnalyticsFilters />
      <Grid container>
        <Grid item xs sx={{ height: '20vh', marginTop: '10px' }}>
          {!isLoading && <MoistureAnalyticsChart data={data} />}
        </Grid>
      </Grid>
      <Grid container>
        <MoistureAnalyticsTable
          data={data || []}
          isLoading={isFetching}
          isPackageSearch={isPackageSearch}
        />
      </Grid>
    </Grid>
  );
};
