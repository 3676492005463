import React from 'react';
import { Autocomplete, Chip, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResizingPopper } from './ResizingPopper';
import { ListboxComponent, renderAutocompleteSimpleRow } from './ListboxComponent';
import { concat } from 'lodash';

export type FilterProps = {
  id: string;
  label: string;
  loading?: boolean;
  width?: string;
  minWidth?: string;
  multiple?: boolean;
  onChange?: (event: any, value?: any) => void;
  onFocus?: (event: any) => void;
  optionLabelPrefix?: string;
  limitTags?: number;
  options?: boolean[] | string[] | number[];
  value?: boolean | (string | null | number)[] | string | null | number;
  extra?: boolean;
  text?: boolean;
  numeric?: boolean;
  disabled?: boolean;
  emptyOption?: boolean;
};

export const Filter = (props: FilterProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    id,
    label,
    loading,
    minWidth,
    multiple,
    onChange,
    optionLabelPrefix,
    options,
    value,
    text,
    width,
    numeric,
    onFocus,
    disabled,
    limitTags = 1,
    emptyOption
  } = props;

  const getOptionLabel = (option: any): string =>
    optionLabelPrefix && (option || option === false)
      ? t(`${optionLabelPrefix}${option}`)
      : option === null
        ? t('filter.none')
        : `${option}`;

  const getOptions = (): boolean[] | string[] =>
    emptyOption ? concat([null] as any, options || []) : options || [];

  return (
    <Grid item sx={{ minWidth }}>
      {text && (
        <TextField
          id={id}
          data-testid={id}
          disabled={disabled}
          label={label}
          size="small"
          sx={{ width }}
          type={numeric ? 'number' : undefined}
          value={value}
          onChange={onChange}
        />
      )}
      {!text && (
        <Autocomplete
          id={id}
          data-testid={id}
          disabled={disabled}
          multiple={multiple}
          size="small"
          limitTags={limitTags}
          options={loading ? [] : getOptions()}
          getOptionLabel={getOptionLabel}
          loading={loading}
          disableCloseOnSelect={multiple}
          renderOption={(props, option, { selected }) =>
            [props, getOptionLabel(option), selected] as React.ReactNode
          }
          renderInput={(params) => <TextField {...params} label={label} />}
          renderTags={(tagValue, getTagProps) => {
            const numTags = tagValue.length;

            return (
              <>
                {tagValue.slice(0, limitTags).map((option, index) => (
                  <Chip
                    sx={{ height: '24px', borderRadius: '4px' }}
                    {...getTagProps({ index })}
                    label={getOptionLabel(option)}
                  />
                ))}
                {numTags > limitTags && ` +${numTags - limitTags}`}
              </>
            );
          }}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          autoHighlight
          slots={{
            popper: ResizingPopper,
            listbox: (props) => (
              <ListboxComponent renderRow={renderAutocompleteSimpleRow} {...props} />
            )
          }}
        />
      )}
    </Grid>
  );
};
