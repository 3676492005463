import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthRequest, ChangePasswordRequest, ResetPasswordRequest } from '../auth/authSlice';
import { RootState } from '../../store';
import { Page } from '../../domain/Page';
import { Employee, EmployeeState, UpdateRoleRequest } from '../employee/employeeSlice';
import { UpdateUserRoleRequest, User, UserState } from '../user/userSlice';
import { Customer, CustomerState, UpdateUnitRequest } from '../customer/customerSlice';
import {
  CustomerItem,
  CustomerItemState,
  CustomerItemUpdateRequestWrapper
} from '../customerItem/customerItemSlice';
import { AttributeType } from '../../domain/AttributeType';
import { Address } from '../order/orderSlice';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth?.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'Customer',
    'CustomerItem',
    'Employee',
    'User',
    'Order',
    'OrderItem',
    'SalesOrderItem',
    'OrderItemsTotal',
    'ProductionOrder',
    'WorkStation',
    'Vendor'
  ],
  endpoints: (builder) => ({
    login: builder.mutation<string, AuthRequest>({
      query: (authRequest) => ({
        url: '/auth/login',
        method: 'POST',
        body: authRequest
      }),
      transformResponse(apiResponse, meta) {
        return meta?.response?.headers.get('Authorization') || '';
      }
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (resetPasswordRequest) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: resetPasswordRequest
      })
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (changePasswordRequest) => ({
        url: '/auth/change-password',
        method: 'POST',
        body: changePasswordRequest
      })
    }),
    invite: builder.mutation<void, number>({
      query: (personId) => ({
        url: `/auth/invite/${personId}`,
        method: 'POST'
      })
    }),
    getEmployees: builder.query<Page<Employee>, EmployeeState>({
      query: (params) => ({
        url: '/employees',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['Employee']
    }),
    updateRole: builder.mutation<void, UpdateRoleRequest>({
      query: (updateRoleRequest) => ({
        url: '/employees/update-role',
        method: 'POST',
        body: updateRoleRequest
      }),
      invalidatesTags: ['Employee']
    }),
    getEmployeeAutocomplete: builder.query<string[], string>({
      query: (fieldName) => ({
        url: `/employees/autocomplete/${fieldName}`
      })
    }),
    syncEmployees: builder.mutation<void, void>({
      query: () => ({
        url: '/admin/sync-employees',
        method: 'POST'
      }),
      invalidatesTags: ['Employee']
    }),
    getUsers: builder.query<Page<User>, UserState>({
      query: (params) => ({
        url: '/users',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['User']
    }),
    getUserAutocomplete: builder.query<string[], string>({
      query: (fieldName) => ({
        url: `/users/autocomplete/${fieldName}`
      })
    }),
    syncUsers: builder.mutation<void, void>({
      query: () => ({
        url: '/admin/sync-customers',
        method: 'POST'
      }),
      invalidatesTags: ['User']
    }),
    syncOrders: builder.mutation<void, void>({
      query: () => ({
        url: '/admin/sync-entire-order-flow',
        method: 'POST'
      })
    }),
    updateUserRole: builder.mutation<void, UpdateUserRoleRequest>({
      query: (updateUserRoleRequest) => ({
        url: '/users/update-role',
        method: 'POST',
        body: updateUserRoleRequest
      }),
      invalidatesTags: ['User']
    }),
    getCustomers: builder.query<Page<Customer>, CustomerState>({
      query: (params) => ({
        url: '/customers',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['Customer']
    }),
    getCustomerAutocomplete: builder.query<string[], string>({
      query: (fieldName) => ({
        url: `/customers/autocomplete/${fieldName}`
      })
    }),
    updateCustomerUnit: builder.mutation<void, UpdateUnitRequest>({
      query: (updateUnitRequest) => ({
        url: '/customers/update-unit',
        method: 'POST',
        body: updateUnitRequest
      }),
      invalidatesTags: ['Customer']
    }),
    getCustomerItems: builder.query<
      Page<CustomerItem>,
      CustomerItemState & { customerId?: string }
    >({
      query: (params) => ({
        url: '/customer-items',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: { customerId: params.customerId, ...params.filter }
      }),
      providesTags: ['CustomerItem']
    }),
    getCustomerItemAutocomplete: builder.query<
      string[],
      { fieldName: string; customerId?: string }
    >({
      query: (params) => ({
        url: `/customer-items/autocomplete/${params.fieldName}/${params.customerId}`
      })
    }),
    bulkUpdateCustomerItems: builder.mutation<void, CustomerItemUpdateRequestWrapper>({
      query: (request) => ({
        url: `/customer-items/${request.companyId}/bulk-update`,
        method: 'POST',
        body: request.body
      }),
      invalidatesTags: ['CustomerItem']
    }),
    getAttributeValues: builder.query<string[], AttributeType>({
      query: (type) => ({
        url: `/attributes/${type}/values`
      }),
      keepUnusedDataFor: 36000
    }),
    getCustomer: builder.query<Customer, number>({
      query: (companyId) => ({
        url: `/customers/${companyId}`
      }),
      providesTags: ['Customer']
    }),
    activateCustomer: builder.mutation<void, number>({
      query: (companyId) => ({
        url: `/customers/${companyId}/activate`,
        method: 'POST'
      }),
      invalidatesTags: ['Customer', 'User']
    }),
    getShipToAddresses: builder.query<Address[], void>({
      query: () => ({
        url: '/companies/ship-to-addresses'
      })
    })
  })
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useInviteMutation,
  useGetEmployeesQuery,
  useUpdateRoleMutation,
  useGetEmployeeAutocompleteQuery,
  useSyncEmployeesMutation,
  useGetUsersQuery,
  useGetUserAutocompleteQuery,
  useSyncUsersMutation,
  useSyncOrdersMutation,
  useUpdateUserRoleMutation,
  useGetCustomersQuery,
  useGetCustomerAutocompleteQuery,
  useUpdateCustomerUnitMutation,
  useGetCustomerItemsQuery,
  useBulkUpdateCustomerItemsMutation,
  useGetCustomerItemAutocompleteQuery,
  useLazyGetAttributeValuesQuery,
  useGetCustomerQuery,
  useActivateCustomerMutation,
  useGetShipToAddressesQuery
} = apiSlice;
