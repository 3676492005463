import { GridLocaleText } from '@mui/x-data-grid';

// Custom locale for et-EE, created based on https://mui.com/material-ui/guides/localization

export interface Localization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  };
}

export const getGridLocalization = (gridTranslations: Partial<GridLocaleText>): Localization => ({
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          ...gridTranslations,
          MuiTablePagination: {
            getItemAriaLabel: (type) => {
              if (type === 'first') {
                return 'Vali esimene lehekülg';
              }
              if (type === 'last') {
                return 'Vali viimane lehekülg';
              }
              if (type === 'next') {
                return 'Vali järgmine lehekülg';
              }
              // if (type === 'previous') {
              return 'Vali eelmine lehekülg';
            },
            labelRowsPerPage: 'Ridu leheküljel:',
            labelDisplayedRows: ({ from, to, count }) =>
              `${from}–${to} / ${count !== -1 ? count : `rohkem kui ${to}`}`
          }
        }
      }
    }
  }
});

const etEEGrid: Partial<GridLocaleText> = {
  // Root
  noRowsLabel: 'Tulemusi ei ole',
  noResultsOverlayLabel: 'Tulemusi ei leitud.',
  // errorOverlayDefaultLabel: 'Tehniline viga.',

  // Density selector toolbar button text
  toolbarDensity: 'Tihedus',
  toolbarDensityLabel: 'Tihedus',
  toolbarDensityCompact: 'Kompaktne',
  toolbarDensityStandard: 'Standardne',
  toolbarDensityComfortable: 'Mugav',

  // Columns selector toolbar button text
  toolbarColumns: 'Tulbad',
  toolbarColumnsLabel: 'Vali tulbad',

  // Filters toolbar button text
  toolbarFilters: 'Filtrid',
  toolbarFiltersLabel: 'Näita filtreid',
  toolbarFiltersTooltipHide: 'Peida filtrid',
  toolbarFiltersTooltipShow: 'Näita filtreid',
  toolbarFiltersTooltipActive: (count) => `Valitud filtreid: ${count}`,

  // Export selector toolbar button text
  toolbarExport: 'Ekspordi',
  toolbarExportLabel: 'Expordi',
  toolbarExportCSV: 'Lae alla CSV fail',
  toolbarExportPrint: 'Prindi',

  // Columns panel text
  // columnsPanelTextFieldLabel: 'Otsi tulp',
  // columnsPanelTextFieldPlaceholder: 'Otsi tulp',
  // columnsPanelDragIconLabel: 'Järjesta tulbad',
  // columnsPanelShowAllButton: 'Näita kõiki',
  // columnsPanelHideAllButton: 'Peida kõik',

  // Filter panel text
  filterPanelAddFilter: 'Lisa filter',
  filterPanelDeleteIconLabel: 'Kustuta',
  // filterPanelLinkOperator: 'Loogikatehe',
  // filterPanelOperators: 'Tehe',
  filterPanelOperatorAnd: 'Ja',
  filterPanelOperatorOr: 'Või',
  filterPanelColumns: 'Tulbad',
  filterPanelInputLabel: 'Väärtus',
  filterPanelInputPlaceholder: 'Filtri väärtus',

  // Filter operators text
  filterOperatorContains: 'sisaldab',
  filterOperatorEquals: 'võrdub',
  filterOperatorStartsWith: 'algab',
  filterOperatorEndsWith: 'lõpeb',
  filterOperatorIs: 'on',
  filterOperatorNot: 'ei ole',
  filterOperatorAfter: 'on hiljem kui',
  filterOperatorOnOrAfter: 'on sama või hiljem kui',
  filterOperatorBefore: 'on enne',
  filterOperatorOnOrBefore: 'on sama või enne kui',
  filterOperatorIsEmpty: 'on tühi',
  filterOperatorIsNotEmpty: 'ei ole tühi',
  filterOperatorIsAnyOf: 'on üks väärtustest',

  // Filter values text
  filterValueAny: 'ükskõik milline',
  filterValueTrue: 'tõene',
  filterValueFalse: 'väär',

  // Column menu text
  columnMenuLabel: 'Menüü',
  columnMenuShowColumns: 'Kuva tulpasi',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Peida',
  columnMenuUnsort: 'Eemalda sorteerimine',
  columnMenuSortAsc: 'Sorteeri kasvavalt',
  columnMenuSortDesc: 'Sorteeri kahanevalt',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktiivset filtrit` : `${count} aktiivne filter`,
  columnHeaderFiltersLabel: 'Näita Filtreid',
  columnHeaderSortIconLabel: 'Sorteeri',

  // Rows selected footer text
  footerRowSelected: (count) => `${count.toLocaleString()} rida valitud`,

  // Total row amount footer text
  footerTotalRows: 'Ridu kokku:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} ${totalCount.toLocaleString()}st`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox selection',
  checkboxSelectionSelectAllRows: 'Vali kõik read',
  checkboxSelectionUnselectAllRows: 'Tühista kõik valikud',
  checkboxSelectionSelectRow: 'Vali rida',
  checkboxSelectionUnselectRow: 'Tühista rea valik',

  // Boolean cell text
  booleanCellTrueLabel: 'jah',
  booleanCellFalseLabel: 'ei',

  // Actions cell more text
  actionsCellMore: 'veel',

  // Column pinning text
  pinToLeft: 'Kinnita vasakule',
  pinToRight: 'Kinnita paremale',
  unpin: 'Eemalda kinnitus',

  // Tree Data
  treeDataGroupingHeaderName: 'Grupp',
  treeDataExpand: 'vaata alamelemente',
  treeDataCollapse: 'peida alamelemendid',

  // Grouping columns
  groupingColumnHeaderName: 'Grupp',
  groupColumn: (name) => `Grupeeri ${name} järgi`,
  unGroupColumn: (name) => `Eemalda grupeering ${name} järgi`,

  // Master/detail
  expandDetailPanel: 'Laienda',
  collapseDetailPanel: 'Tõmba kokku'
};

export const gridEtEE: Localization = getGridLocalization(etEEGrid);
