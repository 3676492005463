import { Button, Grid, Icon } from '@mui/material';
import { RawMaterialAnalyticsDetailsFilter } from './RawMaterialAnalyticsDetailsFilter';
import React, { useMemo, useState } from 'react';
import { RawMaterialAnalyticsDetailsQuantityChart } from './RawMaterialAnalyticsDetailsQuantityChart';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useGetRawMaterialAnalyticsComparisonQuery,
  useGetVendorsQuery
} from '../../../api/analyticsApi';
import { GroupedVendor, VendorsComparisonRequest } from '../../index';
import { RawMaterialAnalyticsDetailsMoistureChart } from './RawMaterialAnalyticsDetailsMoistureChart';
import { mapByVendor, mapVendorGroup } from './RawMaterialAnalyticsDetailsHelper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { RawMaterialAnalyticsFilter } from '../rawMaterialAnalyticsSlice';
import { RawMaterialAnalyticsPrimaryPercentageChart } from './RawMaterialAnalyticsDetaisPrimaryPercentageChart';
import { useAllVendorsMap } from '../../hooks/useAllVendorsMap';

export type RawMaterialAnalyticsDetailsFilterState = {
  startDate?: string;
  endDate?: string;
  vendors: string[];
};

type SearchParamsValues = {
  type: 'vendors' | 'saw-mills';
  vendor: string;
  species: string;
  thickness: string;
  width: string;
  startDate: string;
  endDate: string;
};

const buildVendorComparisonRequest = (
  params: SearchParamsValues,
  filter: RawMaterialAnalyticsFilter,
  detailsFilter: RawMaterialAnalyticsDetailsFilterState,
  parentVendor?: GroupedVendor
): VendorsComparisonRequest => {
  return {
    ...filter,
    startDate: detailsFilter.startDate,
    endDate: detailsFilter.endDate,
    vendors: params.type === 'saw-mills' ? parentVendor?.children?.map((ch) => ch.id) : undefined,
    consumedSpecies: params.species,
    consumedWidth: Number(params.width),
    consumedThickness: Number(params.thickness)
  } as VendorsComparisonRequest;
};

export const RawMaterialAnalyticsDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const state = useSelector((state: RootState) => state.rawMaterialAnalytics);
  const { data: vendors, isLoading: vendorsLoading } = useGetVendorsQuery();
  const params = useMemo(
    () =>
      ({
        type: searchParams.get('type'),
        vendor: searchParams.get('vendor'),
        species: searchParams.get('species'),
        thickness: searchParams.get('thickness'),
        width: searchParams.get('width')
      }) as SearchParamsValues,
    [searchParams]
  );
  const [filterState, setFilterState] = useState<RawMaterialAnalyticsDetailsFilterState>({
    vendors: [],
    startDate: state.filter.startDate || '',
    endDate: state.filter.endDate || ''
  });
  const parentVendor = useMemo(
    () => (params.type === 'saw-mills' ? vendors?.find((v) => v.id === params.vendor) : undefined),
    [params.type, params.vendor, vendors]
  );
  const requestData = useMemo(
    () => buildVendorComparisonRequest(params, state.filter, filterState, parentVendor),
    [params, state.filter, filterState, parentVendor]
  );
  const { data, isLoading } = useGetRawMaterialAnalyticsComparisonQuery(requestData, {
    skip: vendorsLoading
  });
  const vendorsMap = useAllVendorsMap();

  const vendorChartData = useMemo(() => {
    if (data?.length && params.type === 'vendors') {
      return mapByVendor(data, vendorsMap, params.vendor || '', filterState.vendors);
    } else if (data?.length && params.type === 'saw-mills') {
      return mapVendorGroup(data);
    } else {
      return {};
    }
  }, [data, params, filterState.vendors, vendorsMap]);

  return (
    <Grid container>
      <Grid item>
        <Grid container>
          <Grid item>
            <Button
              variant="text"
              className="link"
              style={{ minWidth: 'auto' }}
              onClick={() => navigate(-1)}
            >
              <Icon>arrow_back</Icon>
            </Button>
          </Grid>
          <Grid item>
            <h2>
              <b>
                {t('analytics.rawMaterial.input')}: {params.species}/{params.thickness}x
                {params.width}
              </b>{' '}
              | {params.vendor}
            </h2>
          </Grid>
        </Grid>
      </Grid>
      <RawMaterialAnalyticsDetailsFilter
        filter={filterState}
        search={(newState) => setFilterState(newState)}
        mainVendor={params.type === 'vendors' ? params.vendor : undefined}
      />
      <RawMaterialAnalyticsDetailsQuantityChart isLoading={isLoading} data={vendorChartData} />
      <RawMaterialAnalyticsPrimaryPercentageChart isLoading={isLoading} data={vendorChartData} />
      <RawMaterialAnalyticsDetailsMoistureChart isLoading={isLoading} data={vendorChartData} />
    </Grid>
  );
};
