import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import { RouteType } from '../domain/RouteType';

export const AuthProvider = (props: PropsWithChildren<any>): JSX.Element => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const isLogin = useMatch(RouteType.LOGIN);
  const isNewPassword = useMatch(RouteType.NEW_PASSWORD);

  useEffect(() => {
    if (!isLogin && !isNewPassword && !user) {
      navigate(RouteType.LOGIN);
    }
  });

  return <>{(isLogin || isNewPassword || user) && props.children}</>;
};
