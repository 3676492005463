import { Button, Grid, Paper, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useChangePasswordMutation } from '../api/apiSlice';
import { Loader } from '../../components/Loader';
import BackgroundImage from '../../theme/background.jpeg';
import { RouteType } from '../../domain/RouteType';

export const NewPassword = (): JSX.Element => {
  const { verificationToken } = useParams();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onChangePasswordSubmit = (changePasswordRequest: { [key: string]: string }) => {
    changePassword({ verificationToken, newPassword: changePasswordRequest.password })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('newPassword.success'), { variant: 'success' });
        navigate(RouteType.LOGIN);
      })
      .catch(() => {});
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '100vh'
        }}
      >
        <Grid item>
          <Paper elevation={8} className="padding-16" sx={{ width: '500px' }}>
            <form onSubmit={handleSubmit(onChangePasswordSubmit)} noValidate>
              <Grid container direction="column">
                <Grid item className="text-align-center">
                  <img width={474} height={73} src={'/logo_dark.png'} alt="Puidukoda" />
                </Grid>
                <Grid item className="margin-top-16 font-24-regular">
                  {t('newPassword.title')}
                </Grid>
                <Grid item className="margin-top-16">
                  <TextField
                    id="password"
                    type="password"
                    label={t('newPassword.password')}
                    required
                    fullWidth
                    error={!!errors.password}
                    helperText={(errors.password?.message || ' ') as React.ReactNode}
                    {...register('password', {
                      required: true,
                      validate: (password: string) => {
                        if (password.length < 8) {
                          return t('validation.minLength', { minLength: 8 });
                        } else if (!password.match(/^\S+$/)) {
                          return t('validation.noWhitespace');
                        } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)) {
                          return t('validation.password');
                        }
                      }
                    })}
                  />
                </Grid>
                <Grid item className="margin-top-16">
                  <TextField
                    id="confirmPassword"
                    type="password"
                    label={t('newPassword.confirmPassword')}
                    required
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={(errors.confirmPassword?.message || ' ') as React.ReactNode}
                    {...register('confirmPassword', {
                      required: true,
                      validate: (confirmPassword: string) => {
                        if (watch('password') !== confirmPassword) {
                          return t('validation.passwordMatch');
                        }
                      }
                    })}
                  />
                </Grid>
                <Grid item>
                  <Button fullWidth type="submit" className="margin-top-16">
                    {t('newPassword.submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
