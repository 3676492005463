import { Button, Grid, Icon } from '@mui/material';
import { Children, PropsWithChildren, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FilterAreaProps {
  disabled?: boolean;
  labelKey?: string;
  onClear?: () => void;
  extra?: JSX.Element | ReactNode;
}

export const FilterArea = (props: PropsWithChildren<any> & FilterAreaProps): JSX.Element => {
  const { t } = useTranslation();
  const { children, disabled, labelKey, onClear, extra } = props;
  const [showMore, setShowMore] = useState(false);
  const extraFilters = Children.toArray(children).some((child: any) => child.props?.extra);

  return (
    <>
      <Grid item xs>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h2>{t(`${labelKey || 'filter.title'}`)}</h2>
          </Grid>
          <Grid item alignSelf="center">
            {extra}
            {onClear && (
              <Button
                variant="text"
                className="link"
                onClick={onClear}
                startIcon={
                  <Icon className="margin-top-4">
                    <i className="ri-filter-off-line" />
                  </Icon>
                }
                disabled={disabled}
              >
                {t('filter.clear')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.25}>
        {extraFilters && (
          <Button
            sx={{ height: '42px', width: '100px', marginTop: '8px', marginLeft: '8px' }}
            variant="text"
            className="link"
            onClick={() => setShowMore(!showMore)}
            startIcon={
              <Icon className="margin-top-4">
                {showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Icon>
            }
          >
            {t(`filter.${showMore ? 'less' : 'more'}`)}
          </Button>
        )}
        {Children.toArray(children).filter((child: any) => !child.props?.extra || showMore)}
      </Grid>
    </>
  );
};
