import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Button, Chip, Grid, Icon, IconButton, Switch, Typography } from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid-pro';
import {
  useGetUserAutocompleteQuery,
  useGetUsersQuery,
  useInviteMutation,
  useSyncOrdersMutation,
  useSyncUsersMutation,
  useUpdateUserRoleMutation
} from '../api/apiSlice';
import {
  clearFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setSort,
  UserFilter
} from './userSlice';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { StatusType } from '../../domain/StatusType';
import { useSnackbar } from 'notistack';
import { Filter } from '../../components/Filter';
import { FilterArea } from '../../components/FilterArea';
import { Loader } from '../../components/Loader';

export const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.users);
  const { data, isUninitialized, isLoading, isFetching } = useGetUsersQuery({
    page,
    pageSize,
    sort,
    filter
  });
  const [updateUserRole, { isLoading: isUpdateLoading }] = useUpdateUserRoleMutation();
  const { data: nameOptions, isFetching: isNameOptionsLoading } =
    useGetUserAutocompleteQuery('name');
  const { data: emailOptions, isFetching: isEmailOptionsLoading } =
    useGetUserAutocompleteQuery('email');
  const { data: customerOptions, isFetching: isCustomerOptionsLoading } =
    useGetUserAutocompleteQuery('customer');
  const { data: salespersonOptions, isFetching: isSalespersonOptionsLoading } =
    useGetUserAutocompleteQuery('salesperson');
  const [syncOrders, { isLoading: isOrdersSyncLoading }] = useSyncOrdersMutation();
  const [syncUsers, { isLoading: isUsersSyncLoading }] = useSyncUsersMutation();
  const [invite, { isLoading: isInviteLoading }] = useInviteMutation();

  const onUpdateRole = (employeeId: number, isSpectator: boolean) => {
    updateUserRole({ employeeId, isSpectator })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('users.updateRoleSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onSyncOrders = () => {
    syncOrders()
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('users.ordersSyncSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onSyncUsers = () => {
    syncUsers()
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('users.usersSyncSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onInvite = (personId: number) => {
    invite(personId)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('users.inviteSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const isPending: GridValueGetter = (value, row, column, apiRef): boolean =>
    row.status === StatusType.PENDING && row.customerStatus === StatusType.ACTIVE && row.email;

  const handleFilterChange = (
    field: keyof UserFilter,
    event: any,
    value: string[] | StatusType[]
  ) => dispatch(setFilterValue({ field, value }));

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('users.table.name'), flex: 2 },
    { field: 'email', headerName: t('users.table.email'), flex: 2 },
    { field: 'phone', headerName: t('users.table.phone'), flex: 2 },
    { field: 'customer', headerName: t('users.table.customer'), flex: 2 },
    { field: 'salesperson', headerName: t('users.table.salesperson'), flex: 1 },
    {
      field: 'isSpectator',
      headerName: t('users.table.isSpectator'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <Switch
          checked={params.value}
          size="small"
          data-testid={`switch-${params.row.id}`}
          onClick={(event: any) => onUpdateRole(params.row.employeeId, event.target.checked)}
        />
      )
    },
    {
      field: 'status',
      headerName: t('users.table.status'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, StatusType>) => (
        <Chip label={t(`statusType.${params.value}`)} size="small" variant="filled" />
      )
    },
    {
      field: 'isPending',
      headerName: '',
      flex: 0.5,
      align: 'right',
      sortable: false,
      valueGetter: isPending,
      renderCell: (params: GridRenderCellParams<any, boolean>) =>
        params.value && (
          <IconButton onClick={() => onInvite(params.row.id)}>
            <Icon>email</Icon>
          </IconButton>
        )
    }
  ];

  return (
    <Grid container>
      {(isUninitialized ||
        isUpdateLoading ||
        isOrdersSyncLoading ||
        isUsersSyncLoading ||
        isInviteLoading) && <Loader />}
      <FilterArea onClear={() => dispatch(clearFilter())}>
        <Filter
          id="name"
          label={t('users.table.name')}
          loading={isNameOptionsLoading}
          minWidth="260px"
          multiple
          options={nameOptions}
          value={filter.name || []}
          onChange={handleFilterChange.bind(this, 'name')}
        />
        <Filter
          id="email"
          label={t('users.table.email')}
          loading={isEmailOptionsLoading}
          minWidth="260px"
          multiple
          options={emailOptions}
          value={filter.email || []}
          onChange={handleFilterChange.bind(this, 'email')}
        />
        <Filter
          id="customer"
          label={t('users.table.customer')}
          loading={isCustomerOptionsLoading}
          minWidth="260px"
          multiple
          options={customerOptions}
          value={filter.customer || []}
          onChange={handleFilterChange.bind(this, 'customer')}
        />
        <Filter
          id="salesperson"
          label={t('users.table.salesperson')}
          loading={isSalespersonOptionsLoading}
          minWidth="260px"
          multiple
          options={salespersonOptions}
          value={filter.salesperson || []}
          onChange={handleFilterChange.bind(this, 'salesperson')}
        />
        <Filter
          id="isSpectator"
          label={t('users.table.isSpectator')}
          minWidth="180px"
          optionLabelPrefix="boolean."
          options={[true, false]}
          value={filter.isSpectator !== undefined ? filter.isSpectator : null}
          onChange={handleFilterChange.bind(this, 'isSpectator')}
        />
        <Filter
          id="status"
          label={t('users.table.status')}
          minWidth="180px"
          multiple
          optionLabelPrefix="statusType."
          options={Object.values(StatusType)}
          value={filter.status || []}
          onChange={handleFilterChange.bind(this, 'status')}
        />
      </FilterArea>

      <Grid container justifyContent="space-between" alignItems="center" className="margin-top-16">
        <Grid item>
          <h2>{t('users.table.title')}</h2>
        </Grid>

        <Grid item className="margin-bottom-8">
          <Button
            onClick={() => onSyncOrders()}
            startIcon={<Icon>cached</Icon>}
            className="margin-right-8"
          >
            <Typography variant="button">{t('users.table.syncOrders')}</Typography>
          </Button>

          <Button onClick={() => onSyncUsers()} startIcon={<Icon>cached</Icon>}>
            <Typography variant="button">{t('users.table.syncUsers')}</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs sx={{ height: '65vh' }}>
          <DataGridPro
            loading={isLoading || isFetching}
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            filterMode="server"
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={sort}
            columnHeaderHeight={46}
            rowHeight={40}
            rowCount={data?.totalElements || 0}
            rows={(data?.content || []) as GridRowsProp}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            disableVirtualization={process.env.NODE_ENV === 'test'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
