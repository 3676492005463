import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon
} from '@mui/material';
import { Loader } from '../../components/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useActivateCustomerMutation, useGetCustomerQuery } from '../api/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteType } from '../../domain/RouteType';
import { StatusType } from '../../domain/StatusType';

export const CustomerItemsHeader = (): JSX.Element => {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activateCustomer, { isLoading: isActivateCompanyLoading }] = useActivateCustomerMutation();
  const { data: customer } = useGetCustomerQuery(+customerId!);

  const onActivateCustomer = () => {
    activateCustomer(+customerId!)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('items.activationSuccess'), { variant: 'success' });
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        paddingBottom: '8px',
        borderBottom: '1px solid #D9D9D6'
      }}
    >
      {isActivateCompanyLoading && <Loader />}
      <Grid item>
        <Button variant="text" className="link" onClick={() => navigate(RouteType.CUSTOMERS)}>
          <Icon>arrow_back</Icon>
        </Button>
      </Grid>
      <Grid item xs className="font-20-bold margin-left-8">
        {customer?.name}
      </Grid>
      <Grid item>
        {customer?.status === StatusType.PENDING && (
          <Button onClick={() => setOpen(true)}>{t('items.activateAccess')}</Button>
        )}
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{t('items.activateAccess')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('items.alert.activateAccessMessage')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="outlined">
              {t('button.cancel')}
            </Button>
            <Button
              autoFocus
              onClick={() => {
                setOpen(false);
                onActivateCustomer();
              }}
            >
              {t('items.alert.agree')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};
