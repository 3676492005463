import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { formatNumberScale } from '../../../utils/textFormatUtils';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance
} from 'material-react-table';
import { Button, Grid, Icon } from '@mui/material';
import { getExpandedRowModel } from '../reactMaterialTableHack';
import { setPage, setPageSize } from './rawMaterialAnalyticsSlice';
import { PaginationState } from '@tanstack/table-core';
import { Page } from '../../../domain/Page';
import { RawMaterialGroupedRow } from '../index';
import { uniqueFilter } from '../../../utils/mathUtils';
import { useNavigate } from 'react-router-dom';
import { listFilter, materialReactTableBaseProps, sum } from '../analyticsHelper';
import { TreeFilter } from './TreeFilter';
import { TreeViewBaseItem } from '@mui/x-tree-view';

const getItemGroup = (row: RawMaterialGroupedRow) =>
  `${row.species}/${row.actualThickness}x${row.actualWidth}`;

const getFilteredRows = (
  table: MRT_TableInstance<RawMaterialGroupedRow>
): RawMaterialGroupedRow[] => table.getFilteredRowModel().rows.map((row) => row.original);

const getVendorsListFromRows = (rows?: RawMaterialGroupedRow[]): TreeViewBaseItem[] => {
  const map = new Map<string, TreeViewBaseItem>();

  const recursion = (row: RawMaterialGroupedRow) => {
    const vendorGroup = row.vendorGroup;

    if (vendorGroup) {
      const children = row.children
        ?.filter((r) => !!r)
        .map((r) => ({
          id: r.vendor,
          label: r.vendor
        }));
      const entry = map.get(vendorGroup) || { id: vendorGroup, label: vendorGroup, children };
      map.set(vendorGroup, entry);
    }

    row.children?.forEach((ch) => recursion(ch));
  };

  rows?.forEach(recursion);
  return Array.from(map.values());
};

export const RawMaterialAnalyticsTable = ({
  data,
  isLoading
}: {
  data?: Page<RawMaterialGroupedRow>;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { page, pageSize } = useSelector((state: RootState) => state.rawMaterialAnalytics);
  const rows = useMemo(() => data?.content, [data?.content]);
  const groupedVendorValues = useMemo(() => getVendorsListFromRows(rows), [rows]);

  const onButtonClick = useCallback(
    (type: 'saw-mills' | 'vendors', row: MRT_Row<RawMaterialGroupedRow>) => {
      const getTopLevelRow = (
        r: MRT_Row<RawMaterialGroupedRow> | null
      ): MRT_Row<RawMaterialGroupedRow> | null => {
        const p = r?.getParentRow();
        return p ? getTopLevelRow(p) : r;
      };

      const topLevelParent = getTopLevelRow(row)?.original;
      const url = `/raw-material-analytics/comparison?type=${type}&vendor=${row.original.vendorGroup}&species=${topLevelParent?.species}&thickness=${topLevelParent?.actualThickness}&width=${topLevelParent?.actualWidth}`;

      navigate(url);
    },
    [navigate]
  );

  const columns = useMemo(
    () =>
      [
        {
          id: 'group',
          accessorKey: 'group',
          enableColumnFilter: true,
          filterFn: listFilter(getItemGroup),
          header: t('analytics.rawMaterial.input'),
          filterSelectOptions:
            data?.content?.map((r) => getItemGroup(r)).filter(uniqueFilter) || [],
          Cell: ({ row: { original, depth } }) => (depth === 0 ? getItemGroup(original) : ''),
          Footer: t('analytics.total')
        },
        {
          id: 'vendor',
          accessorKey: 'vendor',
          enableColumnFilter: true,
          header: t('analytics.vendor'),
          filterFn: listFilter((r) => r.vendorGroup || r.vendor),
          Filter: (filterProps) => <TreeFilter {...filterProps} values={groupedVendorValues} />,
          Cell: ({ row: { original, depth, getParentRow } }) => {
            const isParentVendorGroup = !!getParentRow()?.original?.vendorGroup;
            if (original.vendorGroup) {
              return <span>{original.vendorGroup}</span>;
            } else if (original.vendor) {
              return (
                <span style={{ marginLeft: `${isParentVendorGroup ? 20 : 0}px` }}>
                  {original.vendor}
                </span>
              );
            } else {
              return '';
            }
          }
        },
        {
          id: 'qualities',
          enableColumnFilter: true,
          header: t('analytics.quality'),
          filterSelectOptions: data?.content?.map((r) => r.quality).filter(uniqueFilter) || [],
          filterFn: listFilter('quality'),
          Cell: ({ row: { original, subRows } }) => {
            if (original.vendor) {
              return original.quality;
            } else if (original.vendorGroup) {
              return subRows
                ?.map((ch) => ch.original?.quality)
                .filter(uniqueFilter)
                .join('; ');
            }
          }
        },
        {
          id: 'primaryM3',
          header: `${t('analytics.primaryQuality')} (m3)`,
          accessorFn: (row) => `${formatNumberScale(row.primaryProducedM3 || 0)} m3`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            return `${formatNumberScale(filteredRows?.map((r) => r.primaryProducedM3 || 0).reduce(sum, 0))} m3`;
          }
        },
        {
          id: 'primaryPercentage',
          header: `${t('analytics.primaryQuality')} (%)`,
          accessorFn: (row) => `${formatNumberScale(row.primaryPercentage || 0)} %`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const totalPrimary =
              filteredRows?.map((r) => r.consumedAsPrimaryM3).reduce(sum, 0) || 0;
            const totalConsumed = filteredRows?.map((r) => r.totalConsumedM3).reduce(sum, 0) || 0;

            return `${totalConsumed > 0 ? formatNumberScale((totalPrimary * 100) / totalConsumed) : 0} %`;
          }
        },
        {
          id: 'avgPrimaryMoisture',
          header: `${t('analytics.moisture')} (%)`,
          accessorFn: (row) => `${formatNumberScale(row.avgMoisture || 0)} %`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const moistureAgg =
              filteredRows?.map((r) => r.avgMoisture * r.totalConsumedM3).reduce(sum, 0) || 0;
            const totalConsumed = filteredRows?.map((r) => r.totalConsumedM3).reduce(sum, 0) || 0;

            return `${totalConsumed > 0 ? formatNumberScale(moistureAgg / totalConsumed) : 0} %`;
          }
        },
        {
          id: 'avgLength',
          header: `${t('analytics.avgLength')} (m)`,
          accessorFn: (row) => `${formatNumberScale(row.avgLength || 0, 1)} m`,
          Footer: ({ table }) => {
            const filteredRows = table.getFilteredRowModel().rows.map((row) => row.original);
            const lengthAgg =
              filteredRows?.map((r) => r.avgLength * r.totalConsumedM3).reduce(sum, 0) || 0;
            const totalConsumed = filteredRows?.map((r) => r.totalConsumedM3).reduce(sum, 0) || 0;

            return `${totalConsumed > 0 ? formatNumberScale(lengthAgg / totalConsumed, 1) : 0} m`;
          }
        },
        {
          id: 'buttons',
          enableColumnActions: false,
          enableResizing: false,
          size: 110,
          Cell: ({ row }) => {
            const isVendor = !!row.original.vendorGroup;
            const showCompareSawMillsButton = row.original.children?.some((ch) => !!ch.vendor);

            if (!isVendor) {
              return;
            }

            return (
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                {isVendor && (
                  <Button
                    key="compare_vendors"
                    size="small"
                    variant="outlined"
                    onClick={() => onButtonClick('vendors', row)}
                  >
                    DETAIL
                  </Button>
                )}
                {showCompareSawMillsButton && (
                  <Button
                    key="compare_saw_mills"
                    variant="text"
                    className="link"
                    onClick={() => onButtonClick('saw-mills', row)}
                  >
                    <Icon>visibility</Icon>
                  </Button>
                )}
              </div>
            );
          }
        }
      ] as MRT_ColumnDef<RawMaterialGroupedRow>[],
    [t, data?.content, onButtonClick, groupedVendorValues]
  );

  return (
    <Grid container className="margin-top-40">
      <Grid item xs sx={{ height: '40vh' }}>
        <MaterialReactTable<RawMaterialGroupedRow>
          {...materialReactTableBaseProps}
          data={rows || []}
          columns={columns}
          enableStickyFooter
          enableRowVirtualization
          rowCount={data?.totalElements}
          muiTableContainerProps={{ sx: { maxHeight: '70vh' } }}
          getExpandedRowModel={getExpandedRowModel}
          getRowCanExpand={(row) => !!row.subRows?.length}
          getSubRows={(row) =>
            row.children?.length ? Array.from(row.children?.values()) : undefined
          }
          onPaginationChange={(updater: any) => {
            const newValue: PaginationState = updater({
              pageIndex: page,
              pageSize: pageSize
            } as PaginationState);
            dispatch(setPage(newValue.pageIndex));
            dispatch(setPageSize(newValue.pageSize));
          }}
          state={{
            pagination: { pageIndex: page, pageSize: pageSize },
            isLoading: isLoading
          }}
        />
      </Grid>
    </Grid>
  );
};
