import { hasAnyPermission } from '../utils/authUtils';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { Permission } from '../domain/Permission';

export const usePermission = (permission: Permission) => {
  const { user } = useSelector((state: RootState) => state.auth);

  return {
    hasPermission: hasAnyPermission([permission], user?.permissions)
  };
};
