import { useGetVendorsQuery } from '../../api/analyticsApi';
import { useMemo } from 'react';
import { GroupedVendor } from '../index';

export const useAllVendorsMap = (): Map<string, GroupedVendor> => {
  const { data: vendors } = useGetVendorsQuery();
  return useMemo(() => {
    const map = new Map<string, GroupedVendor>();

    vendors
      ?.flatMap((v) => (v?.children?.length ? [v, ...v.children] : v))
      .forEach((v) => map.set(v.id, v));

    return map;
  }, [vendors]);
};
