import React, { ReactElement, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { OrdersFilter } from './OrdersFilter';
import { Loader } from '../../components/Loader';
import { setPage, setPageSize, setSort } from './orderSlice';
import { OrderStatusType } from '../../domain/OrderStatusType';
import {
  useCreateDuplicateOrderMutation,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrdersQuery
} from '../api/orderApi';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { OrderStatus } from './OrderStatus';
import { formatDateString } from '../../utils/dateUtils';
import { RouteType } from '../../domain/RouteType';
import { GridActionsCellItemProps } from '@mui/x-data-grid/components/cell/GridActionsCellItem';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { useSnackbar } from 'notistack';
import { usePermission } from '../../hooks/usePermission';
import { Permission } from '../../domain/Permission';

export const Orders = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.orders);
  const { data, isUninitialized, isLoading, isFetching } = useGetOrdersQuery({
    page,
    pageSize,
    sort,
    filter
  });
  const { hasPermission: canEdit } = usePermission(Permission.ORDER_MANAGEMENT);

  const [createNewOrder, { isLoading: isCreateOrderLoading }] = useCreateOrderMutation();
  const addOrder = () => {
    createNewOrder(undefined)
      .unwrap()
      .then((orderId) => {
        navigate(generatePath(RouteType.ORDER_DETAILS, { orderId: `${orderId}` }));
      })
      .catch(() => {});
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [rowOrderId, setRowOrderId] = useState<number>();
  const [deleteOrder, { isLoading: isDeleting }] = useDeleteOrderMutation();
  const [duplicateOrder, { isLoading: isDuplicating }] = useCreateDuplicateOrderMutation();
  const handleEventDeleteOrder = () => {
    if (rowOrderId) {
      deleteOrder(rowOrderId)
        .unwrap()
        .then(() => {
          enqueueSnackbar(t('order.successfulDelete'), { variant: 'success' });
        })
        .catch(() => {});
    }
  };

  const openConfirmDeleteDialog = (orderId: number) => {
    setConfirmDialogOpen(true);
    setRowOrderId(orderId);
  };

  const handleDuplicateOrder = (orderId: number) => {
    duplicateOrder(orderId)
      .unwrap()
      .then((duplicatedOrderId) => {
        navigate(generatePath(RouteType.ORDER_DETAILS, { orderId: `${duplicatedOrderId}` }));
        enqueueSnackbar(t('order.successfulDuplication'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: t('orders.table.orderNumber'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (
          (params.row.status === OrderStatusType.DRAFT ||
            params.row.status === OrderStatusType.AWAITS_RESPONSE) &&
          !canEdit
        ) {
          return t('orders.tbd');
        }

        return (
          <Link to={'' + params.row.id} className="link">
            {params.value || t('orders.tbd')}
          </Link>
        );
      }
    },
    { field: 'quoteNumber', headerName: t('orders.table.quoteNumber'), flex: 1 },
    { field: 'internalNumber', headerName: t('orders.table.internalNumber'), flex: 1 },
    {
      field: 'createdAt',
      flex: 1,
      headerName: t('orders.table.createdAt'),
      valueGetter: (value ) => formatDateString(value)
    },
    { field: 'createdBy', headerName: t('orders.table.createdBy'), flex: 1 },
    { field: 'address', headerName: t('orders.table.address'), flex: 2 },
    {
      field: 'plannedDelivery',
      flex: 1,
      headerName: t('orders.table.plannedDelivery'),
      valueGetter: (value, row, column, apiRef) => formatDateString(value)
    },
    {
      field: 'status',
      flex: 1,
      headerName: t('orders.table.status'),
      renderCell: (params: GridRenderCellParams<any, OrderStatusType>) => (
        <OrderStatus status={params.value} />
      )
    },
    {
      field: 'availability',
      flex: 1,
      headerName: t('orders.table.availability'),
      renderCell: (params: GridRenderCellParams<any, number>) =>
        params.row.orderNumber ? `${params.value}%` : ''
    }
  ];

  const actionsColumn: GridActionsColDef[] = [
    {
      field: 'actions',
      flex: 1,
      type: 'actions',
      getActions: (params: GridRowParams) => {
        const actions: ReactElement<GridActionsCellItemProps>[] = [];
        if (params.row.status === OrderStatusType.DRAFT && user?.sub === params.row.createdBy) {
          actions.push(
            <GridActionsCellItem
              label={t('orders.action.delete')}
              onClick={() => openConfirmDeleteDialog(params.row.id)}
              showInMenu
            />
          );
        }
        if (params.row.status === OrderStatusType.AWAITS_RESPONSE) {
          actions.push(<GridActionsCellItem label={t('orders.action.discard')} showInMenu />);
        }
        if (params.row.status !== OrderStatusType.DRAFT) {
          actions.push(
            <GridActionsCellItem
              label={t('orders.action.duplicate')}
              onClick={() => handleDuplicateOrder(params.row.id)}
              showInMenu
            />
          );
        }

        return actions;
      }
    }
  ];

  return (
    <Grid container>
      {(isUninitialized || isCreateOrderLoading || isDeleting || isDuplicating) && <Loader />}
      <OrdersFilter />

      <Grid container className="margin-top-8 margin-bottom-8">
        <Grid item xs>
          <h2>{t('orders.table.title')}</h2>
        </Grid>

        <Grid item alignSelf="center">
          {canEdit && <Button onClick={() => addOrder()}>{t('orders.table.add')}</Button>}
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t('order.delete')}
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => handleEventDeleteOrder()}
      >
        {t('order.deleteOrderDialog')}
      </ConfirmDialog>

      <Grid container>
        <Grid item xs sx={{ height: '60vh' }}>
          <DataGridPro
            columns={[...columns, ...(canEdit ? actionsColumn : [])]}
            disableColumnMenu
            disableRowSelectionOnClick
            disableVirtualization={process.env.NODE_ENV === 'test'}
            filterMode="server"
            columnHeaderHeight={46}
            loading={isLoading || isFetching}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            pagination
            paginationMode="server"
            rowHeight={40}
            rowCount={data?.totalElements || 0}
            rows={(data?.content || []) as GridRowsProp}
            pageSizeOptions={[10, 25, 50, 100]}
            sortingMode="server"
            sortModel={sort}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
