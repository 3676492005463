import { styled } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid-pro';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    maxWidth: 500
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main
  }
}));

export const EditCell = (params: GridRenderEditCellParams) => {
  const { error } = params;
  return (
    <StyledTooltip open={!!error} title={error || ''}>
      <span className="height-100 width-100">
        <GridEditInputCell {...params} error={!!params.error} />
      </span>
    </StyledTooltip>
  );
};
