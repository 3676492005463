import React from 'react';
import {
  AppBar,
  Box,
  Chip,
  Grid,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Tooltip
} from '@mui/material';
import { RootState, useAppDispatch } from '../store';
import { removeAuthentication } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../features/language/languageSlice';
import { Language } from '../utils/langUtils';
import { truncateLongText } from '../utils/textFormatUtils';
import { usePermission } from '../hooks/usePermission';
import { Permission } from '../domain/Permission';

export const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { language } = useSelector((state: RootState) => state.language);
  const { hasPermission: isEmployee } = usePermission(Permission.CUSTOMER);
  const { hasPermission: canEdit } = usePermission(Permission.ORDER_MANAGEMENT);

  const onLanguageChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
    dispatch(setLanguage(event.target.value));
  };

  return (
    <AppBar position="fixed" sx={{ height: '80px', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters className="header">
        <Box flexGrow="1">
          <img
            width={382}
            height={62.25}
            src={'/logo_light.png'}
            alt="Puidukoda"
            className="margin-left-16 margin-top-4"
          />
        </Box>
        <Box>
          <Grid container alignItems="center" justifyContent="flex-end">
            {!isEmployee && !canEdit && (
              <Grid item className="margin-right-20">
                <Tooltip followCursor title={t('header.spectator.tooltip')}>
                  <Chip
                    sx={{ height: '24px', borderRadius: '4px' }}
                    label={t('header.spectator.title')}
                    variant="filled"
                    className="header-tag"
                  />
                </Tooltip>
              </Grid>
            )}
            <Grid item className="margin-right-24">
              <div className="header-user-name">{truncateLongText(user?.sub, 30)}</div>
              <div className="header-user-company">{truncateLongText(user?.companyName, 30)}</div>
            </Grid>
            <Grid item className="margin-right-20">
              <Select value={language} onChange={onLanguageChange} className="header-lang-select">
                {Object.values(Language).map((language, index) => (
                  <MenuItem key={index} value={language}>
                    {t(`header.lang.${language}`)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              className="clickable margin-right-20"
              onClick={() => dispatch(removeAuthentication())}
            >
              <Icon className="margin-right-12">logout</Icon>
              {t('header.logout')}
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
