import { Grid } from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';
import { ProducedPackageConsumedPackage } from '../index';
import { formatNumberScale } from '../../../utils/textFormatUtils';
import { materialReactTableBaseProps } from '../analyticsHelper';
import { useTranslation } from 'react-i18next';
import { formatDateTimeString } from '../../../utils/dateUtils';

export const PackageTracingTable = ({ rows }: { rows: ProducedPackageConsumedPackage[] }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'vendor',
          header: t('analytics.vendor')
        },
        {
          accessorKey: 'packageNumber',
          header: t('analytics.packageTracing.inPackage')
        },
        {
          accessorKey: 'itemDescription',
          header: t('analytics.packageTracing.productDescription')
        },
        {
          accessorKey: 'registeredAt',
          accessorFn: ({ registeredAt }) => formatDateTimeString(registeredAt),
          header: t('analytics.packageTracing.packageRegistrationDate')
        },
        {
          header: `${t('analytics.packageTracing.percentageInOutput')} (%)`,
          accessorFn: (row) => `${formatNumberScale(row.outputPct)} %`
        },
        {
          id: 'packageM3',
          header: `${t('analytics.packageTracing.packageQuantity')} (m3)`,
          accessorFn: (row) =>
            `${formatNumberScale(row.totalConsumedPieces * row.consumedPieceM3, 1)} m3`
        },
        {
          id: 'primaryQuality',
          header: `${t('analytics.primaryQuality')} (%)`,
          accessorFn: (row) => `${formatNumberScale(row.primaryPct)} %`
        },
        {
          id: 'secondaryQuality',
          header: `${t('analytics.secondaryQuality')} (%)`,
          accessorFn: (row) => `${formatNumberScale(row.secondaryPct)} %`
        },
        {
          id: 'rejected',
          header: `${t('analytics.reject')} (%)`,
          size: 100,
          accessorFn: (row) => `${formatNumberScale(row.rejectedPct)} %`
        },
        {
          id: 'avgMoisture',
          header: `${t('analytics.moisture')} (%)`,
          size: 100,
          accessorFn: (row) => `${formatNumberScale(row.avgMoisture)} %`
        }
      ] as MRT_ColumnDef<ProducedPackageConsumedPackage>[],
    [t]
  );

  return (
    <Grid container className="margin-top-4">
      <Grid item xs sx={{ height: '40vh' }}>
        <MaterialReactTable<ProducedPackageConsumedPackage>
          {...materialReactTableBaseProps}
          enableColumnActions={false}
          enableExpanding={false}
          enablePagination={false}
          columns={columns}
          data={rows}
        />
      </Grid>
    </Grid>
  );
};
