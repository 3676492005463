import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Chip, Grid, MenuItem, Select } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGetCustomersQuery, useUpdateCustomerUnitMutation } from '../api/apiSlice';
import { setPage, setPageSize, setSort } from './customerSlice';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { StatusType } from '../../domain/StatusType';
import { useSnackbar } from 'notistack';
import { Loader } from '../../components/Loader';
import { Link } from 'react-router-dom';
import { UnitType } from '../../domain/UnitType';
import { CustomersFilter } from './CustomersFilter';

export const Customers = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.customers);
  const { data, isUninitialized, isLoading, isFetching } = useGetCustomersQuery({
    page,
    pageSize,
    sort,
    filter
  });

  const [updateUnit, { isLoading: isUpdateLoading }] = useUpdateCustomerUnitMutation();

  const onUpdateUnit = (companyId: number, unit: UnitType) => {
    updateUnit({ companyId, unit })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('customers.updateUnitSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const columns: GridColDef[] = [
    { field: 'number', headerName: t('customers.table.number'), flex: 2 },
    {
      field: 'country',
      headerName: t('customers.table.country'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value && t(`country.${params.value}`)
    },
    { field: 'salesperson', headerName: t('customers.table.salesperson'), flex: 1 },
    { field: 'salesCoordinator', headerName: t('customers.table.salesCoordinator'), flex: 1 },
    {
      field: 'status',
      headerName: t('customers.table.status'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, StatusType>) => (
        <Chip label={t(`statusType.${params.value}`)} size="small" variant="filled" />
      )
    },
    {
      field: 'unit',
      headerName: t('customers.table.unit'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <Select
          fullWidth
          size="small"
          value={params.value}
          onChange={(event) => onUpdateUnit(params.row.id, event.target.value as UnitType)}
        >
          {Object.values(UnitType).map((unit) => (
            <MenuItem key={unit} value={unit}>
              {t(`unitType.${unit}`)}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      field: 'activeItems',
      headerName: t('customers.table.activeItems'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <Link to={'' + params.row.id} className="link">
          {params.value}
        </Link>
      )
    }
  ];

  return (
    <Grid container>
      {(isUninitialized || isUpdateLoading) && <Loader />}
      <CustomersFilter />

      <Grid container className="margin-top-16">
        <Grid item xs>
          <h2>{t('customers.table.title')}</h2>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs sx={{ height: '65vh' }}>
          <DataGridPro
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            disableVirtualization={process.env.NODE_ENV === 'test'}
            filterMode="server"
            columnHeaderHeight={46}
            loading={isLoading || isFetching}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            pagination
            paginationMode="server"
            rowCount={data?.totalElements || 0}
            rowHeight={40}
            rows={(data?.content || []) as GridRowsProp}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            sortingMode="server"
            sortModel={sort}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
