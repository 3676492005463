import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ProductionOrderDetailsTable } from './ProductionOrderDetailsTable';
import { ProductionOrderDetailsFilters } from './ProductionOrderDetailsFilters';
import { useGetProductionOrdersQuery } from '../../../api/analyticsApi';

export const ProductionOrderDetailsPage = () => {
  const state = useSelector((state: RootState) => state.productionOrderDetails);
  const { data, isFetching } = useGetProductionOrdersQuery(state);

  return (
    <Grid container>
      <ProductionOrderDetailsFilters />
      <ProductionOrderDetailsTable
        dataPage={{ content: data?.content || [], totalElements: data?.totalElements || 0 }}
        isLoading={isFetching}
      />
    </Grid>
  );
};
