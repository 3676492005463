import React, { useState } from 'react';
import { Button, Grid, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandedOrderItem, Order } from './orderSlice';
import { OrderStatusType } from '../../domain/OrderStatusType';
import { formatDateString } from '../../utils/dateUtils';
import { GridRow } from '../../components/GridRow';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatAddressString } from '../../utils/addressUtils';
import { AvailabilityModal } from './AvailabilityModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { OrderItemsTableFooter } from './OrderItemsTableFooter';
import { formatNumber } from '../../utils/textFormatUtils';
import { roundToInteger, roundToTwoDecimals } from '../../utils/mathUtils';

export interface OrderPreviewProps {
  order: Order;
  orderItems?: ExpandedOrderItem[];
  isEditable: boolean;
  setActiveStep: Function;
}

export const OrderPreview = ({
  order,
  orderItems,
  isEditable,
  setActiveStep
}: OrderPreviewProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
  const { language } = useSelector((state: RootState) => state.language);

  const formatAvailability = (params: GridRenderCellParams<any, number>) => {
    const calculatedAvailability =
      ((params.row.reservedQuantity || 0) * 100) / (params.row.outstandingQuantity || 1);
    return params.value || calculatedAvailability ? `${calculatedAvailability}%` : '' || '';
  };

  const columns: GridColDef[] = [
    { field: 'customerItemNumber', headerName: t('order.items.customerItemNumber'), flex: 1 },
    { field: 'customerItemName', headerName: t('order.items.customerItemName'), flex: 3 },
    { field: 'number', headerName: t('order.items.number'), flex: !user?.customNumbers ? 2 : 1 },
    {
      field: 'description',
      headerName: t('order.items.description'),
      flex: !user?.customNames ? 5.5 : 2.5,
      renderCell: (params) => (
        <span>{params.row.translations[language] || params.row.description}</span>
      )
    },
    {
      field: 'packs',
      headerName: t('order.items.packs'),
      flex: 1,
      renderCell: (params) => <span>{formatNumber(roundToInteger(params.row.packs))}</span>
    },
    {
      field: 'quantity',
      headerName: t('order.items.quantity'),
      flex: 1.5,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        t('order.items.quantityValue', {
          quantity: formatNumber(roundToTwoDecimals(Number(params.value))),
          unit: t(`unitType.${params.row.unit}`)
        })
    },
    {
      field: 'price',
      headerName: t('order.items.price'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value
          ? t('order.items.priceValue', {
              price: params.value,
              unit: t(`unitType.${params.row.unit}`)
            })
          : '-'
    },
    {
      field: 'availability',
      headerName: t('order.items.availability'),
      flex: 1,
      renderCell: formatAvailability
    }
  ];

  const footer = () => {
    return <OrderItemsTableFooter orderItems={orderItems} order={order} isPreview={true} />;
  };

  return (
    <>
      <Grid container className="margin-top-8" columnGap="4px">
        <Grid item xs className="grid-block">
          <h2 className="padding-left-16">{t('order.preview.generalDetails')}</h2>
          <Grid container className="padding-bottom-16">
            <Grid item xs>
              <GridRow
                visible
                label={t('order.preview.created')}
                value={t('order.preview.createdValue', {
                  createdAt: formatDateString(order.createdAt),
                  createdBy: order.createdByName
                })}
              />
              <GridRow
                visible={order.submittedAtList.length > 0}
                label={t('order.preview.submitted')}
                value={order.submittedAtList.map((date) => formatDateString(date)).join(', ')}
              />
              <GridRow
                visible={!!order.quoteNumber}
                label={t('order.preview.quoteNumber')}
                value={order.quoteNumber}
              />
              <GridRow
                visible={order.status !== OrderStatusType.DRAFT}
                label={t('order.preview.orderNumber')}
                value={order.orderNumber || t('orders.tbd')}
              />
              <GridRow
                visible={order.status !== OrderStatusType.DRAFT}
                label={t('order.preview.invoiceNumber')}
                value={order.invoiceNumber || t('orders.tbd')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs className="grid-block">
          <Grid container justifyContent="space-between">
            <Grid item className="padding-left-16">
              <h2>{t('order.preview.deliveryDetails')}</h2>
            </Grid>
            {isEditable && (
              <Grid item className="padding-8">
                <Button
                  color="secondary"
                  onClick={() => setActiveStep(1)}
                  startIcon={<Icon>edit</Icon>}
                >
                  {t('button.edit')}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container className="padding-bottom-16">
            <Grid item xs>
              <GridRow
                visible
                label={t('order.preview.shipTo')}
                value={formatAddressString(order.address)}
              />
              <GridRow
                visible={!!order.requestedDelivery}
                label={t('order.preview.requestedDelivery')}
                value={formatDateString(order.requestedDelivery)}
              />
              <GridRow
                visible={!!order.additionalInfo}
                label={t('order.preview.additionalDeliveryInfo')}
                value={order.additionalInfo}
              />
              <GridRow
                visible={order.status !== OrderStatusType.DRAFT}
                label={t('order.preview.plannedDelivery')}
                value={formatDateString(order.plannedDelivery) || t('orders.tbd')}
              />
              <GridRow
                visible={!!order.internalNumber}
                label={t('order.preview.internalNumber')}
                value={order.internalNumber}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="margin-top-8">
        <Grid item xs>
          <Grid container justifyContent="space-between">
            <Grid item>
              <h2>{t('order.preview.orderItems')}</h2>
            </Grid>
            {isEditable && (
              <Grid item>
                <Grid container>
                  <Button
                    className="margin-right-16"
                    color="secondary"
                    onClick={() => setAvailabilityModalOpen(true)}
                  >
                    {t('order.preview.checkAvailability')}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setActiveStep(0)}
                    startIcon={<Icon>edit</Icon>}
                  >
                    {t('button.edit')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs sx={{ height: '300px' }}>
          <DataGridPro
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            columnHeaderHeight={46}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  customerItemNumber: !!user?.customNumbers,
                  customerItemName: !!user?.customNames
                }
              }
            }}
            rowHeight={40}
            rows={(orderItems || []) as GridRowsProp}
            disableVirtualization={process.env.NODE_ENV === 'test'}
            slots={{
              footer: footer
            }}
          />
        </Grid>
      </Grid>

      <AvailabilityModal
        isOpen={availabilityModalOpen}
        orderItems={orderItems}
        handleClose={() => setAvailabilityModalOpen(false)}
      />
    </>
  );
};
