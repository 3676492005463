import clsx from 'clsx';
import { OrderStatusType } from '../../domain/OrderStatusType';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';

export interface OrderStatusProps {
  status?: OrderStatusType;
}

export const OrderStatus = ({ status }: OrderStatusProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(`orders.statusType.${status}`).toUpperCase()}
      size="small"
      variant="filled"
      className={clsx('status', {
        'status-blue': OrderStatusType.DRAFT === status,
        'status-orange-light': OrderStatusType.SUBMITTED === status,
        'status-orange-dark': OrderStatusType.AWAITS_RESPONSE === status,
        'status-green-light': OrderStatusType.ACCEPTED === status,
        'status-green-dark': OrderStatusType.SHIPPED === status,
        'status-gray': OrderStatusType.FINISHED === status,
        'status-red': [OrderStatusType.REJECTED, OrderStatusType.DISCARDED].some((orderStatus) =>
          status?.includes(orderStatus)
        )
      })}
    />
  );
};
