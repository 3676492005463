import { Line } from 'react-chartjs-2';
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { ChartDataset } from 'chart.js/dist/types';
import { lineChartOptions } from '../../../../utils/chartUtil';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import type { ChartOptions } from 'chart.js';
import { useTranslateVendor, VendorChartVendorData } from './RawMaterialAnalyticsDetailsHelper';
import { formatNumberScale } from '../../../../utils/textFormatUtils';
import { useTranslation } from 'react-i18next';

export const RawMaterialAnalyticsDetailsQuantityChart = (params: {
  data: VendorChartVendorData;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { tVendor } = useTranslateVendor();
  const options = useMemo(
    () =>
      merge(cloneDeep(lineChartOptions), {
        scales: {
          y: { ticks: { callback: (c, value) => `${c} m3` } },
          x: { type: 'time', time: { unit: 'month', round: 'month' } }
        },
        plugins: { title: { display: true, text: t('analytics.rawMaterial.input') } }
      } as Partial<ChartOptions>),
    [t]
  );
  const datasets = useMemo(
    () =>
      Object.entries(params.data).map(([vendor, vendorData]) => ({
        label: tVendor(vendor),
        data: vendorData.map((row) => ({
          x: new Date(row.date),
          y: formatNumberScale(row.amount)
        }))
      })) as ChartDataset<'line', any>[],
    [params.data, tVendor]
  );

  return (
    <Grid container>
      <Grid item xs sx={{ height: '20vh', marginTop: '20px' }}>
        <div style={{ display: 'block', height: 'inherit', width: 'auto', position: 'relative' }}>
          <Line key="quantity-chart" redraw options={options} data={{ datasets }} />
        </div>
      </Grid>
    </Grid>
  );
};
