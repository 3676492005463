import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { ProductionAnalyticsFilters } from './ProductionAnalyticsFilters';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { setShowMoistureColumns, setShowQualityColumns } from './productionAnalyticsSlice';
import { ProductionAnalyticsTable } from './ProductionAnalyticsTable';
import { useGetProductionOrderAnalyticsQuery } from '../../api/analyticsApi';

export const ProductionAnalytics = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchParams, showQualityColumns, showMoistureColumns } = useSelector(
    (state: RootState) => state.productionAnalytics
  );
  const { data, isLoading, isFetching } = useGetProductionOrderAnalyticsQuery(searchParams);

  return (
    <Grid container>
      <ProductionAnalyticsFilters />
      <Grid container justifyContent="end" alignItems="center" className="margin-top-4">
        <Grid item className="margin-bottom-8">
          <FormGroup style={{ display: 'inline-block' }}>
            <FormControlLabel
              label={t('analytics.moisture')}
              control={
                <Checkbox
                  checked={showMoistureColumns}
                  onChange={(event, checked) => dispatch(setShowMoistureColumns(checked))}
                />
              }
            />
            <FormControlLabel
              label="PKV"
              control={
                <Checkbox
                  checked={showQualityColumns}
                  onChange={(event, checked) => dispatch(setShowQualityColumns(checked))}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <ProductionAnalyticsTable page={data} isLoading={isLoading || isFetching} />
    </Grid>
  );
};
