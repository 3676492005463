import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MoistureAnalyticsQuery } from '../index';

export type MoistureAnalyticsFilter = {
  vendor: string[];
  consumedPackageNumber?: string;
  purchaseDocumentNumber?: string;
} & Omit<MoistureAnalyticsQuery, 'vendor' | 'consumedPackageNumber' | 'purchaseDocumentNumber'>;

export interface MoistureAnalyticsState {
  filter: MoistureAnalyticsFilter;
}

export const MoistureAnalyticsInitialState = {
  filter: {
    startDate: '',
    endDate: '',
    vendor: [],
    species: [],
    dimensions: [],
    quality: [],
    length: [],
    minMoisture: '',
    maxMoisture: '',
    consumedPackageNumber: '',
    documentNumber: ''
  }
} as MoistureAnalyticsState;

const slice = createSlice({
  name: 'moistureAnalytics',
  initialState: MoistureAnalyticsInitialState,
  reducers: {
    clearFilter: (state) => {
      state = MoistureAnalyticsInitialState;
    },
    setFilterValue: (
      state,
      {
        payload: { field, value }
      }: PayloadAction<{ field: keyof MoistureAnalyticsFilter; value: never }>
    ) => {
      state.filter[field] = value;
    },
    setFilter: (state, { payload }: PayloadAction<MoistureAnalyticsFilter>) => {
      state.filter = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setFilter } = slice.actions;
