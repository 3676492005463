import React from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../components/Filter';
import { FilterArea } from '../../components/FilterArea';
import { RootState, useAppDispatch } from '../../store';
import { useGetCustomerAutocompleteQuery } from '../api/apiSlice';
import { useSelector } from 'react-redux';
import { StatusType } from '../../domain/StatusType';
import { clearFilter, CustomerFilter, setFilterValue } from './customerSlice';

export const CustomersFilter = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { filter } = useSelector((state: RootState) => state.customers);

  const { data: numberOptions, isFetching: isNumberOptionsLoading } =
    useGetCustomerAutocompleteQuery('number');
  const { data: countryOptions, isFetching: isCountryOptionsLoading } =
    useGetCustomerAutocompleteQuery('country');
  const { data: salespersonOptions, isFetching: isSalespersonOptionsLoading } =
    useGetCustomerAutocompleteQuery('salesperson');
  const { data: salesCoordinatorOptions, isFetching: isSalesCoordinatorOptionsLoading } =
    useGetCustomerAutocompleteQuery('salesCoordinator');

  const handleFilterChange = (
    field: keyof CustomerFilter,
    event: any,
    value: string | StatusType[]
  ) => dispatch(setFilterValue({ field, value }));

  return (
    <FilterArea onClear={() => dispatch(clearFilter())}>
      <Filter
        id="number"
        label={t('customers.table.number')}
        loading={isNumberOptionsLoading}
        minWidth="260px"
        options={numberOptions}
        value={filter.number || null}
        onChange={handleFilterChange.bind(this, 'number')}
      />
      <Filter
        id="country"
        label={t('customers.table.country')}
        loading={isCountryOptionsLoading}
        minWidth="200px"
        optionLabelPrefix="country."
        options={countryOptions}
        value={filter.country || null}
        onChange={handleFilterChange.bind(this, 'country')}
      />
      <Filter
        id="salesperson"
        label={t('customers.table.salesperson')}
        loading={isSalespersonOptionsLoading}
        minWidth="220px"
        options={salespersonOptions}
        value={filter.salesperson || null}
        onChange={handleFilterChange.bind(this, 'salesperson')}
      />
      <Filter
        id="salesCoordinator"
        label={t('customers.table.salesCoordinator')}
        loading={isSalesCoordinatorOptionsLoading}
        minWidth="220px"
        options={salesCoordinatorOptions}
        value={filter.salesCoordinator || null}
        onChange={handleFilterChange.bind(this, 'salesCoordinator')}
      />
      <Filter
        id="status"
        label={t('customers.table.status')}
        minWidth="180px"
        multiple
        optionLabelPrefix="statusType."
        options={Object.values(StatusType)}
        value={filter.status || []}
        onChange={handleFilterChange.bind(this, 'status')}
      />
    </FilterArea>
  );
};
