import React, { useEffect } from 'react';
import { EDITABLE_ORDER_STATUS_TYPES, OrderStatusType } from '../../domain/OrderStatusType';
import { Grid } from '@mui/material';
import { Loader } from '../../components/Loader';
import { OrderDelivery } from './OrderDelivery';
import { OrderHeader } from './OrderHeader';
import { OrderItems } from './OrderItems';
import { OrderPreview } from './OrderPreview';
import { RootState } from '../../store';
import { useGetOrderItemsQuery, useGetOrderQuery } from '../api/orderApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OrderFooter } from './OrderFooter';

export const Order = (): JSX.Element => {
  const { orderId } = useParams();
  const [activeStep, setActiveStep] = React.useState(2);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    data: order,
    isUninitialized: isOrderUninitialized,
    isLoading: isOrderLoading
  } = useGetOrderQuery(orderId);
  const {
    data: orderItems,
    isUninitialized: isOrderItemsUninitialized,
    isLoading: isOrderItemsLoading
  } = useGetOrderItemsQuery(orderId);

  const isEditable =
    !!order &&
    EDITABLE_ORDER_STATUS_TYPES.includes(order.status) &&
    user?.personId === order.createdBy;

  const isAwaitsResponse =
    !!order &&
    OrderStatusType.AWAITS_RESPONSE === order.status &&
    user?.personId === order.createdBy;

  useEffect(() => {
    if (isEditable) {
      setActiveStep(0);
    }
    if (isAwaitsResponse) {
      setActiveStep(2);
    }
  }, [isEditable, isAwaitsResponse]);

  return (
    <Grid container>
      {(isOrderUninitialized ||
        isOrderLoading ||
        isOrderItemsUninitialized ||
        isOrderItemsLoading) && <Loader />}
      <OrderHeader
        order={order}
        isEditable={isEditable}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />

      {order && (
        <>
          {activeStep === 0 && <OrderItems order={order} orderItems={orderItems} />}
          {activeStep === 1 && isEditable && <OrderDelivery order={order} />}
          {activeStep === 2 && (
            <OrderPreview
              order={order}
              orderItems={orderItems}
              isEditable={isEditable}
              setActiveStep={setActiveStep}
            />
          )}
        </>
      )}

      {isEditable && (
        <OrderFooter order={order} activeStep={activeStep} hasItems={!!orderItems?.length} />
      )}
    </Grid>
  );
};
