import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../../components/Filter';
import { FilterArea } from '../../../components/FilterArea';
import { RootState, useAppDispatch } from '../../../store';
import { DateRangeFilter } from '../../../components/DateRangeFilter';
import {
  clearFilter,
  ProductionAnalyticsFilter,
  ProductionAnalyticsInitialState,
  setFilter
} from './productionAnalyticsSlice';
import { Button, Grid, Icon } from '@mui/material';
import {
  useGetFilterValuesForProducedPackagesQuery,
  useLazyDownloadProductionAnalyticsQuery
} from '../../api/analyticsApi';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useNavigate } from 'react-router-dom';
import { useWorkStations } from '../hooks/useWorkStations';

export const ProductionAnalyticsFilters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { searchParams } = useSelector((state: RootState) => state.productionAnalytics);
  const [filterState, setFilterState] = useState(searchParams.filter);
  const { data: filterValues } = useGetFilterValuesForProducedPackagesQuery();
  const { workStationIds, isLoading: isWorkStationsLoading } = useWorkStations();
  const [download, { isLoading: isDownloading }] = useLazyDownloadProductionAnalyticsQuery();

  const searchHandler = () => {
    dispatch(setFilter(filterState));
  };

  const handleDateFilterChange = (
    fieldFrom: keyof ProductionAnalyticsFilter,
    fieldTo: keyof ProductionAnalyticsFilter,
    date: DateRange<Date>
  ) =>
    setFilterState({
      ...filterState,
      [fieldFrom]: date[0]?.toJSON(),
      [fieldTo]: date[1]?.toJSON()
    });

  const handleFilterChange = (
    field: keyof ProductionAnalyticsFilter,
    event: any,
    value: string | number
  ) => setFilterState({ ...filterState, [field]: value });

  const clear = () => {
    setFilterState(ProductionAnalyticsInitialState.searchParams.filter);
    dispatch(clearFilter());
  };

  const analyticsButton = () => (
    <Button
      variant="text"
      className="link"
      onClick={() => navigate('/production-analytics/errors')}
      startIcon={<Icon className="margin-top-4">assist_walker</Icon>}
    />
  );

  return (
    <FilterArea onClear={clear} extra={analyticsButton()}>
      <Filter
        multiple
        id="workStation"
        minWidth="200px"
        options={workStationIds}
        loading={isWorkStationsLoading}
        label={t('analytics.workStation')}
        value={filterState.workStation}
        onChange={handleFilterChange.bind(this, 'workStation')}
      />
      <Filter
        multiple
        id="species"
        minWidth="180px"
        label={t('analytics.species')}
        value={filterState.species}
        options={filterValues?.species || []}
        onChange={handleFilterChange.bind(this, 'species')}
      />
      <Filter
        multiple
        id="categoryName"
        minWidth="200px"
        label={t('analytics.category')}
        value={filterState.categoryName}
        options={filterValues?.groupNames || []}
        onChange={handleFilterChange.bind(this, 'categoryName')}
      />
      <Filter
        multiple
        id="profileType"
        minWidth="200px"
        label={t('analytics.profileType')}
        value={filterState.profileType}
        options={filterValues?.profileTypes || []}
        onChange={handleFilterChange.bind(this, 'profileType')}
      />
      <Filter
        multiple
        id="profileCode"
        minWidth="200px"
        label={t('analytics.profileCode')}
        value={filterState.profileCode}
        options={filterValues?.profileCodes || []}
        onChange={handleFilterChange.bind(this, 'profileCode')}
      />
      <DateRangeFilter
        id="date"
        width="150px"
        label="analytics.datepicker"
        valueFrom={filterState.startDate ? new Date(filterState.startDate) : null}
        valueTo={filterState.endDate ? new Date(filterState.endDate) : null}
        onChange={handleDateFilterChange.bind(this, 'startDate', 'endDate')}
      />
      <Filter
        multiple
        id="quality"
        minWidth="200px"
        label={t('analytics.quality')}
        value={filterState.quality}
        options={filterValues?.qualities || []}
        onChange={handleFilterChange.bind(this, 'quality')}
      />
      <Filter
        numeric
        multiple
        id="length"
        minWidth="160px"
        label={t('analytics.length')}
        value={filterState.length}
        options={filterValues?.lengths || []}
        onChange={handleFilterChange.bind(this, 'length')}
      />
      <Filter
        multiple
        id="width"
        minWidth="160px"
        label={t('analytics.width')}
        value={filterState.width}
        options={filterValues?.actualWidths || []}
        onChange={handleFilterChange.bind(this, 'width')}
      />
      <Filter
        numeric
        multiple
        id="thickness"
        minWidth="160px"
        label={t('analytics.thickness')}
        value={filterState.thickness}
        options={filterValues?.actualThicknesses || []}
        onChange={handleFilterChange.bind(this, 'thickness')}
      />
      <Grid item>
        <Button onClick={searchHandler}>{t('button.search')}</Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          disabled={isDownloading}
          onClick={() => download(searchParams)}
          startIcon={<Icon className="margin-top-4">download</Icon>}
        >
          XLSX
        </Button>
      </Grid>
    </FilterArea>
  );
};
