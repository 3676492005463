import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../components/Filter';
import { FilterArea } from '../../components/FilterArea';
import { RootState, useAppDispatch } from '../../store';
import { useGetOrderAutocompleteQuery } from '../api/orderApi';
import { clearFilter, OrderFilter, setDefaultFilterValue, setFilterValue } from './orderSlice';
import { OrderStatusType } from '../../domain/OrderStatusType';
import { useSelector } from 'react-redux';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangeFilter } from '../../components/DateRangeFilter';
import { usePermission } from '../../hooks/usePermission';
import { Permission } from '../../domain/Permission';

export const OrdersFilter = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { filter, isDefaultState } = useSelector((state: RootState) => state.orders);
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { hasPermission: canEdit } = usePermission(Permission.ORDER_MANAGEMENT);

  useEffect(() => {
    if (isDefaultState && user && canEdit) {
      dispatch(setDefaultFilterValue(user.sub));
    }
  });

  const handleFilterChange = (
    field: keyof OrderFilter,
    event: any,
    value: string | OrderStatusType[]
  ) => dispatch(setFilterValue({ field, value }));

  const handleDateFilterChange = (
    fieldFrom: keyof OrderFilter,
    fieldTo: keyof OrderFilter,
    date: DateRange<Date>
  ) => {
    dispatch(setFilterValue({ field: fieldFrom, value: date[0]?.toJSON() }));
    dispatch(setFilterValue({ field: fieldTo, value: date[1]?.toJSON() }));
  };

  const { data: orderNumberOptions, isFetching: isOrderNumberOptionsLoading } =
    useGetOrderAutocompleteQuery('orderNumber');
  const { data: quoteNumberOptions, isFetching: isQuoteNumberOptionsLoading } =
    useGetOrderAutocompleteQuery('quoteNumber');
  const { data: internalNumberOptions, isFetching: isInternalNumberOptionsLoading } =
    useGetOrderAutocompleteQuery('internalNumber');
  const { data: createdByOptions, isFetching: isCreatedByOptionsLoading } =
    useGetOrderAutocompleteQuery('createdBy');
  const { data: shipToOptions, isFetching: isShipToOptionsLoading } =
    useGetOrderAutocompleteQuery('address');

  return (
    <FilterArea onClear={() => dispatch(clearFilter())}>
      <Filter
        id="orderNumber"
        label={t('orders.filter.orderNumber')}
        loading={isOrderNumberOptionsLoading}
        minWidth="195px"
        options={orderNumberOptions}
        value={filter.orderNumber || null}
        onChange={handleFilterChange.bind(this, 'orderNumber')}
      />
      <Filter
        id="quoteNumber"
        label={t('orders.table.quoteNumber')}
        loading={isQuoteNumberOptionsLoading}
        minWidth="195px"
        options={quoteNumberOptions}
        value={filter.quoteNumber || null}
        onChange={handleFilterChange.bind(this, 'quoteNumber')}
      />
      <Filter
        id="internalNumber"
        label={t('orders.table.internalNumber')}
        loading={isInternalNumberOptionsLoading}
        minWidth="240px"
        options={internalNumberOptions}
        value={filter.internalNumber || null}
        onChange={handleFilterChange.bind(this, 'internalNumber')}
      />
      <DateRangeFilter
        id="createdAt"
        label="orders.filter.createdAt"
        width="185px"
        valueFrom={filter.createdAtFrom ? new Date(filter.createdAtFrom) : null}
        valueTo={filter.createdAtTo ? new Date(filter.createdAtTo) : null}
        onChange={handleDateFilterChange.bind(this, 'createdAtFrom', 'createdAtTo')}
      />
      <Filter
        id="createdBy"
        label={t('orders.table.createdBy')}
        loading={isCreatedByOptionsLoading}
        minWidth="200px"
        options={createdByOptions}
        value={filter.createdBy || null}
        onChange={handleFilterChange.bind(this, 'createdBy')}
      />
      <Filter
        id="address"
        label={t('orders.table.address')}
        loading={isShipToOptionsLoading}
        minWidth="320px"
        multiple
        options={shipToOptions}
        value={filter.address || []}
        onChange={handleFilterChange.bind(this, 'address')}
      />
      <DateRangeFilter
        id="plannedDelivery"
        label="orders.filter.plannedDelivery"
        width="210px"
        valueFrom={filter.plannedDeliveryFrom ? new Date(filter.plannedDeliveryFrom) : null}
        valueTo={filter.plannedDeliveryTo ? new Date(filter.plannedDeliveryTo) : null}
        onChange={handleDateFilterChange.bind(this, 'plannedDeliveryFrom', 'plannedDeliveryTo')}
      />
      <Filter
        id="status"
        label={t('orders.table.status')}
        minWidth="180px"
        multiple
        optionLabelPrefix="orders.statusType."
        options={Object.values(OrderStatusType)}
        value={filter.status || []}
        onChange={handleFilterChange.bind(this, 'status')}
      />
    </FilterArea>
  );
};
