import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { ProducedPackageTracing } from '../index';
import { formatDateTimeString } from '../../../utils/dateUtils';
import { formatNumberScale } from '../../../utils/textFormatUtils';
import { useTranslation } from 'react-i18next';

export const PackageTracingOrderDetails = ({ data }: { data: ProducedPackageTracing }) => {
  const { t } = useTranslation();
  const cols: { label: string; value: string | number }[] = useMemo(
    () => [
      {
        label: t('analytics.packageTracing.productionOrderNumber'),
        value: data.productionOrderNumber
      },
      {
        label: t('analytics.packageTracing.productionOrderDescription'),
        value: data.itemDescription
      },
      {
        label: t('analytics.packageTracing.productionDate'),
        value: formatDateTimeString(data.productionStartDate)
      },
      {
        label: t('analytics.packageTracing.packageRegistrationDate'),
        value: formatDateTimeString(data.productionStartDate)
      },
      {
        label: t('analytics.packageTracing.outPackageQuantity'),
        value: `${formatNumberScale(data.producedPackageM3, 1)} m3`
      }
    ],
    [data, t]
  );

  return (
    <Grid container className="margin-top-16">
      {cols.map(({ label, value }) => (
        <Grid container>
          <label>{label}:</label>&nbsp;<span>{value}</span>
        </Grid>
      ))}
    </Grid>
  );
};
