import React, { ReactNode, useCallback, useMemo } from 'react';
import { Button, Chip, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store';
import { ProductionOrder, ProductionOrderAnalyticsStatus } from '../../index';
import { Page } from '../../../../domain/Page';
import { formatDateTimeString } from '../../../../utils/dateUtils';
import { setPage, setPageSize } from './productionOrderDetailsSlice';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { PaginationState } from '@tanstack/table-core';
import { materialReactTableBaseProps } from '../../analyticsHelper';
import { useUpdateProductionOrderAnalyticsStatusMutation } from '../../../api/analyticsApi';
import { useSnackbar } from 'notistack';

type ChipColor = 'default' | 'warning' | 'success';

const actionAllowed = (status: ProductionOrderAnalyticsStatus): boolean => {
  return [
    ProductionOrderAnalyticsStatus.IN_AMOUNT_MISMATCH,
    ProductionOrderAnalyticsStatus.IN_OUT_AMOUNT_DIFFERENCE,
    ProductionOrderAnalyticsStatus.IN_SPLIT_AMOUNT_MISMATCH,
    ProductionOrderAnalyticsStatus.OUT_AMOUNT_MISMATCH,
    ProductionOrderAnalyticsStatus.PRIMARY_AMOUNT_MISMATCH
  ].some((st) => st === status);
};

const getChipColorByStatus = (status: ProductionOrderAnalyticsStatus): ChipColor => {
  switch (status) {
    case ProductionOrderAnalyticsStatus.OK:
      return 'success';
    case ProductionOrderAnalyticsStatus.IGNORED:
      return 'default';
    default:
      return 'warning';
  }
};

const getColor = (num: number) => {
  if (num > 5) {
    return 'red';
  } else if (num > 2) {
    return 'orange';
  } else {
    return 'black';
  }
};

const valueOrDash = (value?: number) => value === undefined ? '-' : value;

const slashValues = (a?: number, b?: number): string => {
  return `${valueOrDash(a)}/${valueOrDash(b)}`
}

const findErrorPercentage = (a: number = 0, b: number = 0): ReactNode => {
  const errorPercentage = (1 - a / b) * 100;
  const asbPercentage = Math.abs(errorPercentage);

  return (
    <span style={{ color: getColor(asbPercentage) }}>
      {isNaN(asbPercentage) ? '-' : `${asbPercentage.toFixed(1)} %`}
    </span>
  );
};

export const ProductionOrderDetailsTable = ({
  dataPage,
  isLoading
}: {
  dataPage?: Page<ProductionOrder>;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { page, pageSize } = useSelector((state: RootState) => state.productionOrderDetails);
  const [query, { isLoading: isUpdating }] = useUpdateProductionOrderAnalyticsStatusMutation();

  const callAction = useCallback(
    (row: ProductionOrder, status: ProductionOrderAnalyticsStatus) => {
      query({ productionOrderId: row.id, body: { status } })
        .unwrap()
        .then(() =>
          enqueueSnackbar(t('analytics.productionOrderDetails.action.statusUpdateSuccess'), {
            variant: 'success'
          })
        )
        .catch(() =>
          enqueueSnackbar(t('analytics.productionOrderDetails.action.statusUpdateError'), {
            variant: 'error'
          })
        );
    },
    [t, query, enqueueSnackbar]
  );

  const columns = useMemo(
    () =>
      [
        {
          id: 'order',
          size: '90',
          header: t('analytics.productionOrderDetails.order'),
          accessorFn: (row) => row.no
        },
        {
          size: '90',
          accessorKey: 'workStation',
          header: t('analytics.workStation')
        },
        {
          id: 'status',
          accessorFn: (row) => (
            <Chip
              color={getChipColorByStatus(row.details.analyticsStatus)}
              label={t(
                `analytics.productionOrderDetails.statusCode.${row.details.analyticsStatus}`
              )}
            />
          ),
          header: t('analytics.productionOrderDetails.status')
        },
        {
          size: '140',
          accessorKey: 'date',
          header: t('analytics.productionOrderDetails.date'),
          accessorFn: (row) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="nowrap">{formatDateTimeString(row.globalReader?.startAt)} -</span>
              <span className="nowrap">{formatDateTimeString(row.globalReader?.endAt)}</span>
            </div>
          )
        },
        {
          id: 'inPcs',
          size: '150',
          header: t('analytics.productionOrderDetails.input'),
          accessorFn: ({ details: { summary } }) => slashValues(summary?.plcConsumedPcs, summary?.bcConsumedPcs)
        },
        {
          id: 'error1',
          size: '100',
          header: t('analytics.productionOrderDetails.errorRate'),
          accessorFn: ({ details: { summary } }) =>
            findErrorPercentage(summary?.bcConsumedPcs, summary?.plcConsumedPcs)
        },
        {
          id: 'plcConsumedSplitPcs',
          size: '120',
          header: t('analytics.productionOrderDetails.plcSplitPcs'),
          accessorFn: ({ details: { summary } }) => valueOrDash(summary?.plcConsumedSplitPcs)
        },
        {
          id: 'plcProducedPcs',
          size: '120',
          header: t('analytics.productionOrderDetails.plcProducedPcs'),
          accessorFn: ({ details: { summary } }) => valueOrDash(summary?.plcProducedPcs)
        },
        {
          id: 'outPcs',
          size: '120',
          header: t('analytics.productionOrderDetails.outPcs'),
          accessorFn: ({ details: { summary } }) => slashValues(summary?.plcProducedSplitPcs, summary?.bcProducedPcs)
        },
        {
          id: 'primaryPcs',
          size: '120',
          header: t('analytics.productionOrderDetails.primaryPcs'),
          accessorFn: ({ details: { summary } }) => slashValues(summary?.plcPrimaryPcs, summary?.bcPrimaryPcs)
        },
        {
          id: 'secondaryPcs',
          size: '100',
          header: t('analytics.productionOrderDetails.secondaryPcs'),
          accessorFn: ({ details: { summary } }) => slashValues(summary?.plcSecondaryPcs, summary?.bcSecondaryPcs)
        },
        {
          id: 'rejectPcs',
          size: '110',
          header: t('analytics.productionOrderDetails.rejectPcs'),
          accessorFn: ({ details: { summary } }) => slashValues(summary?.plcRejectPcs, summary?.bcRejectPcs)
        },
        {
          id: 'plcUnknownPcs',
          size: '110',
          header: t('analytics.productionOrderDetails.plcUnknownPcs'),
          accessorFn: ({ details: { summary } }) => valueOrDash(summary?.plcUnknownPcs)
        },
        {
          id: 'actions',
          size: '150',
          enableResizing: false,
          enableColumnActions: false,
          header: t('analytics.productionOrderDetails.actions'),
          Cell: ({ row: { original } }) =>
            actionAllowed(original.details.analyticsStatus) && (
              <div style={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => callAction(original, ProductionOrderAnalyticsStatus.OK)}
                >
                  {t('analytics.productionOrderDetails.action.accept')}
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => callAction(original, ProductionOrderAnalyticsStatus.IGNORED)}
                >
                  {t('analytics.productionOrderDetails.action.ignore')}
                </Button>
              </div>
            )
        }
      ] as MRT_ColumnDef<ProductionOrder>[],
    [callAction, t]
  );

  return (
    <Grid container>
      <Grid item xs sx={{ height: '40vh', marginTop: '20px' }}>
        <MaterialReactTable<ProductionOrder>
          {...materialReactTableBaseProps}
          columns={columns}
          enableExpanding={false}
          enablePagination={true}
          manualPagination={true}
          rowCount={dataPage?.totalElements}
          onPaginationChange={(updater: any) => {
            const newValue: PaginationState = updater({
              pageIndex: page,
              pageSize: pageSize
            } as PaginationState);
            dispatch(setPage(newValue.pageIndex));
            dispatch(setPageSize(newValue.pageSize));
          }}
          state={{
            pagination: { pageIndex: page, pageSize: pageSize },
            isLoading: isLoading || isUpdating
          }}
          data={dataPage?.content || []}
        />
      </Grid>
    </Grid>
  );
};
