import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RawMaterialAnalyticsFilter {
  startDate?: string;
  endDate?: string;
  species: string[];
  group: string[];
  consumedCrossSections: string[];
  nominalDimensions: string[];
  actualDimensions: string[];
  quality: string[];
  profileType: string[];
  profileCode: string[];
  moisture: number[];
}

export interface RawMaterialAnalyticsState {
  filter: RawMaterialAnalyticsFilter;
  page: number;
  pageSize: number;
}

export const RawMaterialInitialState: RawMaterialAnalyticsState = {
  page: 0,
  pageSize: 20,
  filter: {
    species: [],
    group: [],
    consumedCrossSections: [],
    nominalDimensions: [],
    actualDimensions: [],
    quality: [],
    profileType: [],
    profileCode: [],
    moisture: []
  }
};

const slice = createSlice({
  name: 'rawMaterialAnalytics',
  initialState: RawMaterialInitialState,
  reducers: {
    clearFilter: (state) => {
      state = RawMaterialInitialState;
    },
    setFilter: (state, { payload }: PayloadAction<RawMaterialAnalyticsFilter>) => {
      state.filter = payload;
    },
    setFilterValue: (
      state,
      {
        payload: { field, value }
      }: PayloadAction<{ field: keyof RawMaterialAnalyticsFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setPage, setPageSize, setFilter } = slice.actions;
