import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderStatusType } from '../../domain/OrderStatusType';

export interface OrderSelectionState {
  filter: OrderSelectionFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface OrderSelectionFilter {
  status: OrderStatusType[];
}

const slice = createSlice({
  name: 'orderSelection',
  initialState: {
    filter: { status: [OrderStatusType.DRAFT] },
    page: 0,
    pageSize: 25,
    sort: [{ field: 'createdAt', sort: 'desc' }]
  } as OrderSelectionState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { setPage, setPageSize, setSort } = slice.actions;
