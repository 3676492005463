import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Filter } from '../../../components/Filter';
import { useLazyGetPackageInfoQuery } from '../../api/analyticsApi';
import { PackageTracingOrderDetails } from './PackageTracingOrderDetails';
import { PackageTracingTable } from './PackageTracingTable';
import { Loader } from '../../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const PackageTracing = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [packageNo, setPackageNo] = useState('');
  const [query, { isLoading, data }] = useLazyGetPackageInfoQuery();

  const searchHandler = () => {
    query(packageNo)
      .unwrap()
      .then((res) => {
        if (!res) {
          enqueueSnackbar(t('analytics.packageTracing.packageNotFound'), { variant: 'error' });
        }
      });
  };

  return (
    <Grid container>
      <Grid container spacing={1.25}>
        <Filter
          text
          id="packageNo"
          minWidth="200px"
          value={packageNo}
          label={t('analytics.packageTracing.packageNo')}
          onChange={(event) => setPackageNo(event.target.value)}
        />
        <Grid item>
          <Button onClick={searchHandler} disabled={isLoading}>
            {t('button.search')}
          </Button>
        </Grid>
      </Grid>
      {isLoading && <Loader />}
      {data && (
        <>
          <PackageTracingOrderDetails data={data} />
          <h4>{t('analytics.packageTracing.inPackages')}:</h4>
          <PackageTracingTable rows={data.consumedPackages} />
        </>
      )}
    </Grid>
  );
};
