import { RawMaterialAnalyticsFilter } from './rawMaterial/rawMaterialAnalyticsSlice';

export type FrequencyMap = { [key: string | number]: number };

export type MoistureAnalyticsResult = {
  id: string;
  vendor: string;
  vendorGroup?: string;
  packageNo: string;
  registeredAt: string;
  maxMoisture: number;
  avgMoisture: number;
  totalPcs: number;
  moistureRejectPieces: number;
  moistureMap: FrequencyMap;
  rejectionMap: FrequencyMap;
};

export type MoistureAnalyticsQuery = {
  startDate?: string;
  endDate?: string;
  minMoisture?: string;
  maxMoisture?: string;
  vendor?: string[];
  species?: string[];
  dimensions?: string[];
  quality?: string[];
  length?: number[];
  consumedPackageNumber?: string[];
  purchaseDocumentNumber?: string[];
};

export type MoistureAnalysisGroupedRow = {
  type: 'vendor' | 'vendor_group';
  moistureStandardDeviation?: number;
  moistureRejectPercentage?: number;
  moistureRejectPieces: number;
  children: MoistureAnalysisGroupedRow[];
} & MoistureAnalyticsResult;

export enum ProductionOrderAnalyticsRowType {
  PRODUCTION_ORDER = 'PRODUCTION_ORDER',
  VENDOR_GROUP = 'VENDOR_GROUP',
  VENDOR = 'VENDOR',
  MATERIAL = 'MATERIAL',
  PACKAGE = 'PACKAGE'
}

export type ProductionOrderAnalyticsRow = {
  type: ProductionOrderAnalyticsRowType;
  productionOrderId: number;
  productionOrderNumber: string;
  name: string;
  orderName: string;
  product: string;
  totalM3: number;
  primaryM3: number;
  startAt: string;
  endAt: string;
  historicalTotalM3: number;
  historicalPrimaryM3: number;
  historicalPrimaryMoisture: number;
  historicalPrimaryPct: number;
  primaryPercentage: number;
  avgPrimaryMoisture: number;
  children?: ProductionOrderAnalyticsRow[];
} & WithVendor;

export type RawMaterialGroupedRow = {
  species: string;
  actualThickness: number;
  actualWidth: number;
  quality: string;
  vendor: string;
  vendorGroup?: string;
  totalConsumedM3: number;
  totalProducedM3: number;
  consumedAsPrimaryM3: number;
  primaryProducedM3: number;
  primaryPercentage: number;
  avgLength: number;
  avgMoisture: number;
  RawMaterialGroupedRow: number;
  children: RawMaterialGroupedRow[];
};

export enum ProductionOrderAnalyticsStatus {
  OK = 'OK',
  IGNORED = 'IGNORED',
  IN_AMOUNT_MISMATCH = 'IN_AMOUNT_MISMATCH',
  IN_OUT_AMOUNT_DIFFERENCE = 'IN_OUT_AMOUNT_DIFFERENCE',
  IN_SPLIT_AMOUNT_MISMATCH = 'IN_SPLIT_AMOUNT_MISMATCH',
  OUT_AMOUNT_MISMATCH = 'OUT_AMOUNT_MISMATCH',
  PRIMARY_AMOUNT_MISMATCH = 'PRIMARY_AMOUNT_MISMATCH',
  MISSING_GLOBAL_READER_INFO = 'MISSING_GLOBAL_READER_INFO',
  PRIMARY_ITEM_ERROR = 'PRIMARY_ITEM_ERROR',
  ITEM_ERROR = 'ITEM_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export type ProductionOrderAnalytics = {
  analyzedAt: string;
  analyticsStatus: ProductionOrderAnalyticsStatus;
  summary: {
    bcConsumedPcs: number;
    plcConsumedPcs: number;
    plcConsumedSplitPcs: number;
    bcProducedPcs: number;
    plcProducedPcs: number;
    plcProducedSplitPcs: number;
    bcPrimaryPcs: number;
    plcPrimaryPcs: number;
    bcSecondaryPcs: number;
    plcSecondaryPcs: number;
    bcRejectPcs: number;
    plcRejectPcs: number;
    plcUnknownPcs: number;
  };
};

export type RawMaterialAnalyticsComparison = {
  vendorGroup?: string;
  vendor: string;
  date: string;
  totalM3: number;
  primaryPercentage: number;
  avgMoisture: number;
};

export type VendorsComparisonRequest = {
  vendors: string[];
  consumedSpecies: string;
  consumedWidth: number;
  consumedThickness: number;
} & RawMaterialAnalyticsFilter;

export type ProductionFilterValues = {
  vendors: GroupedVendor[];
  species: string[];
  groupNames: string[];
  qualities: string[];
  profileTypes: string[];
  profileCodes: string[];
  lengths: number[];
  actualThicknesses: number[];
  actualWidths: number[];
  nominalThicknesses: number[];
  nominalWidths: number[];
  actualDimensions: string[];
  nominalDimensions: string[];
  moistures: string[];
};

export type ProducedPackageConsumedPackage = {
  productionOrderId: number;
  vendor: string;
  packageNumber: string;
  consumedItemNo: string;
  itemDescription: string;
  registeredAt: string;
  totalConsumedPieces: number;
  producedPackagePcs: number;
  consumedPieceM3: number;
  producedPieceM3: number;
  producedPcs: number;
  primaryPct: number;
  secondaryPct: number;
  rejectedPct: number;
  outputPct: number;
  avgMoisture: number;
};

export type ProducedPackageTracing = {
  productionOrderId: number;
  productionOrderNumber: number;
  productionStartDate: string;
  packageRegistrationDate: string;
  productionStartEnd: string;
  itemDescription: string;
  producedPackageM3: number;
  consumedPackages: ProducedPackageConsumedPackage[];
};

export type UpdateProductionOrderAnalyticsStatusRequest = {
  status: ProductionOrderAnalyticsStatus;
};

export type ProductionOrder = {
  id: number;
  no: string;
  workStation: string;
  productionFinished: boolean;
  queueNo: number;
  details: ProductionOrderAnalytics;
  globalReader: {
    startAt: string;
    endAt: string;
  };
};

export type Vendor = {
  no: string;
  name: string;
  parentNo: string;
};

export type GroupedVendor = {
  id: string;
  label: string;
  parentId?: string;
  children?: GroupedVendor[];
};

export type WorkStation = {
  id: string;
  label: string;
};

export type WithVendor = {
  vendor: string;
  vendorGroup?: string;
};
