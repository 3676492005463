import { Grid } from '@mui/material';

export interface GridRowProps {
  visible: boolean;
  label: string;
  value?: string;
}

export const GridRow = ({ visible, label, value }: GridRowProps): JSX.Element => {
  return visible ? (
    <Grid container columnGap="20px">
      <Grid item xs={3} className="grid-label">
        <div>{label}</div>
      </Grid>
      <Grid item xs className="padding-right-8">
        <div>{value}</div>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
