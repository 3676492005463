import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from '../../domain/StatusType';

export interface User {
  id: number;
  employeeId: number;
  name?: string;
  email?: string;
  phone?: string;
  customer?: string;
  salesperson?: string;
  status: StatusType;
  customerStatus: StatusType;
  isSpectator: boolean;
}

export interface UserState {
  filter: UserFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface UserFilter {
  name?: string[];
  email?: string[];
  customer?: string[];
  salesperson?: string[];
  status?: StatusType[];
  isSpectator?: boolean;
}

export interface UpdateUserRoleRequest {
  employeeId: number;
  isSpectator: boolean;
}

const slice = createSlice({
  name: 'users',
  initialState: {
    filter: {},
    page: 0,
    pageSize: 25,
    sort: [{ field: 'name', sort: 'asc' }]
  } as UserState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    setFilterValue: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: keyof UserFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setPage, setPageSize, setSort } = slice.actions;
