import React, { useState } from 'react';
import { DateRangeFilter } from '../../../../components/DateRangeFilter';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RawMaterialAnalyticsDetailsFilterState } from './RawMaterialAnalyticsDetails';
import { VendorSelect } from '../../VendorSelect';

type RawMaterialAnalyticsDetailsFilterProps = {
  filter: RawMaterialAnalyticsDetailsFilterState;
  search: (filter: RawMaterialAnalyticsDetailsFilterState) => void;
  mainVendor?: string | null;
};

export const RawMaterialAnalyticsDetailsFilter = ({
  filter,
  search,
  mainVendor
}: RawMaterialAnalyticsDetailsFilterProps) => {
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState(filter);

  return (
    <Grid container spacing={1.25} style={{ paddingLeft: '40px' }}>
      <DateRangeFilter
        id="date"
        width="160px"
        label="analytics.datepicker"
        valueFrom={filterState.startDate ? new Date(filterState.startDate) : null}
        valueTo={filterState.endDate ? new Date(filterState.endDate) : null}
        onChange={([startDate, endDate]) =>
          setFilterState({
            ...filterState,
            startDate: startDate?.toJSON(),
            endDate: endDate?.toJSON()
          })
        }
      />
      {mainVendor && (
        <VendorSelect
          id="vendors"
          minWidth={'250px'}
          label={t('analytics.vendor')}
          value={filterState.vendors}
          onChange={(event, value) => setFilterState({ ...filterState, vendors: value })}
        />
      )}
      <Grid item>
        <Button onClick={() => search(filterState)}>{t('button.search')}</Button>
      </Grid>
    </Grid>
  );
};
