import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { formatNumber } from '../../utils/textFormatUtils';
import { UnitType } from '../../domain/UnitType';
import React, { useState } from 'react';
import { ExpandedOrderItem, Order } from './orderSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useGetOrderItemsAmountTotalQuery } from '../api/orderApi';
import { useTranslation } from 'react-i18next';
import { roundToInteger, roundToTwoDecimals } from '../../utils/mathUtils';

export interface OrderItemsTableFooterProps {
  order: Order;
  orderItems?: ExpandedOrderItem[];
  isPreview: Boolean;
}

export const OrderItemsTableFooter = ({
  orderItems,
  order,
  isPreview
}: OrderItemsTableFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const [footerUnit, setFooterUnit] = useState(user?.defaultUnit!);
  const { data: totalAmount } = useGetOrderItemsAmountTotalQuery({
    orderId: order.id,
    unit: footerUnit
  });

  const calculatePacks = () => {
    return (
      orderItems?.reduce((sum, order) => sum + (roundToInteger(order.packs as number) || 0), 0) || 0
    );
  };

  const calculatePrice = (): number => {
    return (
      orderItems?.reduce((sum, order) => sum + (order.price || 0) * (order.quantity || 0), 0) || 0
    );
  };

  const calculateTotalAmount = () => {
    if (footerUnit === UnitType.PACK) {
      return roundToInteger(totalAmount as number);
    }
    return totalAmount;
  };

  return (
    <Grid
      container
      sx={{
        padding: '2px',
        borderTop: '1px solid #D9D9D6',
        background: '#F9F9F9'
      }}
    >
      <Grid item xs className="padding-left-10">
        <p>
          <b>{t('order.items.total')}</b>
        </p>
      </Grid>
      <Grid item xs={1} className="padding-left-10">
        <p>
          <b data-testid={'total-packs'}>{formatNumber(calculatePacks())}</b>
        </p>
      </Grid>
      <Grid item xs={1.5} className="padding-left-10">
        <Grid container>
          <Grid item className="padding-right-8">
            <p>
              <b data-testid={'total-amount'}>{formatNumber(calculateTotalAmount())}</b>
            </p>
          </Grid>
          <Grid item alignSelf="center">
            <FormControl size="small">
              <InputLabel />
              <Select
                value={footerUnit}
                onChange={(event) => setFooterUnit(event.target.value as UnitType)}
                sx={{ background: '#FFFFFF' }}
              >
                {Object.values(UnitType).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {t(`unitType.${unit}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} className="padding-left-10">
        {isPreview && (
          <p>
            <b data-testid={'total-price'}>
              {formatNumber(Number(roundToTwoDecimals(calculatePrice())))}
            </b>
          </p>
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};
