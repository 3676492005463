import { Button, Grid, Link, Paper, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation, useResetPasswordMutation } from '../api/apiSlice';
import { AuthRequest, ResetPasswordRequest, setAuthentication } from './authSlice';
import { Loader } from '../../components/Loader';
import { RootState, useAppDispatch } from '../../store';
import BackgroundImage from '../../theme/background.jpeg';
import { getDefaultRouteByPermission } from '../../utils/authUtils';

export const Login = (): JSX.Element => {
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin, setIsLogin] = useState(true);

  const onLoginSubmit = (authRequest: { [key: string]: any }) => {
    login(authRequest as AuthRequest)
      .unwrap()
      .then((authentication) => {
        dispatch(setAuthentication(authentication));
        enqueueSnackbar(t('login.successfulLogin'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onPasswordResetSubmit = (passwordResetRequest: { [key: string]: any }) => {
    resetPassword(passwordResetRequest as ResetPasswordRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('login.successfulReset'), { variant: 'success' });
        setIsLogin(true);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (user) {
      navigate(getDefaultRouteByPermission(user?.permissions));
    }
  });

  return (
    <>
      {(isLoginLoading || isResetPasswordLoading) && <Loader />}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '100vh'
        }}
      >
        <Grid item>
          <Paper elevation={8} className="padding-16" sx={{ width: '500px' }}>
            {isLogin && (
              <form onSubmit={handleSubmit(onLoginSubmit)} noValidate>
                <Grid container direction="column">
                  <Grid item className="text-align-center">
                    <img width={474} height={73} src={'/logo_dark.png'} alt="Puidukoda" />
                  </Grid>
                  <Grid item className="margin-top-16 font-24-regular">
                    {t('login.title')}
                  </Grid>
                  <Grid item className="margin-top-16">
                    <TextField
                      id="username"
                      label={t('login.username')}
                      required
                      fullWidth
                      error={!!errors.username}
                      {...register('username', { required: true })}
                    />
                  </Grid>
                  <Grid item className="margin-top-16">
                    <TextField
                      id="password"
                      type="password"
                      label={t('login.password')}
                      required
                      fullWidth
                      error={!!errors.password}
                      {...register('password', { required: true })}
                    />
                  </Grid>
                  <Grid item>
                    <Button fullWidth type="submit" className="margin-top-16">
                      {t('login.submitLogin')}
                    </Button>
                  </Grid>
                  <Grid item className="margin-top-16 margin-bottom-24">
                    <Link href="#" onClick={() => setIsLogin(false)}>
                      {t('login.forgotPassword')}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            )}
            {!isLogin && (
              <form onSubmit={handleSubmit(onPasswordResetSubmit)} noValidate>
                <Grid container direction="column">
                  <Grid item className="text-align-center">
                    <img width={474} height={73} src={'/logo_dark.png'} alt="Puidukoda" />
                  </Grid>
                  <Grid item className="margin-top-16 font-24-regular">
                    {t('login.forgotPassword')}
                  </Grid>
                  <Grid item className="margin-top-16">
                    {t('login.forgotPasswordHelper')}
                  </Grid>
                  <Grid item className="margin-top-16">
                    <TextField
                      id="username"
                      label={t('login.username')}
                      required
                      fullWidth
                      error={!!errors.username}
                      {...register('username', { required: true })}
                    />
                  </Grid>
                  <Grid item>
                    <Button fullWidth type="submit" className="margin-top-16">
                      {t('login.submitReset')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => setIsLogin(true)}
                      fullWidth
                      variant="outlined"
                      className="margin-top-16"
                    >
                      {t('button.back')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
