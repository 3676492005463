import { GroupedVendor, RawMaterialAnalyticsComparison } from '../../index';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export type VendorChartData = {
  vendor: string;
  date: string;
  amount: number;
  primaryPercentage: number;
  moisture: number;
};

export type VendorChartVendorData = {
  [vendor: string]: VendorChartData[];
};

type VendorDateGroup = {
  [date: string]: VendorChartData;
};

const mapToVendorChartData = (row: RawMaterialAnalyticsComparison) =>
  ({
    vendor: row.vendor,
    date: row.date,
    amount: row.totalM3,
    primaryPercentage: row.primaryPercentage,
    moisture: row.avgMoisture
  }) as VendorChartData;

export const mapVendorGroup = (data: RawMaterialAnalyticsComparison[]): VendorChartVendorData => {
  return data.reduce((buffer, row) => {
    buffer[row.vendor] = (buffer[row.vendor] || []).concat(mapToVendorChartData(row));
    return buffer;
  }, {} as VendorChartVendorData);
};

const prepareActualVendorsSet = (
  vendorsMap: Map<string, GroupedVendor>,
  mainVendor: string,
  compareVendors?: string[]
) => {
  const actualVendors = new Set<string>();

  const addLowestLevelVendorToSet = (vendorId: string) => {
    const vendor = vendorsMap.get(vendorId);
    if (vendor?.children?.length) {
      vendor?.children.forEach((c) => actualVendors.add(c.id));
    } else if (vendor) {
      actualVendors.add(vendor?.id);
    }
  };

  addLowestLevelVendorToSet(mainVendor);
  compareVendors?.forEach((cv) => addLowestLevelVendorToSet(cv));

  return actualVendors;
};

export const mapByVendor = (
  data: RawMaterialAnalyticsComparison[],
  vendorsMap: Map<string, GroupedVendor>,
  mainVendor: string,
  compareVendors?: string[]
): VendorChartVendorData => {
  const actualVendors = prepareActualVendorsSet(vendorsMap, mainVendor, compareVendors);
  const result: VendorChartVendorData = {};
  data
    .filter((row) => actualVendors.has(row.vendor))
    .map(mapToVendorChartData)
    .forEach((row) => {
      const list = result[row.vendor] || [];
      list.push(row);
      result[row.vendor] = list;
    });

  const others = data
    .filter((row) => !actualVendors.has(row.vendor))
    .reduce((buffer, row) => {
      let group = buffer[row.date];
      if (group) {
        group.amount = group.amount + row.totalM3;
        group.moisture = (group.moisture || 0) + row.totalM3 * row.avgMoisture;
      } else {
        group = {
          vendor: row.vendor,
          date: row.date,
          amount: row.totalM3,
          moisture: row.totalM3 * row.avgMoisture,
          primaryPercentage: row.primaryPercentage
        };
      }

      buffer[row.date] = group;
      return buffer;
    }, {} as VendorDateGroup);

  result['OTHERS'] = Object.entries(others).map(([date, row]) => ({
    vendor: 'OTHERS',
    date: date,
    amount: row.amount,
    primaryPercentage: row.primaryPercentage,
    moisture: row.moisture / row.amount
  }));

  return result;
};

export const useTranslateVendor = (): { tVendor: (vendor: string) => string } => {
  const { t } = useTranslation();
  const tVendor = useCallback(
    (vendor: string) => {
      return vendor === 'OTHERS' ? t('analytics.others') : vendor;
    },
    [t]
  );

  return { tVendor };
};
