import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemAttributes } from '../../domain/ItemAttributes';
import { UnitType } from '../../domain/UnitType';

export interface SalesOrderItem {
  id: number;
  availableQuantity: number;
  availablePacks: number;
  packQuantity: number;
  unit: UnitType;
  customerItemName?: string;
  customerItemNumber?: string;
  customerItemType?: string;
  itemName?: string;
  itemNumber?: string;
  itemTranslations: { [key: string]: string };
  orderQuantity?: number;
}

export interface EditSalesOrderItem {
  id: number;
  availableQuantity: number;
  availablePacks: number;
  packQuantity: number;
  unit: UnitType;
  customerItemId: number;
  customerItemName?: string;
  customerItemNumber?: string;
  itemName?: string;
  itemNumber?: string;
  itemTranslations: { [key: string]: string };
  orderQuantity?: number;
  orderPacks: number;
  orderAllAvailable: boolean;
}

export interface SalesOrderItemUpdateRequest {
  orderItemId?: number;
  orderAmount?: number;
  unit?: UnitType;
}

export interface SalesOrderItemState {
  filter: SalesOrderItemFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface SalesOrderItemFilter extends ItemAttributes {
  customerItemType?: string;
  searchField?: string;
  onlyAvailableItems?: boolean;
}

export interface OrderItemCreateRequest {
  customerItemId: number;
  quantity: number;
  unit: UnitType;
}

export interface OrderItemGetRequest {
  customerItemId: number;
  unit: UnitType;
}

export interface OrderItemDeleteRequest {
  orderId: number;
  orderItemId: number;
}

const slice = createSlice({
  name: 'orderItems',
  initialState: {
    filter: {},
    page: 0,
    pageSize: 10,
    sort: [{ field: 'customerItemNumber', sort: 'asc' }]
  } as SalesOrderItemState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    setFilterValue: (
      state,
      {
        payload: { field, value }
      }: PayloadAction<{ field: keyof SalesOrderItemFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setPage, setPageSize, setSort } = slice.actions;
