import { useGetWorkStationsQuery } from '../../api/analyticsApi';
import { useMemo } from 'react';

export const useWorkStations = (): { workStationIds: string[]; isLoading: boolean } => {
  const { data, isFetching } = useGetWorkStationsQuery();
  return useMemo(
    () => ({
      workStationIds: data?.map((ws) => ws.id) || [],
      isLoading: isFetching
    }),
    [isFetching, data]
  );
};
