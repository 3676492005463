import { Button, Grid, Icon, Step, StepButton, Stepper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Order } from './orderSlice';
import { RouteType } from '../../domain/RouteType';
import { OrderStatus } from './OrderStatus';
import { OrderStep } from '../../domain/OrderStep';

export interface OrderHeaderProps {
  order?: Order;
  isEditable?: boolean;
  activeStep?: number;
  setActiveStep: Function;
}

export const OrderHeader = ({
  order,
  isEditable,
  activeStep,
  setActiveStep
}: OrderHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <Button variant="text" className="link" onClick={() => navigate(RouteType.ORDERS)}>
            <Icon>arrow_back</Icon>
          </Button>
        </Grid>
        {order && (
          <>
            <Grid item className="font-20-bold margin-left-8">
              {order.orderNumber || t('order.titlePlaceholder')}
            </Grid>
            <Grid item className="margin-left-16">
              <OrderStatus status={order.status} />
            </Grid>
          </>
        )}
      </Grid>

      {isEditable && (
        <Grid container className="margin-top-16 margin-bottom-8">
          <Grid xs={4} item>
            <Stepper nonLinear activeStep={activeStep}>
              {Object.values(OrderStep).map((step, index) => (
                <Step key={index} className="clickable">
                  <StepButton onClick={() => setActiveStep(index)}>
                    {t(`orderStep.${step}`)}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      )}
    </>
  );
};
