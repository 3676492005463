import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemAttributes } from '../../domain/ItemAttributes';

export interface CustomerItem {
  id: number;
  number: string;
  description: string;
  companyId?: number;
  customerItemNumber?: string;
  customerItemName?: string;
  customerItemType?: string;
  active: boolean;
}

export interface CustomerItemState {
  filter: CustomerItemFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface CustomerItemFilter extends ItemAttributes {
  number?: string[];
  description?: string[];
  customerItemNumber?: string[];
  customerItemName?: string[];
  customerItemType?: string[];
  active?: boolean;
}

export interface CustomerItemUpdateRequest {
  itemId: number;
  active: boolean;
  number?: string;
  name?: string;
  type?: string;
}

export interface CustomerItemUpdateRequestWrapper {
  companyId: number;
  body: CustomerItemUpdateRequest[];
}

const slice = createSlice({
  name: 'customerItems',
  initialState: {
    filter: { active: true },
    page: 0,
    pageSize: 25,
    sort: [{ field: 'number', sort: 'asc' }]
  } as CustomerItemState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    resetFilter: (state) => {
      state.filter = { active: true };
    },
    setFilterValue: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: keyof CustomerItemFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, resetFilter, setFilterValue, setPage, setPageSize, setSort } =
  slice.actions;
