import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from '../../domain/StatusType';
import { RoleType } from '../../domain/RoleType';

export interface Employee {
  id: number;
  employeeId: number;
  name?: string;
  email?: string;
  phone?: string;
  status: StatusType;
  roles: RoleType[];
}

export interface EmployeeState {
  filter: EmployeeFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface EmployeeFilter {
  name?: string[];
  email?: string[];
  status?: StatusType[];
  role?: RoleType;
}

export interface UpdateRoleRequest {
  employeeId: number;
  isAdmin: boolean;
}

const slice = createSlice({
  name: 'employees',
  initialState: {
    filter: {},
    page: 0,
    pageSize: 25,
    sort: [{ field: 'name', sort: 'asc' }]
  } as EmployeeState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    setFilterValue: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: keyof EmployeeFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setPage, setPageSize, setSort } = slice.actions;
