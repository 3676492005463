export const validateNumber = (number: string, name: string, itemsNumbers: string[], t: any) => {
  const isDuplicate = number && itemsNumbers?.includes(number.toLowerCase().trim());
  const isRequired = name && !number;
  return isDuplicate
    ? t('items.table.uniqueNumberError', { number: number.trim() })
    : isRequired
      ? t('items.table.requiredNumberError')
      : null;
};

export const validateName = (name: string, number: string, itemsNames: string[], t: any) => {
  const isDuplicate = name && itemsNames?.includes(name.toLowerCase().trim());
  const isRequired = number && !name;
  return isDuplicate
    ? t('items.table.uniqueNameError', { name: name.trim() })
    : isRequired
      ? t('items.table.requiredNameError')
      : null;
};
