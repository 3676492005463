export enum AttributeType {
  ACTUAL_THICKNESS = 'ACTUAL_THICKNESS',
  ACTUAL_WIDTH = 'ACTUAL_WIDTH',
  CE = 'CE',
  CERTIFICATE = 'CERTIFICATE',
  COVER_PLASTIC = 'COVER_PLASTIC',
  CROSS_SECTION_GROUP = 'CROSS_SECTION_GROUP',
  FOIL_TYPE = 'FOIL_TYPE',
  GROUP = 'GROUP',
  LENGTH = 'LENGTH',
  MOISTURE_CONTENT = 'MOISTURE_CONTENT',
  NOMINAL_THICKNESS = 'NOMINAL_THICKNESS',
  NOMINAL_WIDTH = 'NOMINAL_WIDTH',
  PACKAGING_METHOD = 'PACKAGING_METHOD',
  PAINTING_PROCESS = 'PAINTING_PROCESS',
  PROFILE_NO = 'PROFILE_NO',
  PROFILE_TYPE = 'PROFILE_TYPE',
  QUALITY = 'QUALITY',
  SPECIES = 'SPECIES',
  SUPER_GROUP = 'SUPER_GROUP',
  SURFACE = 'SURFACE',
  TREATMENT = 'TREATMENT'
}
