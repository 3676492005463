// noinspection ES6UnusedImports

import React from 'react';
import { alpha, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { RenderRoutes } from './router/Routes';
import { Provider } from 'react-redux';
import { store } from './store';
import { AuthProvider } from './components/AuthProvider';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './utils/snackbarUtils';
import { useTranslation } from 'react-i18next';
import { Language } from './utils/langUtils';
import { enUS as coreEnUS, etEE as coreEtEE } from '@mui/material/locale';
import { enUS as gridEnUS } from '@mui/x-data-grid/locales';
import { enUS as datePickersEnUS } from '@mui/x-date-pickers/locales';
import { gridEtEE } from './utils/gridEtEE';
import { datePickersEtEE } from './utils/datePickersEtEE';
import {} from '@mui/x-data-grid-pro/themeAugmentation';

const primaryColor = '#ff801c';
const secondaryColor = '#515151';

const App = (): JSX.Element => {
  const {
    i18n: { language },
    t
  } = useTranslation();

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: primaryColor,
          contrastText: '#fff'
        },
        secondary: {
          main: secondaryColor,
          contrastText: primaryColor
        }
      },
      typography: {
        fontFamily: ['Intro-Regular', 'sans-serif'].join(',')
      },
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'contained'
          },
          styleOverrides: {
            root: {
              letterSpacing: 0.2,
              height: 34,
              paddingTop: '8px'
            },
            containedPrimary: {
              backgroundColor: secondaryColor,
              color: primaryColor,
              '&:hover': {
                backgroundColor: '#282828'
              }
            },
            containedSecondary: {
              backgroundColor: '#fff',
              color: primaryColor,
              lineHeight: 'normal',
              '&:hover': {
                backgroundColor: alpha('#282828', 0.7)
              }
            },
            outlinedSecondary: {
              backgroundColor: alpha(secondaryColor, 0.5),
              '&:hover': {
                backgroundColor: '#d9d9d6'
              }
            },
            fullWidth: {
              height: 36
            },
            startIcon: {
              marginTop: '-4px'
            }
          }
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: secondaryColor,
              color: '#ffffff'
            }
          }
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              height: 80
            }
          }
        },
        MuiIcon: {
          defaultProps: {
            color: 'primary'
          }
        },
        MuiSvgIcon: {
          defaultProps: {
            color: 'primary'
          }
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              fill: primaryColor
            }
          }
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: primaryColor
            }
          }
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                backgroundColor: alpha(secondaryColor, 0.1),
                '&:hover': {
                  backgroundColor: alpha(secondaryColor, 0.2)
                },
                '& .MuiTypography-root': {
                  fontWeight: 600,
                  color: primaryColor
                }
              },
              '&:hover': {
                backgroundColor: alpha(secondaryColor, 0.1)
              }
            }
          }
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              '& .MuiDataGrid-footerContainer': {
                height: 48,
                minHeight: 48
              },
              '& .MuiDataGrid-cell': {
                '&:focus-within': {
                  outline: 'none'
                }
              },
              '& .MuiToolbar-root': {
                height: 48,
                minHeight: 48
              }
            }
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: '#fc3a2c',
              '&$error': {
                color: '#fc3a2c'
              }
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: 14
            }
          }
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              maxWidth: 'initial'
            }
          }
        },
        MuiStepButton: {
          styleOverrides: {
            root: {
              paddingTop: '8px',
              paddingBottom: '8px'
            }
          }
        }
      }
    },
    language === Language.ET ? coreEtEE : coreEnUS,
    language === Language.ET ? gridEtEE : gridEnUS,
    language === Language.ET ? datePickersEtEE : datePickersEnUS
  );

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider preventDuplicate>
            <SnackbarUtilsConfigurator t={t} />
            <AuthProvider>
              <RenderRoutes />
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
