export enum RouteType {
  CUSTOMERS = '/customers',
  CUSTOMER_DETAILS = '/customers/:customerId',
  EMPLOYEES = '/employees',
  LOGIN = '/login',
  NEW_PASSWORD = '/new-password/:verificationToken',
  ORDERS = '/orders',
  ORDER_DETAILS = '/orders/:orderId',
  STOCK = '/stock',
  USERS = '/users',
  RAW_MATERIAL_ANALYTICS = '/raw-material-analytics',
  RAW_MATERIAL_ANALYTICS_COMPARISON = '/raw-material-analytics/comparison',
  PRODUCTION_ANALYTICS = '/production-analytics',
  PRODUCTION_ANALYTICS_ERRORS = '/production-analytics/errors',
  MOISTURE_ANALYTICS = '/moisture-analytics',
  PACKAGE_TRACING = '/package-tracing'
}
