import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductionOrderDetailsFilter {
  workStations: string[];
  productionOrderNumber: string;
}

export interface ProductionOrderDetailsState {
  filter: ProductionOrderDetailsFilter;
  page: number;
  pageSize: number;
}

const slice = createSlice({
  name: 'productionOrderDetails',
  initialState: {
    filter: {
      workStations: [],
      productionOrderNumber: ''
    },
    page: 0,
    pageSize: 20
  } as ProductionOrderDetailsState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {
        workStations: [],
        productionOrderNumber: ''
      };
    },
    setFilter: (state, { payload }: PayloadAction<ProductionOrderDetailsFilter>) => {
      state.filter = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setPage, setPageSize, setFilter } = slice.actions;
