export enum OrderStatusType {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  AWAITS_RESPONSE = 'AWAITS_RESPONSE',
  ACCEPTED = 'ACCEPTED',
  SHIPPED = 'SHIPPED',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  DISCARDED = 'DISCARDED'
}

export const EDITABLE_ORDER_STATUS_TYPES = [OrderStatusType.DRAFT, OrderStatusType.AWAITS_RESPONSE];
