export const roundToTwoDecimals = (inputNumber: number): number => {
  return Math.round(inputNumber * 100) / 100;
};

export const roundToInteger = (inputNumber: number): number => {
  return Math.round(inputNumber);
};

export const uniqueFilter = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

export const getStandardDeviation = (array: number[]) => {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
};
