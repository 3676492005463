import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton
} from '@mui/material';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { ExpandedOrderItem } from './orderSlice';
import { formatNumber } from '../../utils/textFormatUtils';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { roundToTwoDecimals } from '../../utils/mathUtils';

export interface AvailabilityModalProps {
  isOpen: boolean;
  handleClose: () => void;
  orderItems?: ExpandedOrderItem[];
}

export const AvailabilityModal = ({
  isOpen,
  handleClose,
  orderItems
}: AvailabilityModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { language } = useSelector((state: RootState) => state.language);

  const columns: GridColDef[] = [
    { field: 'customerItemNumber', headerName: t('order.items.customerItemNumber'), flex: 1 },
    { field: 'customerItemName', headerName: t('order.items.customerItemName'), flex: 2 },
    { field: 'number', headerName: t('order.items.number'), flex: 1 },
    {
      field: 'description',
      headerName: t('order.items.description'),
      flex: 2,
      renderCell: (params) => (
        <span>{params.row.translations[language] || params.row.description}</span>
      )
    },
    {
      field: 'quantity',
      headerName: t('order.items.orderQuantity'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        t('order.items.quantityValue', {
          quantity: formatNumber(roundToTwoDecimals(Number(params.value))),
          unit: t(`unitType.${params.row.unit}`)
        })
    },
    {
      field: 'availableQuantity',
      headerName: t('order.items.inventory'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        t('order.items.quantityValue', {
          quantity:
            (params.row.availableQuantity > 0 && formatNumber(params.row.availableQuantity)) || 0,
          unit: t(`unitType.${params.row.unit}`)
        })
    }
  ];

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{t('order.availabilityModal.title')}</Grid>
          <Grid item>
            <IconButton onClick={handleClose} sx={{ marginTop: '-20px', marginRight: '-24px' }}>
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ width: '75vw' }}>
          <Grid item xs sx={{ height: '50vh' }}>
            <DataGridPro
              columns={columns}
              disableColumnMenu
              disableRowSelectionOnClick
              disableVirtualization={process.env.NODE_ENV === 'test'}
              columnHeaderHeight={46}
              hideFooter
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    customerItemNumber: !!user?.customNumbers,
                    customerItemName: !!user?.customNames
                  }
                }
              }}
              rowHeight={40}
              rows={(orderItems || []) as GridRowsProp}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" autoFocus>
          {t('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
