import format from 'date-fns/format';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const YEAR_MONTH_FORMAT = 'MM.yyyy';
export const DATE_MASK = '__.__.____';

export const formatDateString = (value?: string): string => {
  return (value && format(new Date(value), DATE_FORMAT)) || '';
};

export const formatDateTimeString = (value?: string): string => {
  return (value && format(new Date(value), DATE_TIME_FORMAT)) || '';
};

export const formatYearMonthString = (value?: string): string => {
  return (value && format(new Date(value), YEAR_MONTH_FORMAT)) || '';
};
