import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface TermsAndConditionsModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const TermsAndConditionsModal = ({
  isOpen,
  handleClose
}: TermsAndConditionsModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{t('order.termsAndConditionsModal.headerTitle')}</Grid>
          <Grid item>
            <IconButton onClick={handleClose} sx={{ marginTop: '-20px', marginRight: '-24px' }}>
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ width: '75vw' }}>
          <Grid item xs sx={{ height: '50vh' }}>
            <p>
              <strong>
                <span>{t('order.termsAndConditionsModal.documentTitle')}</span>
              </strong>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.documentLocationText')}</span>
              <a href={t('order.termsAndConditionsModal.documentLocationWebsite')}>
                <span>{t('order.termsAndConditionsModal.documentLocationWebsite')}</span>
              </a>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphOne.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphOne.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphOne.body.paragraphTwo')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphOne.body.paragraphThree')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphOne.body.paragraphFour')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphTwo.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphTwo.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <ol>
                  <p>
                    <span>
                      {t(
                        'order.termsAndConditionsModal.paragraphTwo.body.paragraphOneBody.paragraphOne'
                      )}
                    </span>
                  </p>
                </ol>
                <ol>
                  <p>
                    <span>
                      {t(
                        'order.termsAndConditionsModal.paragraphTwo.body.paragraphOneBody.paragraphTwo'
                      )}
                    </span>
                  </p>
                </ol>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphTwo.body.paragraphTwo')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphThree.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphThree.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <strong>
                    <span>
                      {t('order.termsAndConditionsModal.paragraphThree.body.paragraphTwo')}
                    </span>
                  </strong>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphFour.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphFour.body.paragraphOne')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphFive.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphFive.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphFive.body.paragraphTwo')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>
                    {t('order.termsAndConditionsModal.paragraphFive.body.paragraphThree')}
                  </span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphSix.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphSix.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphSix.body.paragraphTwo')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphSix.body.paragraphThree')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphSeven.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphSeven.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphSeven.body.paragraphTwo')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphEight.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphEight.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphEight.body.paragraphTwo')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>
                    {t('order.termsAndConditionsModal.paragraphEight.body.paragraphThree')}
                  </span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphNine.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphNine.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphNine.body.paragraphTwo')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>
                    {t('order.termsAndConditionsModal.paragraphNine.body.paragraphThree')}
                  </span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphTen.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphTen.body.paragraphOne')}</span>
                </p>
              </ol>
              <ol>
                <p>
                  <span>{t('order.termsAndConditionsModal.paragraphTen.body.paragraphTwo')}</span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>{t('order.termsAndConditionsModal.paragraphEleven.title')}</span>
            </p>
            <div>
              <ol>
                <p>
                  <span>
                    {t('order.termsAndConditionsModal.paragraphEleven.body.paragraphOne')}
                  </span>
                </p>
              </ol>
            </div>
            <p>
              <span>&nbsp;</span>
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" autoFocus>
          {t('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
