import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EditSalesOrderItem, setSort } from './orderItemSlice';
import {
  useDeleteOrderItemMutation,
  useGetOrderItemsQuery,
  useGetSalesOrderItemMutation,
  useUpdateSalesOrderItemMutation
} from '../api/orderApi';
import { UnitType } from '../../domain/UnitType';
import { ExpandedOrderItem, Order } from './orderSlice';
import { formatNumber } from '../../utils/textFormatUtils';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { OrderItemsTableFooter } from './OrderItemsTableFooter';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { roundToInteger, roundToTwoDecimals } from '../../utils/mathUtils';

export interface OrderItemsProps {
  lazy?: boolean;
  order: Order;
  orderItems?: ExpandedOrderItem[];
}

export const OrderItemsTable = ({ lazy, order, orderItems }: OrderItemsProps): JSX.Element => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isEdit, setIsEdit] = useState(false);
  const [editOrderItem, setEditOrderItem] = useState<EditSalesOrderItem>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState<number>();
  const [getSalesOrderItem] = useGetSalesOrderItemMutation();
  const [deleteOrderItem] = useDeleteOrderItemMutation();
  const [updateOrderItem] = useUpdateSalesOrderItemMutation();
  const {
    watch,
    register,
    formState: { errors }
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const watchAllFields = watch();
  const { language } = useSelector((state: RootState) => state.language);
  const { data: lazyLoadedOrderItems, isLoading: isOrderItemsLoading } = useGetOrderItemsQuery(
    order.id?.toString(),
    { skip: !lazy }
  );

  useEffect(() => {
    dispatch(
      setSort([{ field: !!user?.customNumbers ? 'customerItemNumber' : 'itemName', sort: 'asc' }])
    );
  }, [dispatch, user]);

  const onChangeUnit = (params: GridRenderCellParams, unit: UnitType) => {
    getSalesOrderItem({
      customerItemId: params!.row.customerItemId,
      unit: unit
    })
      .unwrap()
      .then((response) => {
        editOrderItem!.availableQuantity = response.availableQuantity;
        editOrderItem!.availablePacks = roundToInteger(response.availablePacks);
        editOrderItem!.packQuantity = response.packQuantity;
        editOrderItem!.unit = response.unit;
        editOrderItem!.orderQuantity = getOrderQuantity(params);
      });
  };

  const getOrderQuantity = (params: GridRenderCellParams<any>): number | undefined => {
    const fields = watchAllFields[params.id];
    if (params.row.orderAllAvailable) {
      editOrderItem!.orderQuantity = params.row.availableQuantity;
      return params.row.availableQuantity;
    }
    if (fields.orderPacks <= 0) {
      return 0;
    }
    return fields.orderPacks * params.row.packQuantity;
  };

  const getOrderPacks = (params: GridRenderCellParams<any>) => {
    return getOrderQuantity(params)! / params!.row.packQuantity;
  };

  const getAllAvailableOrderPacks = (params: GridRenderCellParams<any>) => {
    if (params.row.availablePacks === 0 && params.row.availableQuantity !== 0) {
      return 1;
    }
    return params.row.availablePacks;
  };

  const openConfirmDeleteDialog = (orderItemId: number) => {
    setOrderItemId(orderItemId);
    setConfirmOpen(true);
  };

  const handleEventDeleteOrderItem = () => {
    if (orderItemId) {
      deleteOrderItem({ orderId: +orderId! || order.id, orderItemId })
        .unwrap()
        .then(() => {
          enqueueSnackbar(t('order.items.successfulDelete'), { variant: 'success' });
        })
        .catch(() => {});
    }
  };

  const handleSaveEditedItem = () => {
    if (editOrderItem?.orderQuantity === 0) {
      enqueueSnackbar(t('order.items.quantityMissing'), { variant: 'error' });
      return;
    }
    updateOrderItem({
      orderId: orderId || order.id?.toString(),
      request: {
        orderItemId: editOrderItem?.id,
        orderAmount: editOrderItem?.orderQuantity,
        unit: editOrderItem?.unit
      }
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('order.items.successfulUpdate'), { variant: 'success' });
        setIsEdit(false);
      })
      .catch(() => {});
  };

  const handleUnitChange = (event: SelectChangeEvent, params: GridRenderCellParams) => {
    onChangeUnit(params, event.target.value as UnitType);
  };

  const handleEditClick = (params?: GridRenderCellParams) => {
    const salesOrderItem: EditSalesOrderItem = {
      id: params!.row.id,
      availableQuantity: 0,
      availablePacks: 0,
      packQuantity: 0,
      unit: params!.row.unit,
      customerItemId: params!.row.customerItemId,
      customerItemName: params!.row.customerItemName,
      customerItemNumber: params!.row.customerItemNumber,
      itemName: params!.row.description,
      itemNumber: params!.row.number,
      itemTranslations: params!.row.translations,
      orderQuantity: params!.row.quantity,
      orderPacks: params!.row.packs,
      orderAllAvailable: params!.row.availableQuantity === params!.row.quantity
    };
    getSalesOrderItem({
      customerItemId: params!.row.customerItemId,
      unit: params!.row.unit
    })
      .unwrap()
      .then((response) => {
        salesOrderItem.availableQuantity = response.availableQuantity;
        salesOrderItem.availablePacks = roundToInteger(response.availablePacks);
        salesOrderItem.packQuantity = response.packQuantity;
      });
    setIsEdit(true);
    setEditOrderItem(salesOrderItem);
  };

  const handleEditModelChange = (packs: number, amount: number, checked: boolean) => {
    const salesOrderItem: EditSalesOrderItem = {
      id: editOrderItem!.id,
      availableQuantity: editOrderItem!.availableQuantity,
      availablePacks: editOrderItem!.availablePacks,
      packQuantity: editOrderItem!.packQuantity,
      unit: editOrderItem!.unit,
      customerItemId: editOrderItem!.customerItemId,
      customerItemName: editOrderItem!.customerItemName,
      customerItemNumber: editOrderItem!.customerItemNumber,
      itemName: editOrderItem!.itemName,
      itemNumber: editOrderItem!.itemNumber,
      itemTranslations: editOrderItem!.itemTranslations,
      orderQuantity: amount,
      orderPacks: +packs,
      orderAllAvailable: checked
    };
    setEditOrderItem(salesOrderItem);
  };

  const editOrderItemsColumns: GridColDef[] = [
    {
      field: 'customerItemNumber',
      flex: 1,
      headerName: t('order.items.customerItemNumber')
    },
    {
      field: 'customerItemName',
      flex: 2,
      headerName: t('order.items.customerItemName')
    },
    {
      field: 'itemName',
      flex: 2.5,
      headerName: t('order.items.description'),
      renderCell: (params) => (
        <span className="margin-top-4">
          {params.row.itemTranslations[language] || params.row.itemName}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('order.items.itemNumberValue', { itemNumber: params.row.itemNumber })}
          </FormHelperText>
        </span>
      )
    },
    {
      field: 'availableQuantity',
      flex: 2,
      headerAlign: 'center',
      headerName: t('order.items.availableAmount'),
      align: 'center',
      renderCell: (params) => (
        <Grid container alignItems="center">
          <Grid item xs={4} textAlign="right">
            {formatNumber(roundToTwoDecimals(params.value))}
          </Grid>
          <Grid item xs={8} textAlign="left">
            <FormControl sx={{ marginLeft: '4px' }} size="small" className="table-input">
              <Select
                value={params.row.unit}
                sx={{ width: '110px', height: '28px', fontSize: '14px' }}
                onChange={(event) => handleUnitChange(event, params)}
              >
                {Object.values(UnitType).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {t(`unitType.${unit}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    },
    {
      field: 'availablePacks',
      flex: 1,
      headerName: t('order.items.availablePacks'),
      renderCell: (params) => (
        <div className="margin-top-4" style={{ lineHeight: '28px' }}>
          {roundToInteger(params.row.availablePacks)}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('order.items.availablePacksHelperText', {
              quantity: roundToTwoDecimals(params.row.packQuantity),
              unit: t(`unitType.${params.row.unit}`)
            })}
          </FormHelperText>
        </div>
      )
    },
    {
      field: 'orderPacks',
      flex: 1,
      headerName: t('order.items.orderPacks'),
      renderCell: (params) => (
        <TextField
          className="table-input"
          type="number"
          value={
            roundToInteger(editOrderItem?.orderPacks!) !== 0
              ? roundToInteger(editOrderItem?.orderPacks!)
              : ''
          }
          slotProps={{ input: { inputProps: { min: 0 } } }}
          error={!!(errors[params.row.id] as any)?.orderPacks}
          helperText={(errors[params.row.id] as any)?.orderPacks?.message || ''}
          {...register(`${params.row.id}.orderPacks`, {
            pattern: {
              value: /^([1-9]\d*)$/,
              message: t('order.items.packsError')
            },
            onChange: (onChangeParams) => {
              params!.row.orderAllAvailable = false;
              handleEditModelChange(onChangeParams.target.value, getOrderQuantity(params)!, false);
            }
          })}
        />
      )
    },
    {
      field: 'orderAllAvailable',
      flex: 1,
      headerName: t('order.items.orderAllAvailable'),
      renderCell: (params) => (
        <Checkbox
          {...(register(`${params.row.id}.orderAllAvailable`),
          {
            onChange: (event, checked) => {
              if (checked) {
                handleEditModelChange(
                  getAllAvailableOrderPacks(params),
                  params.row.availableQuantity,
                  checked
                );
              } else {
                params!.row.orderAllAvailable = false;
                handleEditModelChange(getOrderPacks(params), getOrderQuantity(params)!, false);
              }
            }
          })}
          checked={editOrderItem?.orderAllAvailable || false}
        />
      )
    },
    {
      field: 'orderQuantity',
      flex: 1,
      headerName: t('order.items.orderQuantity'),
      renderCell: (params) =>
        editOrderItem!.orderQuantity! > 0
          ? t('order.items.quantityValue', {
              quantity: formatNumber(roundToTwoDecimals(editOrderItem!.orderQuantity!)),
              unit: t(`unitType.${params.row.unit}`)
            })
          : ''
    },
    {
      field: 'edit',
      width: 100,
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: () => (
        <div>
          <IconButton
            onClick={() => {
              setIsEdit(false);
            }}
          >
            <Icon>highlight_off_icon</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              handleSaveEditedItem();
            }}
          >
            <Icon>check_circle</Icon>
          </IconButton>
        </div>
      )
    }
  ];

  const orderItemsColumns: GridColDef[] = [
    { field: 'customerItemNumber', headerName: t('order.items.customerItemNumber'), flex: 1 },
    { field: 'customerItemName', headerName: t('order.items.customerItemName'), flex: 3 },
    {
      field: 'number',
      headerName: t('order.items.number'),
      flex: !user?.customNumbers ? 2.5 : 1.5
    },
    {
      field: 'description',
      headerName: t('order.items.description'),
      flex: !user?.customNames ? 6 : 3,
      renderCell: (params) => (
        <span>{params.row.translations[language] || params.row.description}</span>
      )
    },
    {
      field: 'packs',
      headerName: t('order.items.packs'),
      flex: 1,
      renderCell: (params) => <span>{formatNumber(roundToInteger(params.row.packs))}</span>
    },
    {
      field: 'quantity',
      headerName: t('order.items.quantity'),
      flex: 1.5,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        t('order.items.quantityValue', {
          quantity: formatNumber(roundToTwoDecimals(Number(params.value))),
          unit: t(`unitType.${params.row.unit}`)
        })
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton onClick={() => openConfirmDeleteDialog(params.id as number)}>
              <Icon>delete</Icon>
            </IconButton>
            <IconButton onClick={() => handleEditClick(params)}>
              <Icon>edit</Icon>
            </IconButton>
          </div>
        );
      }
    }
  ];

  const footer = () => {
    return (
      <OrderItemsTableFooter
        orderItems={orderItems || lazyLoadedOrderItems}
        order={order}
        isPreview={false}
      />
    );
  };

  return (
    <Grid
      container
      className={clsx('margin-top-8', {
        'padding-8': lazy
      })}
    >
      <ConfirmDialog
        title={t('order.items.delete')}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => handleEventDeleteOrderItem()}
      >
        {t('order.items.deleteOrderItemDialog')}
      </ConfirmDialog>
      {isEdit && (
        <Grid container className="margin-top-8">
          <Grid container className="margin-top-8">
            <Grid item xs>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <h2>{t('order.preview.editOrderItem')}</h2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="margin-top-8 width-100">
            <Grid item xs sx={{ height: '103px' }}>
              <DataGridPro
                columns={editOrderItemsColumns}
                disableColumnMenu
                disableRowSelectionOnClick
                columnHeaderHeight={46}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      customerItemNumber: !!user?.customNumbers,
                      customerItemName: !!user?.customNames
                    }
                  }
                }}
                rowHeight={55}
                rows={[editOrderItem]}
                rowCount={1}
                hideFooter={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container className="margin-top-8">
        <Grid item xs>
          <Grid container justifyContent="space-between">
            <Grid item>
              <h2>{t('order.preview.orderItems')}</h2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs sx={{ height: '300px' }}>
          <DataGridPro
            columns={orderItemsColumns}
            disableColumnMenu
            disableRowSelectionOnClick
            columnHeaderHeight={46}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  customerItemNumber: !!user?.customNumbers,
                  customerItemName: !!user?.customNames
                }
              }
            }}
            rowHeight={40}
            rows={(orderItems || lazyLoadedOrderItems || []) as GridRowsProp}
            disableVirtualization={process.env.NODE_ENV === 'test'}
            loading={isOrderItemsLoading}
            slots={{
              footer: footer
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
