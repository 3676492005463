import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container className="main-grid" justifyContent="center" alignItems="center">
      <Grid item className="text-align-center">
        <h1>{t('notFound.title')}</h1>
        <div>{t('notFound.description')}</div>
      </Grid>
    </Grid>
  );
};
