import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { barChartOptions } from '../../../utils/chartUtil';
import { mapMoistureAnalyticsChartData } from './MoistureAnalyticsHelper';
import { MoistureAnalyticsResult } from '../index';
import { useTranslation } from 'react-i18next';

export const MoistureAnalyticsChart = ({ data }: { data?: MoistureAnalyticsResult[] }) => {
  const { t } = useTranslation();
  const [labels, chartData] = useMemo(() => mapMoistureAnalyticsChartData(data), [data]);

  return (
    <div style={{ display: 'block', height: 'inherit', width: 'auto', position: 'relative' }}>
      <Bar
        options={barChartOptions}
        data={{
          labels,
          datasets: [
            {
              label: `${t('analytics.moisture')} %`,
              backgroundColor: 'rgb(255, 128, 28)',
              data: chartData
            }
          ]
        }}
      />
    </div>
  );
};
