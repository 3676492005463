import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Filter } from '../../../components/Filter';
import { FilterArea } from '../../../components/FilterArea';
import { DateRange } from '@mui/x-date-pickers-pro';
import { RootState, useAppDispatch } from '../../../store';
import { Button, Grid, Icon } from '@mui/material';
import { DateRangeFilter } from '../../../components/DateRangeFilter';
import {
  useGetFilterValuesForConsumedPackagesQuery,
  useLazyDownloadMoistureAnalyticsQuery
} from '../../api/analyticsApi';
import {
  clearFilter,
  MoistureAnalyticsFilter,
  MoistureAnalyticsInitialState,
  setFilter
} from './moistureAnalyticsSlice';
import { splitValueOrEmptyArray } from '../analyticsHelper';
import { VendorSelect } from '../VendorSelect';

export const MoistureAnalyticsFilters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filter } = useSelector((state: RootState) => state.moistureAnalytics);
  const [filterState, setFilterState] = useState<MoistureAnalyticsFilter>(filter);
  const { data: filterValues } = useGetFilterValuesForConsumedPackagesQuery();
  const [download, { isLoading: isDownloading }] = useLazyDownloadMoistureAnalyticsQuery();

  const handleDateFilterChange = ([startDate, endDate]: DateRange<Date>) => {
    setFilterState({ ...filterState, startDate: startDate?.toJSON(), endDate: endDate?.toJSON() });
  };

  const handleFilterChange = (
    field: keyof MoistureAnalyticsFilter,
    event: any,
    value: string | number
  ) => setFilterState({ ...filterState, [field]: value });

  const searchHandler = () => {
    dispatch(setFilter(filterState));
  };

  const clear = () => {
    dispatch(clearFilter());
    setFilterState(MoistureAnalyticsInitialState.filter);
  };

  return (
    <FilterArea onClear={clear}>
      <DateRangeFilter
        width="150px"
        id="plannedDelivery"
        label="analytics.datepicker"
        valueFrom={filterState.startDate ? new Date(filterState.startDate) : null}
        valueTo={filterState.endDate ? new Date(filterState.endDate) : null}
        onChange={handleDateFilterChange}
      />
      <VendorSelect
        id={'vendors'}
        minWidth="260px"
        label={t('analytics.vendor')}
        value={filterState?.vendor || []}
        onChange={handleFilterChange.bind(this, 'vendor')}
      />
      <Filter
        multiple
        id="species"
        minWidth="200px"
        value={filterState.species}
        label={t('analytics.species')}
        options={filterValues?.species || []}
        onChange={handleFilterChange.bind(this, 'species')}
      />
      <Filter
        multiple
        id="crossSectionGroup"
        minWidth="200px"
        label={t('analytics.dimensions')}
        value={filterState.dimensions}
        options={filterValues?.nominalDimensions || []}
        onChange={handleFilterChange.bind(this, 'dimensions')}
      />
      <Filter
        multiple
        id="quality"
        minWidth="200px"
        label={t('analytics.quality')}
        value={filterState.quality}
        options={filterValues?.qualities || []}
        onChange={handleFilterChange.bind(this, 'quality')}
      />
      <Filter
        numeric
        multiple
        id="length"
        minWidth="200px"
        label={t('analytics.length')}
        value={filterState.length}
        options={filterValues?.lengths || []}
        onChange={handleFilterChange.bind(this, 'length')}
      />
      <Filter
        text
        numeric
        id="minMoisture"
        width="120px"
        label={t('analytics.moistureAnalytics.moistureGt')}
        value={filterState.minMoisture}
        onChange={(event) => handleFilterChange('minMoisture', event, event.target.value)}
      />
      <Filter
        text
        numeric
        width="120px"
        id="maxMoisture"
        label={t('analytics.moistureAnalytics.moistureLt')}
        value={filterState.maxMoisture}
        onChange={(event) => handleFilterChange('maxMoisture', event, event.target.value)}
      />
      <Filter
        text
        id="packageNumber"
        minWidth="150px"
        label={t('analytics.moistureAnalytics.packNum')}
        value={filterState.consumedPackageNumber}
        onChange={(event) => handleFilterChange('consumedPackageNumber', event, event.target.value)}
      />
      <Filter
        text
        id="purchaseDocumentNumber"
        minWidth="150px"
        label={t('analytics.moistureAnalytics.documentNumber')}
        value={filterState.purchaseDocumentNumber}
        onChange={(event) =>
          handleFilterChange('purchaseDocumentNumber', event, event.target.value)
        }
      />
      <Grid item>
        <Button onClick={searchHandler}>{t('button.search')}</Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          disabled={isDownloading}
          onClick={() =>
            download({
              ...filter,
              consumedPackageNumber: splitValueOrEmptyArray(filter.consumedPackageNumber),
              purchaseDocumentNumber: splitValueOrEmptyArray(filter.purchaseDocumentNumber)
            })
          }
          startIcon={<Icon className="margin-top-4">download</Icon>}
        >
          XLSX
        </Button>
      </Grid>
    </FilterArea>
  );
};
