import { apiSlice } from './apiSlice';
import {
  ExpandedOrderItem,
  Order,
  OrderCreateRequest,
  OrderState,
  OrderTableItem,
  OrderTotalAmountRequest,
  OrderUpdateRequest
} from '../order/orderSlice';
import { Page } from '../../domain/Page';
import {
  OrderItemCreateRequest,
  OrderItemDeleteRequest,
  OrderItemGetRequest,
  SalesOrderItem,
  SalesOrderItemState,
  SalesOrderItemUpdateRequest
} from '../order/orderItemSlice';
import { UnitType } from '../../domain/UnitType';

const orderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<Page<OrderTableItem>, OrderState>({
      query: (params) => ({
        url: '/orders',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['Order']
    }),
    getOrderAutocomplete: builder.query<string[], string>({
      query: (fieldName) => ({
        url: `/orders/autocomplete/${fieldName}`
      })
    }),
    getOrder: builder.query<Order, string | undefined>({
      query: (orderId) => ({
        url: `/orders/${orderId || ''}`
      }),
      providesTags: ['Order']
    }),
    createOrder: builder.mutation<number, OrderCreateRequest | undefined>({
      query: (body) => ({
        url: '/orders/create',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Order']
    }),
    createDuplicateOrder: builder.mutation<number, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}/duplicate`,
        method: 'POST'
      }),
      invalidatesTags: ['Order']
    }),
    updateOrder: builder.mutation<void, OrderUpdateRequest>({
      query: (body) => ({
        url: `/orders/${body.orderId}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Order']
    }),
    deleteOrder: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Order']
    }),
    getOrderItems: builder.query<ExpandedOrderItem[], string | undefined>({
      query: (orderId) => ({
        url: `/orders/${orderId}/items`
      }),
      providesTags: ['OrderItem']
    }),
    getSalesOrderItems: builder.query<Page<SalesOrderItem>, SalesOrderItemState>({
      query: (params) => ({
        url: '/orders/items/search',
        params: {
          page: params.page,
          size: params.pageSize,
          sort: params?.sort?.[0] ? `${params.sort[0].field},${params.sort[0].sort}` : undefined
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['SalesOrderItem']
    }),
    getSalesOrderItemsAutocomplete: builder.query<string[], string>({
      query: (fieldName) => ({
        url: `/orders/items/autocomplete/${fieldName}`
      })
    }),
    recalculateSalesOrderItem: builder.mutation<
      SalesOrderItem,
      { itemId: number; unit: UnitType; filter: SalesOrderItemState }
    >({
      query: (params) => ({
        url: `/orders/items/${params.itemId}/${params.unit}`,
        method: 'POST'
      }),
      async onQueryStarted({ itemId, unit, filter }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSalesOrderItem } = await queryFulfilled;
          dispatch(
            orderApi.util.updateQueryData('getSalesOrderItems', filter, (previousValue) => {
              Object.assign(
                previousValue.content.find(
                  (x) => x.id === updatedSalesOrderItem.id
                ) as SalesOrderItem,
                updatedSalesOrderItem
              );
            })
          );
        } catch {}
      }
    }),
    getOrderItemsAmountTotal: builder.query<number, OrderTotalAmountRequest>({
      query: (params) => ({
        url: `/orders/${params.orderId}/totals/${params.unit}`
      }),
      providesTags: ['OrderItemsTotal']
    }),
    createOrderItem: builder.mutation<void, { orderId?: string; request: OrderItemCreateRequest }>({
      query: ({ orderId, request }) => ({
        url: `/orders/${orderId}/items`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['OrderItem', 'OrderItemsTotal']
    }),
    deleteOrderItem: builder.mutation<void, OrderItemDeleteRequest>({
      query: (params) => ({
        url: `/orders/${params.orderId}/items/${params.orderItemId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OrderItem', 'OrderItemsTotal']
    }),
    getSalesOrderItem: builder.mutation<SalesOrderItem, OrderItemGetRequest>({
      query: (request) => ({
        url: `/orders/items/${request.customerItemId}/${request.unit}`,
        method: 'POST'
      })
    }),
    updateSalesOrderItem: builder.mutation<
      void,
      { orderId?: string; request: SalesOrderItemUpdateRequest }
    >({
      query: ({ orderId, request }) => ({
        url: `/orders/${orderId}/items`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['OrderItem', 'SalesOrderItem', 'Order', 'OrderItemsTotal']
    }),
    submitOrder: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}/submit`,
        method: 'POST'
      }),
      invalidatesTags: ['Order', 'OrderItem']
    }),
    resubmitOrder: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}/resubmit`,
        method: 'POST'
      }),
      invalidatesTags: ['Order', 'OrderItem']
    }),
    discardOrder: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}/discard`,
        method: 'POST'
      }),
      invalidatesTags: ['Order', 'OrderItem']
    })
  })
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useDeleteOrderMutation,
  useGetOrderItemsQuery,
  useCreateOrderMutation,
  useGetOrderAutocompleteQuery,
  useCreateDuplicateOrderMutation,
  useUpdateOrderMutation,
  useGetSalesOrderItemsQuery,
  useGetSalesOrderItemsAutocompleteQuery,
  useRecalculateSalesOrderItemMutation,
  useGetOrderItemsAmountTotalQuery,
  useCreateOrderItemMutation,
  useDeleteOrderItemMutation,
  useGetSalesOrderItemMutation,
  useUpdateSalesOrderItemMutation,
  useSubmitOrderMutation,
  useResubmitOrderMutation,
  useDiscardOrderMutation
} = orderApi;
