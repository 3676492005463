import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton
} from '@mui/material';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { OrderItemCreateRequest } from '../order/orderItemSlice';
import { formatDateString } from '../../utils/dateUtils';
import { useCreateOrderItemMutation, useGetOrdersQuery } from '../api/orderApi';
import { Loader } from '../../components/Loader';
import { setPage, setPageSize, setSort } from './orderSelectionSlice';
import { useSnackbar } from 'notistack';
import { NewOrderModal } from './NewOrderModal';
import { useCallback, useState } from 'react';
import { OrderItemsTable } from '../order/OrderItemsTable';

export interface OrderSelectionProps {
  isOpen: boolean;
  handleClose: () => void;
  itemRequest?: OrderItemCreateRequest;
}

export const OrderSelection = ({
  isOpen,
  handleClose,
  itemRequest
}: OrderSelectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.orderSelection);
  const {
    data: orders,
    isUninitialized,
    isLoading,
    isFetching
  } = useGetOrdersQuery({
    page,
    pageSize,
    sort,
    filter
  });
  const [createOrderItem, { isLoading: isCreateOrderItemLoading }] = useCreateOrderItemMutation();
  const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);

  const addToOrder = (params: GridRenderCellParams<any, void>) => {
    createOrderItem({
      orderId: params.row.id,
      request: itemRequest!
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('stock.orderSelection.itemAdded'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 60,
      renderCell: (params) => (
        <IconButton>
          <Icon>{`expand_${params.value ? 'less' : 'more'}`}</Icon>
        </IconButton>
      )
    },
    { field: 'internalNumber', headerName: t('stock.orderSelection.internalNumber'), flex: 1 },
    { field: 'address', headerName: t('stock.orderSelection.address'), flex: 2 },
    {
      field: 'createdAt',
      headerName: t('stock.orderSelection.createdAt'),
      valueGetter: (value) => formatDateString(value),
      flex: 1
    },
    { field: 'createdBy', headerName: t('stock.orderSelection.createdBy'), flex: 1 },
    {
      field: 'add',
      width: 50,
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params) => (
        <IconButton onClick={() => addToOrder(params)}>
          <Icon>add</Icon>
        </IconButton>
      )
    }
  ];

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams) => (
      <div className="order-selection-details-wrapper">
        <OrderItemsTable lazy order={row} />
      </div>
    ),
    []
  );

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t('stock.orderSelection.title')}</Grid>
            <Grid item>
              <IconButton onClick={handleClose} sx={{ marginTop: '-20px', marginRight: '-24px' }}>
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {(isUninitialized || isCreateOrderItemLoading) && <Loader />}

          <Grid container sx={{ width: '85vw' }}>
            <Grid item xs sx={{ height: '60vh' }}>
              <DataGridPro
                columns={columns}
                disableColumnMenu
                disableRowSelectionOnClick
                disableVirtualization={process.env.NODE_ENV === 'test'}
                filterMode="server"
                columnHeaderHeight={46}
                loading={isLoading || isFetching}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={({ pageSize, page }) => {
                  dispatch(setPageSize(pageSize));
                  dispatch(setPage(page));
                }}
                onSortModelChange={(sort) => dispatch(setSort(sort))}
                pagination
                paginationMode="server"
                rowHeight={40}
                rowCount={orders?.totalElements || 0}
                rows={(orders?.content || []) as GridRowsProp}
                pageSizeOptions={[10, 25, 50, 100]}
                sortingMode="server"
                sortModel={sort}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => 'auto'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('button.close')}
          </Button>
          <Button autoFocus onClick={() => setNewOrderModalOpen(true)}>
            {t('stock.orderSelection.createNew')}
          </Button>
        </DialogActions>
      </Dialog>

      <NewOrderModal isOpen={newOrderModalOpen} handleClose={() => setNewOrderModalOpen(false)} />
    </>
  );
};
