import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductionAnalyticsFilter {
  workStation: string[];
  species: string[];
  categoryName: string[];
  profileType: string[];
  profileCode: string[];
  quality: string[];
  length: number[];
  width: number[];
  thickness: number[];
  startDate?: string;
  endDate?: string;
  productionOrderNumber?: string;
}

export interface ProductionAnalyticsSearchParams {
  filter: ProductionAnalyticsFilter;
  page: number;
  pageSize: number;
}

export interface ProductionAnalyticsState {
  showMoistureColumns: boolean;
  showQualityColumns: boolean;
  searchParams: ProductionAnalyticsSearchParams;
}

export const ProductionAnalyticsInitialState: ProductionAnalyticsState = {
  searchParams: {
    filter: {
      workStation: [],
      species: [],
      categoryName: [],
      profileType: [],
      profileCode: [],
      quality: [],
      length: [],
      width: [],
      thickness: [],
      startDate: '',
      endDate: '',
      productionOrderNumber: ''
    },
    page: 0,
    pageSize: 20
  },
  showMoistureColumns: true,
  showQualityColumns: false
};

const slice = createSlice({
  name: 'productionAnalytics',
  initialState: ProductionAnalyticsInitialState,
  reducers: {
    clearFilter: (state) => {
      state.searchParams = ProductionAnalyticsInitialState.searchParams;
    },
    setFilter: (state, { payload }: PayloadAction<ProductionAnalyticsFilter>) => {
      state.searchParams.filter = payload;
    },
    setFilterValue: (
      state,
      {
        payload: { field, value }
      }: PayloadAction<{ field: keyof ProductionAnalyticsFilter; value: any }>
    ) => {
      state.searchParams.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.pageSize = payload;
    },
    setShowMoistureColumns: (state, { payload }: PayloadAction<boolean>) => {
      state.showMoistureColumns = payload;
    },
    setShowQualityColumns: (state, { payload }: PayloadAction<boolean>) => {
      state.showQualityColumns = payload;
    }
  }
});

export default slice.reducer;
export const {
  clearFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setShowMoistureColumns,
  setShowQualityColumns,
  setFilter
} = slice.actions;
