import { MoistureAnalysisGroupedRow, MoistureAnalyticsResult } from '../index';
import round from 'lodash/round';
import {
  averageFromFrequencyMap,
  combineFrequencyMaps,
  standardDeviationFromFrequencyMap,
  sum
} from '../analyticsHelper';

export const mapMoistureAnalyticsChartData = (
  data?: MoistureAnalyticsResult[]
): [string[], number[]] => {
  const labels: any[] = [];
  const distribution: number[] = [];
  const moistureMap = new Map<number, number>();

  data
    ?.flatMap((row) => Object.entries(row.moistureMap))
    .forEach(([k, v]) => {
      const key = Number(k);
      const count = moistureMap.get(key) || 0;
      moistureMap.set(key, count + v);
    });

  const keys = Array.from(moistureMap.keys()).filter((k) => k > 0);
  const min = keys.sort((a, b) => a - b)?.[0] || 0;
  const max = keys.sort((a, b) => b - a)?.[0] || 0;
  const totalPcs =
    keys.map((k) => moistureMap.get(k)).reduce((a, b) => (a || 0) + (b || 0), 0) || 0;

  for (let idx = min; idx <= max; idx += 2) {
    labels.push(`${idx} - ${idx + 1} %`);
    distribution.push(
      round((((moistureMap.get(idx) || 0) + (moistureMap.get(idx + 1) || 0)) / totalPcs) * 100, 1)
    );
  }

  return [labels, distribution];
};

export const mapMoistureAnalyticsTableData = (
  data?: MoistureAnalyticsResult[]
): MoistureAnalysisGroupedRow[] => {
  const rows = new Map<string, MoistureAnalysisGroupedRow>();

  data?.forEach((row) => {
    const base: Partial<MoistureAnalysisGroupedRow> = {
      moistureRejectPercentage:
        row.totalPcs > 0 ? (row.moistureRejectPieces * 100) / row.totalPcs : 0,
      moistureStandardDeviation: standardDeviationFromFrequencyMap(row.moistureMap)
    };

    const newRow = {
      ...row,
      ...base,
      type: 'vendor',
      children: []
    } as MoistureAnalysisGroupedRow;

    if (row.vendorGroup) {
      const parent =
        rows.get(row.vendorGroup) ||
        ({
          ...row,
          ...base,
          type: 'vendor_group',
          children: []
        } as MoistureAnalysisGroupedRow);
      parent.children.push(newRow);
      rows.set(row.vendorGroup, parent);
    } else {
      rows.set(row.vendor || '-', newRow);
    }
  });

  return Array.from(rows.values()).map((row) => {
    if (row.children?.length > 1) {
      const moistureFrequencyMap = combineFrequencyMaps(row.children.map((c) => c.moistureMap));
      const moistureRejectPcs = row.children.map((c) => c.moistureRejectPieces).reduce(sum, 0);
      const totalPcs = row.children.map((c) => c.totalPcs).reduce(sum, 0);

      return {
        ...row,
        totalPcs: totalPcs,
        avgMoisture: averageFromFrequencyMap(moistureFrequencyMap),
        maxMoisture:
          row.children
            .map((ch) => ch.maxMoisture)
            .sort()
            .reverse()?.[0] || 0,
        moistureRejectPieces: moistureRejectPcs,
        moistureRejectPercentage: totalPcs > 0 ? (moistureRejectPcs * 100) / totalPcs : 0,
        moistureStandardDeviation: standardDeviationFromFrequencyMap(moistureFrequencyMap)
      } as MoistureAnalysisGroupedRow;
    }

    return row;
  });
};
