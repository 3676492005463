import React from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLazyGetAttributeValuesQuery } from '../features/api/apiSlice';
import { Filter } from './Filter';
import { AttributeType } from '../domain/AttributeType';
import { CustomerItemFilter } from '../features/customerItem/customerItemSlice';
import { SalesOrderItemFilter } from '../features/order/orderItemSlice';

export interface ItemFilterProps {
  filter: CustomerItemFilter | SalesOrderItemFilter;
  disabled: boolean;
  extra?: boolean;
  handleSelectChange: (
    field: keyof (CustomerItemFilter | SalesOrderItemFilter),
    event: any,
    value?: any
  ) => void;
}

export const ItemAttributesFilter = (props: ItemFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const { filter, disabled, handleSelectChange } = props;

  const [getActualThicknesses, actualThicknesses] = useLazyGetAttributeValuesQuery();
  const [getActualWidths, actualWidths] = useLazyGetAttributeValuesQuery();
  const [getTreatments, treatments] = useLazyGetAttributeValuesQuery();
  const [getLengths, lengths] = useLazyGetAttributeValuesQuery();
  const [getMoistures, moistures] = useLazyGetAttributeValuesQuery();
  const [getNominalThicknesses, nominalThicknesses] = useLazyGetAttributeValuesQuery();
  const [getNominalWidths, nominalWidths] = useLazyGetAttributeValuesQuery();
  const [getPaintingProcesses, paintingProcesses] = useLazyGetAttributeValuesQuery();
  const [getProfileNumbers, profileNumbers] = useLazyGetAttributeValuesQuery();
  const [getProfileTypes, profileTypes] = useLazyGetAttributeValuesQuery();
  const [getQualities, qualities] = useLazyGetAttributeValuesQuery();
  const [getSpecies, species] = useLazyGetAttributeValuesQuery();

  const handleSelectFocus = (trigger: any, type: AttributeType) => trigger(type, true);
  const numericSort = (options?: string[]) =>
    sortBy(options || [], (option) => +option.replace(',', '.'));

  return (
    <>
      <Filter
        id="actualThickness"
        disabled={disabled}
        label={t('items.table.actualThickness')}
        minWidth="210px"
        multiple
        loading={actualThicknesses.isLoading}
        options={numericSort(actualThicknesses.data)}
        value={filter.actualThickness || []}
        onFocus={handleSelectFocus.bind(this, getActualThicknesses, AttributeType.ACTUAL_THICKNESS)}
        onChange={handleSelectChange.bind(this, 'actualThickness')}
        extra
      />
      <Filter
        id="actualWidth"
        disabled={disabled}
        label={t('items.table.actualWidth')}
        minWidth="190px"
        multiple
        loading={actualWidths.isLoading}
        options={numericSort(actualWidths.data)}
        value={filter.actualWidth || []}
        onFocus={handleSelectFocus.bind(this, getActualWidths, AttributeType.ACTUAL_WIDTH)}
        onChange={handleSelectChange.bind(this, 'actualWidth')}
        extra
      />
      <Filter
        id="treatment"
        disabled={disabled}
        label={t('items.table.treatment')}
        minWidth="140px"
        multiple
        loading={treatments.isLoading}
        options={treatments.data || []}
        value={filter.treatment || []}
        onFocus={handleSelectFocus.bind(this, getTreatments, AttributeType.TREATMENT)}
        onChange={handleSelectChange.bind(this, 'treatment')}
        extra
        emptyOption
      />
      <Filter
        id="length"
        disabled={disabled}
        label={t('items.table.length')}
        minWidth="160px"
        multiple
        loading={lengths.isLoading}
        options={numericSort(lengths.data)}
        value={filter.length || []}
        onFocus={handleSelectFocus.bind(this, getLengths, AttributeType.LENGTH)}
        onChange={handleSelectChange.bind(this, 'length')}
        extra
      />
      <Filter
        id="moisture"
        disabled={disabled}
        label={t('items.table.moisture')}
        minWidth="140px"
        multiple
        loading={moistures.isLoading}
        options={numericSort(moistures.data)}
        value={filter.moisture || []}
        onFocus={handleSelectFocus.bind(this, getMoistures, AttributeType.MOISTURE_CONTENT)}
        onChange={handleSelectChange.bind(this, 'moisture')}
        extra
      />
      <Filter
        id="nominalThickness"
        disabled={disabled}
        label={t('items.table.nominalThickness')}
        minWidth="220px"
        multiple
        loading={nominalThicknesses.isLoading}
        options={numericSort(nominalThicknesses.data)}
        value={filter.nominalThickness || []}
        onFocus={handleSelectFocus.bind(
          this,
          getNominalThicknesses,
          AttributeType.NOMINAL_THICKNESS
        )}
        onChange={handleSelectChange.bind(this, 'nominalThickness')}
        extra
      />
      <Filter
        id="nominalWidth"
        disabled={disabled}
        label={t('items.table.nominalWidth')}
        minWidth="210px"
        multiple
        loading={nominalWidths.isLoading}
        options={numericSort(nominalWidths.data)}
        value={filter.nominalWidth || []}
        onFocus={handleSelectFocus.bind(this, getNominalWidths, AttributeType.NOMINAL_WIDTH)}
        onChange={handleSelectChange.bind(this, 'nominalWidth')}
        extra
      />
      <Filter
        id="paintingProcess"
        disabled={disabled}
        label={t('items.table.paintingProcess')}
        minWidth="190px"
        multiple
        loading={paintingProcesses.isLoading}
        options={paintingProcesses.data || []}
        value={filter.paintingProcess || []}
        onFocus={handleSelectFocus.bind(this, getPaintingProcesses, AttributeType.PAINTING_PROCESS)}
        onChange={handleSelectChange.bind(this, 'paintingProcess')}
        extra
        emptyOption
      />
      <Filter
        id="profileNumber"
        disabled={disabled}
        label={t('items.table.profileNumber')}
        minWidth="140px"
        multiple
        loading={profileNumbers.isLoading}
        options={profileNumbers.data || []}
        value={filter.profileNumber || []}
        onFocus={handleSelectFocus.bind(this, getProfileNumbers, AttributeType.PROFILE_NO)}
        onChange={handleSelectChange.bind(this, 'profileNumber')}
        extra
      />
      <Filter
        id="profileType"
        disabled={disabled}
        label={t('items.table.profileType')}
        minWidth="150px"
        multiple
        loading={profileTypes.isLoading}
        options={profileTypes.data || []}
        value={filter.profileType || []}
        onFocus={handleSelectFocus.bind(this, getProfileTypes, AttributeType.PROFILE_TYPE)}
        onChange={handleSelectChange.bind(this, 'profileType')}
        extra
      />
      <Filter
        id="quality"
        disabled={disabled}
        label={t('items.table.quality')}
        minWidth="120px"
        multiple
        loading={qualities.isLoading}
        options={qualities.data || []}
        value={filter.quality || []}
        onFocus={handleSelectFocus.bind(this, getQualities, AttributeType.QUALITY)}
        onChange={handleSelectChange.bind(this, 'quality')}
        extra
      />
      <Filter
        id="species"
        disabled={disabled}
        label={t('items.table.species')}
        minWidth="120px"
        multiple
        loading={species.isLoading}
        options={species.data || []}
        value={filter.species || []}
        onFocus={handleSelectFocus.bind(this, getSpecies, AttributeType.SPECIES)}
        onChange={handleSelectChange.bind(this, 'species')}
        extra
      />
    </>
  );
};
