import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { apiSlice } from './features/api/apiSlice';
import authReducer, { removeAuthentication } from './features/auth/authSlice';
import employeeReducer from './features/employee/employeeSlice';
import languageReducer from './features/language/languageSlice';
import userReducer from './features/user/userSlice';
import moistureAnalyticsReducer from './features/analytics/moisture/moistureAnalyticsSlice';
import productionAnalyticsReducer from './features/analytics/production/productionAnalyticsSlice';
import rawMaterialAnalyticsReducer from './features/analytics/rawMaterial/rawMaterialAnalyticsSlice';
import productionOrderDetailsReducer from './features/analytics/production/details/productionOrderDetailsSlice';
import customerReducer from './features/customer/customerSlice';
import customerItemReducer from './features/customerItem/customerItemSlice';
import orderReducer from './features/order/orderSlice';
import orderItemReducer from './features/order/orderItemSlice';
import orderSelectionReducer from './features/stock/orderSelectionSlice';
import stockReducer from './features/stock/stockSlice';
import snackbarUtils from './utils/snackbarUtils';

export const commonApiErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    if (action.meta.arg.endpointName !== 'login') {
      snackbarUtils.error('unauthorized.sessionExpired');
      api.dispatch(removeAuthentication());
    } else {
      snackbarUtils.error('apiErrors.login.failedLogin');
    }
  } else if (isRejectedWithValue(action)) {
    snackbarUtils.error(action.payload.data?.errorMessage || 'apiErrors.general', {
      errorParams: action.payload.data?.errorParams
    });
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    customers: customerReducer,
    customerItems: customerItemReducer,
    employees: employeeReducer,
    language: languageReducer,
    orders: orderReducer,
    orderItems: orderItemReducer,
    orderSelection: orderSelectionReducer,
    stock: stockReducer,
    users: userReducer,
    moistureAnalytics: moistureAnalyticsReducer,
    productionAnalytics: productionAnalyticsReducer,
    rawMaterialAnalytics: rawMaterialAnalyticsReducer,
    productionOrderDetails: productionOrderDetailsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(commonApiErrorHandler).concat(apiSlice.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
