import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { FilterArea } from '../../../components/FilterArea';
import { Filter } from '../../../components/Filter';
import { Button, Grid, Icon } from '@mui/material';
import {
  clearFilter,
  RawMaterialAnalyticsFilter,
  RawMaterialInitialState,
  setFilter
} from './rawMaterialAnalyticsSlice';
import {
  useGetFilterValuesForConsumedPackagesQuery,
  useGetFilterValuesForProducedPackagesQuery,
  useLazyDownloadRawMaterialAnalyticsQuery
} from '../../api/analyticsApi';
import { DateRangeFilter } from '../../../components/DateRangeFilter';

export const RawMaterialAnalyticsFilters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.rawMaterialAnalytics);
  const [filterState, setFilterState] = useState(state.filter);
  const { data: consumedFilterValues } = useGetFilterValuesForConsumedPackagesQuery();
  const { data: producedFilterValues } = useGetFilterValuesForProducedPackagesQuery();
  const [download, { isLoading: isDownloading }] = useLazyDownloadRawMaterialAnalyticsQuery();

  const onSearch = () => dispatch(setFilter(filterState));

  const handleFilterChange = (
    field: keyof RawMaterialAnalyticsFilter,
    event: any,
    value: string | number
  ) => setFilterState({ ...filterState, [field]: value });

  const clear = () => {
    dispatch(clearFilter());
    setFilterState(RawMaterialInitialState.filter);
  };

  return (
    <FilterArea onClear={clear}>
      <DateRangeFilter
        id="date"
        width="150px"
        label="analytics.datepicker"
        valueFrom={filterState.startDate ? new Date(filterState.startDate) : null}
        valueTo={filterState.endDate ? new Date(filterState.endDate) : null}
        onChange={([startDate, endDate]) =>
          setFilterState({
            ...filterState,
            startDate: startDate?.toJSON(),
            endDate: endDate?.toJSON()
          })
        }
      />
      <Filter
        multiple
        id="species"
        limitTags={2}
        minWidth="200px"
        value={filterState.species}
        label={t('analytics.species')}
        options={producedFilterValues?.species || []}
        onChange={handleFilterChange.bind(this, 'species')}
      />
      <Filter
        multiple
        limitTags={1}
        minWidth="220px"
        id="consumedDimensions"
        label={t('analytics.inputCrossSection')}
        value={filterState.consumedCrossSections}
        options={consumedFilterValues?.nominalDimensions || []}
        onChange={handleFilterChange.bind(this, 'consumedCrossSections')}
      />
      <Filter
        multiple
        id="group"
        limitTags={1}
        minWidth="200px"
        label={t('analytics.category')}
        value={filterState.group}
        options={producedFilterValues?.groupNames || []}
        onChange={handleFilterChange.bind(this, 'group')}
      />
      <Filter
        multiple
        limitTags={1}
        minWidth="220px"
        id="nominalDimensions"
        label={t('analytics.nominalDimensions')}
        value={filterState.nominalDimensions}
        options={producedFilterValues?.nominalDimensions || []}
        onChange={handleFilterChange.bind(this, 'nominalDimensions')}
      />
      <Filter
        multiple
        limitTags={1}
        minWidth="220px"
        id="actualDimensions"
        label={t('analytics.actualDimensions')}
        value={filterState.actualDimensions}
        options={producedFilterValues?.actualDimensions || []}
        onChange={handleFilterChange.bind(this, 'actualDimensions')}
      />
      <Filter
        multiple
        limitTags={1}
        minWidth="220px"
        id="profileType"
        label={t('analytics.profileType')}
        value={filterState.profileType}
        options={producedFilterValues?.profileTypes || []}
        onChange={handleFilterChange.bind(this, 'profileType')}
      />
      <Filter
        multiple
        limitTags={1}
        minWidth="200px"
        id="profileCode"
        label={t('analytics.profileCode')}
        value={filterState.profileCode}
        options={producedFilterValues?.profileCodes || []}
        onChange={handleFilterChange.bind(this, 'profileCode')}
      />
      <Filter
        multiple
        limitTags={3}
        id="moisture"
        minWidth="150px"
        label={t('analytics.moisture')}
        value={filterState.moisture}
        options={producedFilterValues?.moistures || []}
        onChange={handleFilterChange.bind(this, 'moisture')}
      />
      <Grid item>
        <Button onClick={onSearch}>{t('button.search')}</Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          disabled={isDownloading}
          onClick={() => download(state)}
          startIcon={<Icon className="margin-top-4">download</Icon>}
        >
          XLSX
        </Button>
      </Grid>
    </FilterArea>
  );
};
