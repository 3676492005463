import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLanguage } from '../../utils/langUtils';

const slice = createSlice({
  name: 'auth',
  initialState: { language: getLanguage() },
  reducers: {
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload;
      localStorage.setItem('language', payload);
    }
  }
});

export default slice.reducer;
export const { setLanguage } = slice.actions;
