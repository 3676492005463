export const truncateLongText = (text: string | undefined, maxLength: number): string => {
  return (text && (text?.length > maxLength ? `${text.slice(0, maxLength)}\u2026` : text)) || '';
};

export const formatNumber = (value?: number): string => {
  return value?.toLocaleString('en').replace(/,/g, ' ') || '';
};

export const formatNumberScale = (value: number | null | undefined, scale: number = 0): string => {
  return value?.toLocaleString('en', { maximumFractionDigits: scale }).replace(/,/g, ' ') || '';
};
