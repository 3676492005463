import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { ProductionOrderAnalyticsRow, ProductionOrderAnalyticsRowType } from '../index';
import { formatNumberScale } from '../../../utils/textFormatUtils';
import { setPage, setPageSize } from './productionAnalyticsSlice';
import { Page } from '../../../domain/Page';
import { getExpandedRowModel } from '../reactMaterialTableHack';
import { uniqueFilter } from '../../../utils/mathUtils';
import { formatDateTimeString } from '../../../utils/dateUtils';
import { PaginationState } from '@tanstack/table-core';
import { listFilter, materialReactTableBaseProps } from '../analyticsHelper';
import { TreeFilter } from '../rawMaterial/TreeFilter';
import { extractProductionVendors } from './ProductionAnalyticsTableHelper';

const historicalValuesRowTypes = new Set([
  ProductionOrderAnalyticsRowType.PRODUCTION_ORDER,
  ProductionOrderAnalyticsRowType.VENDOR_GROUP,
  ProductionOrderAnalyticsRowType.VENDOR
]);

export const ProductionAnalyticsTable = ({
  page,
  isLoading
}: {
  page?: Page<ProductionOrderAnalyticsRow>;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchParams, showQualityColumns, showMoistureColumns } = useSelector(
    (state: RootState) => state.productionAnalytics
  );
  const rows = useMemo(() => page?.content || [], [page?.content]);
  const groupedVendorValues = useMemo(() => extractProductionVendors(rows), [rows]);

  const columns = useMemo(
    () =>
      [
        {
          id: 'orderName',
          enableColumnFilter: true,
          header: t('analytics.production.order'),
          filterFn: listFilter('orderName'),
          filterSelectOptions:
            rows.map((r) => `${r.productionOrderNumber}/${r.product}`).filter(uniqueFilter) || [],
          accessorFn: ({ type, orderName }) =>
            type === ProductionOrderAnalyticsRowType.PRODUCTION_ORDER ? orderName : ''
        },
        {
          id: 'vendor',
          enableColumnFilter: true,
          header: t('analytics.vendor'),
          filterFn: listFilter('vendor'),
          Filter: (filterProps) => <TreeFilter {...filterProps} values={groupedVendorValues} />,
          Cell: ({ row: { original, depth } }) => {
            if (original.type === ProductionOrderAnalyticsRowType.VENDOR_GROUP) {
              return <span>{original.vendorGroup}</span>;
            } else if (original.type === ProductionOrderAnalyticsRowType.VENDOR) {
              const marginLeft = depth === 2 ? '20px' : '0';
              return <span style={{ marginLeft }}>{original.vendor || '-'}</span>;
            }
          }
        },
        {
          id: 'rawMaterialPackage',
          header: t('analytics.production.rawMaterialPackage'),
          Cell: ({ row: { original } }) => {
            if (original.type === ProductionOrderAnalyticsRowType.MATERIAL) {
              return <span>{original.name}</span>;
            } else if (original.type === ProductionOrderAnalyticsRowType.PACKAGE) {
              return <span style={{ marginLeft: `20px` }}>{original.name}</span>;
            }
          }
        },
        {
          id: 'totalM3',
          header: `${t('analytics.production.totalVolume')} (m3)`,
          accessorFn: ({ totalM3 }) => `${formatNumberScale(totalM3) || 0} m3`
        },
        {
          id: 'primaryPercentage',
          size: 80,
          header: `${t('analytics.production.primary')} (%)`,
          accessorFn: ({ primaryPercentage, totalM3, primaryM3 }) =>
            `${formatNumberScale(primaryPercentage) || 0} %`
        },
        {
          id: 'historicalTotalM3',
          header: `${t('analytics.production.historicalTotalM3')} (m3)`,
          accessorFn: ({ historicalTotalM3, type }) =>
            historicalValuesRowTypes.has(type)
              ? `${formatNumberScale(historicalTotalM3, 0) || 0} m3`
              : ''
        },
        {
          id: 'historicalPrimaryPct',
          size: 130,
          header: `${t('analytics.production.historicalPrimary')} (%)`,
          accessorFn: ({ historicalPrimaryPct, type }) =>
            historicalValuesRowTypes.has(type)
              ? `${formatNumberScale(historicalPrimaryPct) || 0} %`
              : ''
        },
        {
          id: 'primaryPercentageDeviation',
          size: 120,
          accessorKey: 'primaryPercentageDeviation',
          header: t('analytics.production.primaryDeviation'),
          Cell: ({ row: { original } }) => {
            if (historicalValuesRowTypes.has(original.type)) {
              const deviation = original.primaryPercentage - original.historicalPrimaryPct || 0;
              const color = deviation < 0 ? 'red' : 'black';
              return <span style={{ color }}>{deviation} %</span>;
            }
          }
        },
        {
          id: 'avgPrimaryMoisture',
          size: 100,
          header: `${t('analytics.moisture')} (%)`,
          accessorFn: ({ avgPrimaryMoisture }) => `${formatNumberScale(avgPrimaryMoisture) || 0} %`
        },
        {
          id: 'historicalPrimaryMoisture',
          size: 185,
          header: `${t('analytics.production.historicalPrimaryMoisture')} (%)`,
          accessorFn: ({ historicalPrimaryMoisture, type }) =>
            historicalValuesRowTypes.has(type)
              ? `${formatNumberScale(historicalPrimaryMoisture) || 0} %`
              : ''
        },
        {
          size: 130,
          id: 'moistureDeviation',
          header: t('analytics.production.moistureDeviation'),
          Cell: ({
            row: {
              original: { historicalPrimaryMoisture, avgPrimaryMoisture, type }
            }
          }) => {
            if (historicalValuesRowTypes.has(type)) {
              const deviation = avgPrimaryMoisture - historicalPrimaryMoisture || 0;
              const color = deviation <= -1 ? 'red' : 'black';
              return <span style={{ color }}>{formatNumberScale(deviation)} %</span>;
            }
          }
        },
        {
          id: 'startAt',
          size: 140,
          accessorFn: ({ endAt }) => (endAt ? formatDateTimeString(endAt) : ''),
          header: t('analytics.production.productionDate')
        }
      ] as MRT_ColumnDef<ProductionOrderAnalyticsRow>[],
    [t, rows, groupedVendorValues]
  );

  return (
    <Grid container>
      <Grid item xs sx={{ height: '40vh' }}>
        <MaterialReactTable<ProductionOrderAnalyticsRow>
          {...materialReactTableBaseProps}
          columns={columns}
          getSubRows={(row) => row.children}
          getExpandedRowModel={getExpandedRowModel}
          rowCount={page?.totalElements}
          enableRowVirtualization
          muiTableContainerProps={{ sx: { maxHeight: '70vh' } }}
          onPaginationChange={(updater: any) => {
            const newValue: PaginationState = updater({
              pageIndex: searchParams.page,
              pageSize: searchParams.pageSize
            } as PaginationState);
            dispatch(setPage(newValue.pageIndex));
            dispatch(setPageSize(newValue.pageSize));
          }}
          displayColumnDefOptions={{
            'mrt-row-expand': { size: 65, grow: false, enableResizing: false }
          }}
          state={{
            columnVisibility: {
              primaryPercentage: showQualityColumns,
              historicalTotalM3: showQualityColumns,
              historicalPrimaryPct: showQualityColumns,
              primaryPercentageDeviation: showQualityColumns,
              avgPrimaryMoisture: showMoistureColumns,
              historicalPrimaryMoisture: showMoistureColumns,
              moistureDeviation: showMoistureColumns
            },
            pagination: { pageIndex: searchParams.page, pageSize: searchParams.pageSize },
            isLoading: isLoading
          }}
          data={rows || []}
        />
      </Grid>
    </Grid>
  );
};
