import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './theme/theme.scss';
import './i18n';
import { LicenseInfo } from '@mui/x-license';
import { createRoot } from 'react-dom/client';

LicenseInfo.setLicenseKey(
  '0fc40e983190686cb127a7e6212bff8dTz05NzY0NCxFPTE3NTcyNDg2NDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}
