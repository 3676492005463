import React, { useEffect, useState } from 'react';
import {
  CSSObject,
  Divider,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar
} from '@mui/material';
import { ROUTES } from '../router/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { hasAnyPermission } from '../utils/authUtils';
import { Permission } from '../domain/Permission';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  width: '256px'
});

const closedMixin = (theme: Theme): CSSObject => ({
  ...openedMixin(theme),
  width: `calc(${theme.spacing(8)} + 1px)`
});

export const Sidebar = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);

  const drawerOpenKey = 'drawerOpen';
  const defaultOpen =
    !localStorage.getItem(drawerOpenKey) &&
    hasAnyPermission([Permission.ORDER_VIEW], user?.permissions)
      ? false
      : localStorage.getItem(drawerOpenKey) !== 'false';
  const [drawerOpen, setDrawerOpen] = useState(defaultOpen);

  useEffect(() => {
    localStorage.setItem(drawerOpenKey, String(drawerOpen));
  }, [drawerOpen]);

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      data-testid={drawerOpen ? 'sidebarOpen' : 'sidebarClosed'}
      sx={(theme: Theme) => ({
        whiteSpace: 'nowrap',
        ...(drawerOpen && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!drawerOpen && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme)
        })
      })}
    >
      <Toolbar />
      <List>
        <ListItemButton onClick={() => setDrawerOpen(!drawerOpen)}>
          <ListItemIcon>
            <Icon>
              <i className={drawerOpen ? 'ri-menu-fold-line' : 'ri-menu-unfold-line'} />
            </Icon>
          </ListItemIcon>
          <ListItemText primary={t(`sidebar.HIDE_MENU`)} />
        </ListItemButton>
        <Divider />

        {ROUTES.map(
          (route) =>
            hasAnyPermission(route.permissions, user?.permissions) && (
              <React.Fragment key={route.key}>
                <ListItemButton
                  onClick={() => navigate(route.path)}
                  selected={pathname.includes(route.path)}
                >
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={t(`sidebar.${route.key}`)} />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            )
        )}
      </List>
    </Drawer>
  );
};
