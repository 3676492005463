import React from 'react';
import enUSLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Language } from '../utils/langUtils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../utils/dateUtils';

export interface DateFilterProps {
  id: string;
  label: string;
  onChange: (date: DateRange<Date>) => void;
  valueFrom: Date | null;
  valueTo: Date | null;
  width: string;
}

export const DateRangeFilter = (props: DateFilterProps): JSX.Element => {
  const { label, onChange, valueFrom, valueTo, width } = props;
  const {
    i18n: { language },
    t
  } = useTranslation();

  return (
    <Grid item>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={language === Language.ET ? etLocale : enUSLocale}
      >
        <MobileDateRangePicker
          format={DATE_FORMAT}
          value={[valueFrom, valueTo]}
          onChange={(value) => onChange(value)}
          slotProps={{
            textField: {
              sx: { width },
              size: 'small'
            },
            actionBar: {
              actions: ['clear', 'cancel']
            },
            toolbar: {
              hidden: true
            },
            fieldSeparator: {
              hidden: true
            }
          }}
          localeText={{
            start: t(`${label}.from`),
            end: t(`${label}.to`)
          }}
        />
      </LocalizationProvider>
    </Grid>
  );
};
