import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCustomerItemAutocompleteQuery } from '../api/apiSlice';
import { clearFilter, CustomerItemFilter, setFilterValue } from './customerItemSlice';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { Filter } from '../../components/Filter';
import { FilterArea } from '../../components/FilterArea';
import { useParams } from 'react-router-dom';
import { ItemAttributesFilter } from '../../components/ItemAttributesFilter';

export interface CustomerItemFilterProps {
  disabled: boolean;
}

export const CustomerItemsFilter = (props: CustomerItemFilterProps): JSX.Element => {
  let { customerId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filter } = useSelector((state: RootState) => state.customerItems);
  const { disabled } = props;

  const { data: numberOptions, isFetching: isNumberOptionsLoading } =
    useGetCustomerItemAutocompleteQuery({
      fieldName: 'number',
      customerId
    });
  const { data: descriptionOptions, isFetching: isDescriptionOptionsLoading } =
    useGetCustomerItemAutocompleteQuery({ fieldName: 'description', customerId });
  const { data: customerItemNumberOptions, isFetching: isCustomerItemNumberOptionsLoading } =
    useGetCustomerItemAutocompleteQuery({ fieldName: 'customerItemNumber', customerId });
  const { data: customerItemNameOptions, isFetching: isCustomerItemNameOptionsLoading } =
    useGetCustomerItemAutocompleteQuery({ fieldName: 'customerItemName', customerId });
  const { data: customerItemTypeOptions, isFetching: isCustomerItemTypeOptionsLoading } =
    useGetCustomerItemAutocompleteQuery({ fieldName: 'customerItemType', customerId });

  const handleSelectChange = (
    field: keyof CustomerItemFilter,
    event: any,
    value: string[] | boolean
  ) => dispatch(setFilterValue({ field, value }));

  return (
    <FilterArea onClear={() => dispatch(clearFilter())} disabled={disabled}>
      <Filter
        id="number"
        disabled={disabled}
        label={t('items.table.number')}
        loading={isNumberOptionsLoading}
        minWidth="180px"
        multiple
        options={numberOptions}
        value={filter.number || []}
        onChange={handleSelectChange.bind(this, 'number')}
      />
      <Filter
        id="description"
        disabled={disabled}
        label={t('items.table.description')}
        loading={isDescriptionOptionsLoading}
        minWidth="500px"
        multiple
        options={descriptionOptions}
        value={filter.description || []}
        onChange={handleSelectChange.bind(this, 'description')}
      />
      <Filter
        id="customerItemNumber"
        disabled={disabled}
        label={t('items.table.customerItemNumber')}
        loading={isCustomerItemNumberOptionsLoading}
        minWidth="220px"
        multiple
        options={customerItemNumberOptions}
        value={filter.customerItemNumber || []}
        onChange={handleSelectChange.bind(this, 'customerItemNumber')}
      />
      <Filter
        id="customerItemName"
        disabled={disabled}
        label={t('items.table.customerItemName')}
        loading={isCustomerItemNameOptionsLoading}
        minWidth="220px"
        multiple
        options={customerItemNameOptions}
        value={filter.customerItemName || []}
        onChange={handleSelectChange.bind(this, 'customerItemName')}
      />
      <Filter
        id="customerItemType"
        disabled={disabled}
        label={t('items.table.customerItemType')}
        loading={isCustomerItemTypeOptionsLoading}
        minWidth="220px"
        multiple
        options={customerItemTypeOptions}
        value={filter.customerItemType || []}
        onChange={handleSelectChange.bind(this, 'customerItemType')}
      />
      <Filter
        id="active"
        disabled={disabled}
        label={t('items.table.active')}
        minWidth="120px"
        optionLabelPrefix="boolean."
        options={[true, false]}
        value={filter.active !== undefined ? filter.active : null}
        onChange={handleSelectChange.bind(this, 'active')}
      />
      <ItemAttributesFilter
        disabled={disabled}
        filter={filter}
        extra
        handleSelectChange={handleSelectChange}
      />
    </FilterArea>
  );
};
