import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../store';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { FilterArea } from '../../../../components/FilterArea';
import { clearFilter } from '../../../user/userSlice';
import { Filter } from '../../../../components/Filter';
import { ProductionOrderDetailsFilter, setFilter } from './productionOrderDetailsSlice';
import { useWorkStations } from '../../hooks/useWorkStations';

export const ProductionOrderDetailsFilters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filter } = useSelector((state: RootState) => state.productionOrderDetails);
  const [filterState, setFilterState] = useState(filter);
  const { workStationIds, isLoading: isWorkStationsLoading } = useWorkStations();

  const searchHandler = () => {
    dispatch(setFilter(filterState));
  };

  const handleFilterChange = (field: keyof ProductionOrderDetailsFilter, event: any, value: any) =>
    setFilterState({ ...filterState, [field]: value });

  return (
    <FilterArea onClear={() => dispatch(clearFilter())}>
      <Filter
        multiple
        id="workStation"
        minWidth="260px"
        options={workStationIds}
        loading={isWorkStationsLoading}
        label={t('analytics.workStation')}
        value={filterState.workStations}
        onChange={handleFilterChange.bind(this, 'workStations')}
      />
      <Filter
        text
        width="260px"
        id="productionOrderNumber"
        label={t('analytics.productionOrderNumber')}
        value={filterState.productionOrderNumber}
        onChange={(event) => handleFilterChange('productionOrderNumber', event, event.target.value)}
      />
      <Grid item>
        <Button onClick={searchHandler}>{t('button.search')}</Button>
      </Grid>
    </FilterArea>
  );
};
