import { PickersLocaleText } from '@mui/x-date-pickers-pro';

// Custom locale for et-EE, created based on https://mui.com/material-ui/guides/localization

export const getPickersLocalization = (pickersTranslations: Partial<PickersLocaleText<any>>) => {
  return {
    components: {
      MuiLocalizationProvider: {
        defaultProps: {
          localeText: { ...pickersTranslations }
        }
      }
    }
  };
};

const etEEPickers: PickersLocaleText<any> = {
  // Calendar navigation
  previousMonth: 'Eelnev kuu',
  nextMonth: 'Järgnev kuu',

  // View navigation
  openPreviousView: 'ava eelnev vaade',
  openNextView: 'ava järgnev vaade',
  calendarViewSwitchingButtonAriaLabel: (view: 'year' | 'month' | 'day') =>
    view === 'year'
      ? 'aastate vaade on avatud, vaheta kalendrivaatele'
      : 'kalendrivaade on avatud, vaheta aastate vaatele',
  // inputModeToggleButtonAriaLabel: (isKeyboardInputOpen, viewType) =>
  //   isKeyboardInputOpen
  //     ? `tekstisisestuse vaade on avatud, mine ${viewType} vaatele`
  //     : `${viewType} vaade on avatud, mine tekstisisestuse vaatele`,

  // DateRange placeholders
  start: 'Algus',
  end: 'Lõpp',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  year: 'Y',
  month: 'M',
  day: 'D',
  weekDay: 'WD',
  hours: 'H',
  minutes: 'min',
  seconds: 'sec',
  empty: 'tyhi',

  // Action bar
  cancelButtonLabel: 'Sulge',
  clearButtonLabel: 'Tühjenda',
  fieldClearLabel: 'Tühjenda',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Täna',

  // Toolbar titles
  // datePickerDefaultToolbarTitle: 'Vali kuupäev',
  // dateTimePickerDefaultToolbarTitle: 'Vali kuupäev & kellaaeg',
  // timePickerDefaultToolbarTitle: 'Vali kellaaeg',
  // dateRangePickerDefaultToolbarTitle: 'Vali kuupäevade vahemik',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Vali ${view}. ${
      time === null ? 'Aeg on valimata' : `Valitud aeg ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} tundi`,
  minutesClockNumberText: (minutes) => `${minutes} minutit`,
  secondsClockNumberText: (seconds) => `${seconds} sekundit`,

  // Calendar labels
  calendarWeekNumberHeaderLabel: 'Nädala number',
  calendarWeekNumberHeaderText: '#',
  calendarWeekNumberAriaLabelText: (weekNumber: any) => `Nädal ${weekNumber}`,
  calendarWeekNumberText: (weekNumber: any) => `${weekNumber}`,

  // Open picker labels
  openDatePickerDialogue: (value, utils) =>
    !!value && utils.isValid(value)
      ? `Vali kuupäev, valitud kuupäev on ${utils.format(new Date(value), 'fullDate')}`
      : 'Vali kuupäev',
  openTimePickerDialogue: (value, utils) =>
    !!value && utils.isValid(value)
      ? `Vali kellaaeg, valitud kellaaeg on ${utils.format(new Date(value), 'fullTime')}`
      : 'Vali kellaaeg',

  // Table labels
  timeTableLabel: 'vali kellaaeg',
  dateTableLabel: 'vali kuupäev',

  selectViewText: () => 'select 111',

  // Field section placeholders
  fieldYearPlaceholder: (params: any) => 'Y'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params: any) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
  fieldDayPlaceholder: () => 'DD',
  fieldHoursPlaceholder: () => 'hh',
  fieldMinutesPlaceholder: () => 'mm',
  fieldSecondsPlaceholder: () => 'ss',
  fieldMeridiemPlaceholder: () => 'aa',
  fieldWeekDayPlaceholder: () => 'ndl',

  dateTimePickerToolbarTitle: 'asd',
  datePickerToolbarTitle: 'asd',
  timePickerToolbarTitle: 'asd',
  dateRangePickerToolbarTitle: 'asd',
  meridiem: 'meri'
};

export const datePickersEtEE = getPickersLocalization(etEEPickers);
