import { useSnackbar, VariantType } from 'notistack';
import { PropsWithChildren } from 'react';

let t: any;
let useSnackbarRef: any;
export const SnackbarUtilsConfigurator = (props: PropsWithChildren<any>) => {
  t = props.t;
  useSnackbarRef = useSnackbar();
  return null;
};

const snackbarUtils = {
  success(message: string) {
    this.toast(message, 'success');
  },
  warning(message: string) {
    this.toast(message, 'warning');
  },
  info(message: string) {
    this.toast(message, 'info');
  },
  error(message: string, options?: any) {
    this.toast(message, 'error', options);
  },
  toast(message: string, variant: VariantType = 'default', options?: any) {
    useSnackbarRef.enqueueSnackbar(t(message, options), { variant });
  }
};

export default snackbarUtils;
