import { ProductionOrderAnalyticsRow } from '../index';
import { TreeViewBaseItem } from '@mui/x-tree-view';

export const extractProductionVendors = (
  rows: ProductionOrderAnalyticsRow[]
): TreeViewBaseItem[] => {
  const map = new Map<string, TreeViewBaseItem>();

  const recursion = (row: ProductionOrderAnalyticsRow): void => {
    if (row.children?.length) {
      row.children.forEach((cr) => recursion(cr));
    } else {
      const vendor: TreeViewBaseItem = { label: row.vendor, id: row.vendor, children: [] };
      if (row.vendorGroup) {
        const vendorGroup =
          map.get(row.vendorGroup) ||
          ({ label: row.vendorGroup, id: row.vendorGroup, children: [] } as TreeViewBaseItem);
        if (!vendorGroup.children?.some((c) => c.id === row.vendor)) {
          vendorGroup.children = [...(vendorGroup.children || []), vendor];
        }
        map.set(row.vendorGroup, vendorGroup);
      } else if (row.vendor && !map.has(row.vendor)) {
        map.set(row.vendor, vendor);
      }
    }
  };

  rows.forEach((r) => recursion(r));

  return Array.from(map.values());
};
