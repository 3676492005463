import React, {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactChild,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Grid, Icon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

type Props = {
  renderRow: (props: ListChildComponentProps) => JSX.Element;
} & HTMLAttributes<HTMLElement>;

export const ListboxComponent = forwardRef<HTMLDivElement, Props>(
  function ListboxComponent(props, ref) {
    const { children, renderRow, ...other } = props;
    const itemData: ReactChild[] = [];
    (children as ReactChild[]).forEach((item: ReactChild & { children?: ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

export const renderAutocompleteSimpleRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8
  };
  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Grid container justifyContent="space-between">
        <Grid item>{dataSet[1]}</Grid>
        <Grid item>
          {dataSet[2] && (
            <Icon sx={{ lineHeight: '22px', fontSize: '16px', fontWeight: 'bold' }}>check</Icon>
          )}
        </Grid>
      </Grid>
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
