import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { OrderStatusType } from '../../domain/OrderStatusType';
import { UnitType } from '../../domain/UnitType';

export interface Order {
  id: number;
  orderNumber?: string;
  quoteNumber?: string;
  internalNumber?: string;
  invoiceNumber?: string;
  additionalInfo?: string;
  createdAt: string;
  createdBy: number;
  createdByName?: string;
  requestedDelivery?: string;
  plannedDelivery?: string;
  submittedAtList: string[];
  status: OrderStatusType;
  address: Address;
  orderItems?: OrderItem[];
}

export interface OrderItem {
  customerItemId: number;
  id: number;
  orderId: number;
  price: number;
  quantity: number;
  unit: UnitType;
}

export interface Address {
  id?: number;
  code?: string;
  name?: string;
  street?: string;
  street2?: string;
  city?: string;
  county?: string;
  country?: string;
  postalCode?: string;
}

export interface OrderTableItem {
  id: number;
  address?: string;
  createdAt: string;
  createdBy: string;
  internalNumber?: string;
  orderNumber?: string;
  plannedDelivery?: string;
  quoteNumber?: string;
  status: OrderStatusType;
  availability?: number;
}

export interface ExpandedOrderItem {
  id?: number;
  unit?: UnitType;
  packs?: number;
  quantity?: number;
  availableQuantity?: number;
  price?: number;
  customerItemNumber?: string;
  customerItemName?: string;
  number?: string;
  description?: string;
  translations: { [key: string]: string };
  outstandingQuantity?: number;
  reservedQuantity?: number;
  availability?: string;
}

export interface OrderState {
  filter: OrderFilter;
  isDefaultState?: boolean;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface OrderFilter {
  orderNumber?: string;
  quoteNumber?: string;
  internalNumber?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  createdBy?: string;
  address?: string[];
  plannedDeliveryFrom?: string;
  plannedDeliveryTo?: string;
  status?: OrderStatusType[];
}

export interface OrderUpdateRequest extends OrderCreateRequest {
  orderId: number;
}

export interface OrderCreateRequest {
  address?: Address;
  requestedDelivery?: Date;
  additionalInfo?: string;
  internalNumber?: string;
}

export interface OrderTotalAmountRequest {
  orderId: number;
  unit: UnitType;
}

export const DEFAULT_ORDER_STATUSES = [
  OrderStatusType.DRAFT,
  OrderStatusType.SUBMITTED,
  OrderStatusType.AWAITS_RESPONSE,
  OrderStatusType.ACCEPTED,
  OrderStatusType.SHIPPED
];

const slice = createSlice({
  name: 'orders',
  initialState: {
    filter: { status: DEFAULT_ORDER_STATUSES },
    isDefaultState: true,
    page: 0,
    pageSize: 25,
    sort: [{ field: 'createdAt', sort: 'desc' }]
  } as OrderState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    setDefaultFilterValue: (state, { payload: createdBy }: PayloadAction<string>) => {
      state.filter.createdBy = createdBy;
      state.isDefaultState = false;
    },
    setFilterValue: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: keyof OrderFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setDefaultFilterValue, setFilterValue, setPage, setPageSize, setSort } =
  slice.actions;
