export enum Permission {
  APP = 'APP_MANAGEMENT',
  EMPLOYEE = 'EMPLOYEE_MANAGEMENT',
  CUSTOMER = 'CUSTOMER_MANAGEMENT',
  CUSTOMER_ITEM = 'CUSTOMER_ITEM_MANAGEMENT',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
  ORDER_VIEW = 'ORDER_VIEW',
  STOCK_MANAGEMENT = 'STOCK_MANAGEMENT',
  STOCK_VIEW = 'STOCK_VIEW',
  USER = 'USER_MANAGEMENT',
  ANALYTICS_VIEW = 'ANALYTICS_VIEW'
}
