import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import et from './translations/et.json';
import { getLanguage, Language } from './utils/langUtils';

i18n.use(initReactI18next).init({
  resources: { en, et },
  lng: getLanguage(),
  fallbackLng: Language.EN,
  interpolation: { escapeValue: false },
  returnNull: false
});

export default i18n;
