import React from 'react';
import enUSLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetShipToAddressesQuery } from '../api/apiSlice';
import { useCreateOrderMutation } from '../api/orderApi';
import { Controller, useForm } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { formatAddressString } from '../../utils/addressUtils';
import { useSnackbar } from 'notistack';
import { DATE_FORMAT } from '../../utils/dateUtils';
import { Loader } from '../../components/Loader';
import { OrderCreateRequest } from '../order/orderSlice';
import { Language } from '../../utils/langUtils';

export interface NewOrderModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const NewOrderModal = ({ isOpen, handleClose }: NewOrderModalProps): JSX.Element => {
  const {
    i18n: { language },
    t
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, register, handleSubmit, reset } = useForm();
  const [createOrder, { isLoading: isCreateOrderLoading }] = useCreateOrderMutation();
  const {
    data: addresses,
    isUninitialized: isShipToUninitialized,
    isLoading: isShipToLoading
  } = useGetShipToAddressesQuery();

  const onSubmit = (form: { [key: string]: any }) => {
    createOrder({
      ...form,
      address: form.address ? JSON.parse(form.address) : undefined
    } as OrderCreateRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('stock.newOrder.successfulCreate'), { variant: 'success' });
        handleClose();
        reset();
      })
      .catch(() => {});
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{t('stock.newOrder.title')}</Grid>
          <Grid item>
            <IconButton onClick={handleClose} sx={{ marginTop: '-20px', marginRight: '-24px' }}>
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {(isShipToUninitialized || isShipToLoading || isCreateOrderLoading) && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container className="margin-top-16">
            <Grid item>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>{t('stock.newOrder.shipTo')}</InputLabel>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field }) =>
                        addresses?.length ? (
                          <Select
                            label={t('stock.newOrder.shipTo')}
                            onChange={(value) => field.onChange(value)}
                          >
                            {addresses.map((address, index) => (
                              <MenuItem key={index} value={JSON.stringify(address)}>
                                {formatAddressString(address)}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="requestedDelivery"
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={language === Language.ET ? etLocale : enUSLocale}
                        >
                          <DatePicker
                            format={DATE_FORMAT}
                            label={t('stock.newOrder.requestedDelivery')}
                            minDate={new Date()}
                            value={field.value ? new Date(field.value) : undefined}
                            onChange={(date) => field.onChange(date)}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    id="additionalInfo"
                    label={t('stock.newOrder.additionalInfo')}
                    fullWidth
                    multiline
                    rows={3}
                    {...register('additionalInfo')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="internalNumber"
                    label={t('stock.newOrder.internalNumber')}
                    fullWidth
                    {...register('internalNumber')}
                    helperText={t('stock.newOrder.internalNumberInfo')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" type="button">
          {t('button.close')}
        </Button>
        <Button autoFocus onClick={handleSubmit(onSubmit)}>
          {t('button.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
