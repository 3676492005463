import { FilterProps } from '../../components/Filter';
import { useGetVendorsQuery } from '../api/analyticsApi';
import React, { useCallback, useMemo } from 'react';
import { Autocomplete, Chip, Grid, TextField } from '@mui/material';
import { ResizingPopper } from '../../components/ResizingPopper';
import { ListboxComponent, renderAutocompleteSimpleRow } from '../../components/ListboxComponent';
import { GroupedVendor } from './index';

type VendorSelectProps = { value: string[] } & Omit<FilterProps, 'value'>;

export const VendorSelect = (props: VendorSelectProps) => {
  const { data: vendors } = useGetVendorsQuery();
  const flatVendors = useMemo(
    () => vendors?.flatMap((v) => (v?.children?.length ? v.children : v)) || [],
    [vendors]
  );
  const vendorsSet = useMemo(
    () => new Map<string, GroupedVendor>(flatVendors.map((v) => [v.id, v])),
    [flatVendors]
  );

  const getOptionLabel = useCallback(
    (vendorId: string) => vendorsSet.get(vendorId)?.id || '-',
    [vendorsSet]
  );

  const {
    id,
    label,
    loading,
    multiple,
    onChange,
    value,
    minWidth,
    onFocus,
    limitTags = 1,
    ...rest
  } = props;

  return (
    <Grid item sx={{ minWidth }}>
      <Autocomplete
        {...rest}
        id={id}
        data-testid={id}
        size="small"
        multiple={true as any}
        limitTags={limitTags}
        options={loading ? [] : flatVendors.map((v) => v.id)}
        getOptionLabel={getOptionLabel}
        loading={loading}
        disableCloseOnSelect={multiple}
        renderOption={(props, option, { selected }) =>
          [props, getOptionLabel(option), selected] as React.ReactNode
        }
        renderInput={(params) => <TextField {...params} label={label} />}
        renderTags={(tagValue, getTagProps) => {
          const numTags = tagValue.length;

          return (
            <>
              {tagValue.slice(0, limitTags).map((option, index) => (
                <Chip
                  sx={{ height: '24px', borderRadius: '4px' }}
                  {...getTagProps({ index })}
                  label={getOptionLabel(option)}
                />
              ))}
              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        autoHighlight
        slots={{
          popper: ResizingPopper,
          listbox: (props) => (
            <ListboxComponent renderRow={renderAutocompleteSimpleRow} {...props} />
          )
        }}
      />
    </Grid>
  );
};
