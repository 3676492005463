import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { isValidToken } from '../../utils/authUtils';
import { Permission } from '../../domain/Permission';
import { UnitType } from '../../domain/UnitType';

export interface AuthRequest {
  username: string;
  password: string;
}

export interface ResetPasswordRequest {
  username: string;
}

export interface ChangePasswordRequest {
  verificationToken?: string;
  newPassword: string;
}

export interface User {
  exp: number;
  sub: string;
  personId: number;
  companyName: string;
  companyNumber: string;
  permissions: Permission[];
  defaultUnit?: UnitType;
  customNumbers?: boolean;
  customNames?: boolean;
}

export interface AuthState {
  user?: User;
  token?: string;
}

const getInitialState = (): AuthState => {
  const token = localStorage.getItem('jwt') as string;
  return isValidToken(token)
    ? {
        token,
        user: jwtDecode(token)
      }
    : {};
};

const slice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    setAuthentication: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        const token = payload.replace('Bearer ', '');
        state.token = token;
        state.user = jwtDecode(token);
        localStorage.setItem('jwt', token);
      }
    },
    removeAuthentication: (state) => {
      state.token = undefined;
      state.user = undefined;
      localStorage.removeItem('jwt');
    }
  }
});

export default slice.reducer;
export const { setAuthentication, removeAuthentication } = slice.actions;
