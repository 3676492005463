import { Grid } from '@mui/material';
import { useGetRawMaterialAnalyticsQuery } from '../../api/analyticsApi';
import { RawMaterialAnalyticsFilters } from './RawMaterialAnalyticsFilters';
import { RawMaterialAnalyticsTable } from './RawMaterialAnalyticsTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const RawMaterialAnalytics = () => {
  const { filter, page, pageSize } = useSelector((state: RootState) => state.rawMaterialAnalytics);
  const { data, isFetching } = useGetRawMaterialAnalyticsQuery({ filter, page, pageSize });

  return (
    <Grid container>
      <RawMaterialAnalyticsFilters />
      <RawMaterialAnalyticsTable data={data} isLoading={isFetching} />
    </Grid>
  );
};
