import React, { useState } from 'react';
import { Loader } from '../../components/Loader';
import { Order } from './orderSlice';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Grid, Icon, Typography } from '@mui/material';
import { OrderStatusType } from '../../domain/OrderStatusType';
import {
  useDeleteOrderMutation,
  useDiscardOrderMutation,
  useResubmitOrderMutation,
  useSubmitOrderMutation
} from '../api/orderApi';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { RouteType } from '../../domain/RouteType';
import { useSnackbar } from 'notistack';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

export interface OrderFooterProps {
  order: Order;
  activeStep?: number;
  hasItems?: boolean;
}

export const OrderFooter = ({ order, activeStep, hasItems }: OrderFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteOrder] = useDeleteOrderMutation();
  const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState(false);
  const [submitOrder, { isLoading: isSubmitLoading }] = useSubmitOrderMutation();
  const [resubmitOrder, { isLoading: isResubmitLoading }] = useResubmitOrderMutation();
  const [discardOrder, { isLoading: isDiscardLoading }] = useDiscardOrderMutation();

  const handleEventDeleteOrder = () => {
    deleteOrder(order.id)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('order.successfulDelete'), { variant: 'success' });
        navigate(RouteType.ORDERS);
      })
      .catch(() => {});
  };

  const handleSubmit = () => {
    if (!order.address.code) {
      enqueueSnackbar(t('order.addressMissing'), { variant: 'error' });
    } else if (!hasItems) {
      enqueueSnackbar(t('order.itemsMissing'), { variant: 'error' });
    } else {
      submitOrder(order.id)
        .unwrap()
        .then(() => {
          enqueueSnackbar(t('order.successfulSubmit'), { variant: 'success' });
        })
        .catch(() => {});
    }
  };

  const handleResubmit = () => {
    if (!order.address.code) {
      enqueueSnackbar(t('order.addressMissing'), { variant: 'error' });
    } else if (!hasItems) {
      enqueueSnackbar(t('order.itemsMissing'), { variant: 'error' });
    } else {
      resubmitOrder(order.id)
        .unwrap()
        .then(() => {
          enqueueSnackbar(t('order.successfulSubmit'), { variant: 'success' });
        })
        .catch(() => {});
    }
  };

  const handleDiscard = () => {
    discardOrder(order.id)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('order.successfulDiscard'), { variant: 'success' });
      })
      .catch(() => {});
  };

  return (
    <Grid container justifyContent="space-between" className="margin-top-16 margin-bottom-8">
      {(isSubmitLoading || isResubmitLoading || isDiscardLoading) && <Loader />}
      <Grid item>
        {order.status === OrderStatusType.DRAFT && (
          <>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setConfirmOpen(true)}
              startIcon={<Icon color="secondary">delete_outline</Icon>}
            >
              <Typography variant="button">{t('order.delete')}</Typography>
            </Button>
            <ConfirmDialog
              title={t('order.delete')}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handleEventDeleteOrder}
            >
              {t('order.deleteOrderDialog')}
            </ConfirmDialog>
          </>
        )}
        {order.status === OrderStatusType.AWAITS_RESPONSE && (
          <>
            <Button color="secondary" variant="outlined" onClick={() => handleDiscard()}>
              {t('button.discard')}
            </Button>
            <ConfirmDialog
              title={t('order.delete')}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handleEventDeleteOrder}
            >
              {t('order.deleteOrderDialog')}
            </ConfirmDialog>
          </>
        )}
      </Grid>
      <Grid item>
        {activeStep === 2 &&
          (order.status === OrderStatusType.DRAFT ||
            order.status === OrderStatusType.AWAITS_RESPONSE) && (
            <Grid container>
              <Grid item sx={{ marginTop: '-4px' }}>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(event, checked) => setTermsAccepted(checked)}
                />
              </Grid>
              <Grid item className="margin-right-16 margin-top-6">
                <Grid container>
                  <Grid item className="margin-right-4">
                    {t('order.preview.termsConditionsOne')}
                  </Grid>
                  <Grid
                    item
                    sx={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => setTermsAndConditionsModalOpen(true)}
                  >
                    {t('order.preview.termsConditionsTwo')}
                  </Grid>
                </Grid>
              </Grid>
              {order.status === OrderStatusType.DRAFT && (
                <Button disabled={!termsAccepted} onClick={() => handleSubmit()}>
                  {t('button.submit')}
                </Button>
              )}
              {order.status === OrderStatusType.AWAITS_RESPONSE && (
                <Button disabled={!termsAccepted} onClick={() => handleResubmit()}>
                  {t('button.confirm')}
                </Button>
              )}
            </Grid>
          )}
      </Grid>
      <TermsAndConditionsModal
        isOpen={termsAndConditionsModalOpen}
        handleClose={() => setTermsAndConditionsModalOpen(false)}
      />
    </Grid>
  );
};
