import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ConfirmDialogProps {
  title: string;
  children: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}

export const ConfirmDialog = ({
  title,
  children,
  open,
  setOpen,
  onConfirm
}: ConfirmDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          autoFocus
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {t('button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
