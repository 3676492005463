import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Button, Grid, Icon, IconButton } from '@mui/material';
import {
  DataGridPro,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowSelectionModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import {
  useBulkUpdateCustomerItemsMutation,
  useGetCustomerItemAutocompleteQuery,
  useGetCustomerItemsQuery
} from '../api/apiSlice';
import {
  CustomerItemUpdateRequest,
  resetFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setSort
} from './customerItemSlice';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { validateName, validateNumber } from './itemValidation';
import { pull } from 'lodash';
import { EditCell } from '../../components/EditCell';
import { CustomerItemsFilter } from './CustomerItemsFilter';
import { CustomerItemsHeader } from './CustomerItemsHeader';

export const CustomerItems = (): JSX.Element => {
  let { customerId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const [isSingleEdit, setIsSingleEdit] = useState(false);
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.customerItems);
  const [editModel, setEditModel] = useState<CustomerItemUpdateRequest[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [bulkUpdateItems, { isLoading: isUpdateItemsLoading }] =
    useBulkUpdateCustomerItemsMutation();
  const { data: itemsNames } = useGetCustomerItemAutocompleteQuery({
    fieldName: 'customerItemName',
    customerId
  });
  const { data: itemsNumbers } = useGetCustomerItemAutocompleteQuery({
    fieldName: 'customerItemNumber',
    customerId
  });
  const [names, setNames] = useState([] as string[]);
  const [numbers, setNumbers] = useState([] as string[]);
  const { data, isUninitialized, isLoading, isFetching } = useGetCustomerItemsQuery({
    page,
    pageSize,
    sort,
    filter,
    customerId
  });
  const apiRef = useGridApiRef();
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    dispatch(resetFilter());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'number', headerName: t('items.table.number'), flex: 0.75, sortable: !isEdit },
    {
      field: 'description',
      headerName: t('items.table.description'),
      flex: 5,
      sortable: !isEdit
    },
    {
      field: 'customerItemNumber',
      headerName: t('items.table.customerItemNumber'),
      flex: 1.5,
      sortable: !isEdit,
      editable: isEdit,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = validateNumber(
          params.props.value,
          params.otherFieldsProps?.customerItemName.value,
          numbers,
          t
        );
        return { ...params.props, error: hasError };
      },
      renderEditCell: EditCell
    },
    {
      field: 'customerItemName',
      headerName: t('items.table.customerItemName'),
      flex: 2,
      sortable: !isEdit,
      editable: isEdit,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = validateName(
          params.props.value,
          params.otherFieldsProps?.customerItemNumber.value,
          names,
          t
        );
        return { ...params.props, error: hasError };
      },
      renderEditCell: EditCell
    },
    {
      field: 'customerItemType',
      headerName: t('items.table.customerItemType'),
      flex: 1.5,
      sortable: !isEdit,
      editable: isEdit
    },
    {
      field: 'active',
      headerName: t('items.table.active'),
      type: 'boolean',
      sortable: !isEdit,
      editable: isEdit,
      flex: 0.75
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      flex: 0.5,
      align: 'right',
      renderCell: (params) => {
        if (isSingleEdit) {
          return (
            <div>
              <IconButton
                onClick={() => {
                  onCancelEdit(true);
                }}
              >
                <Icon>highlight_off_icon</Icon>
              </IconButton>
              <IconButton
                onClick={() => {
                  onSaveEditedItems(true);
                }}
              >
                <Icon>check_circle</Icon>
              </IconButton>
            </div>
          );
        } else if (!isEdit) {
          return (
            <IconButton onClick={() => handleEditButtonClick(params)}>
              <Icon>edit</Icon>
            </IconButton>
          );
        }
      }
    }
  ];

  const onBulkActivate = (active: boolean) => {
    const body = selectionModel.map((itemId) => {
      const item = data?.content.find((e) => e.id === +itemId);
      return {
        itemId: +itemId,
        active: active,
        number: item?.customerItemNumber,
        name: item?.customerItemName,
        type: item?.customerItemType
      };
    });

    bulkUpdateItems({ body, companyId: +customerId! })
      .unwrap()
      .then(() => {
        setSelectionModel([]);
        enqueueSnackbar(t('items.updateSuccess'), { variant: 'success' });
      });
  };

  const handleRowChange = (newRow: any) => {
    let itemIndex = editModel.findIndex((e) => e.itemId === newRow.id);
    let item = {
      itemId: newRow.id,
      active: newRow.active,
      number: newRow.customerItemNumber || undefined,
      name: newRow.customerItemName || undefined,
      type: newRow.customerItemType || undefined
    };
    if (itemIndex === -1) {
      editModel.push(item);
    } else {
      editModel[itemIndex] = item;
    }

    if (item.name) {
      names.push(item.name.trim());
    }
    if (item.number) {
      numbers.push(item.number.trim());
    }
    return newRow;
  };

  const handleCellClick = (data: any) => {
    isEdit &&
      data.isEditable &&
      apiRef.current.getRowMode(data.id) === 'view' &&
      apiRef.current.startRowEditMode({ id: data.id });

    if (data.row.customerItemName !== null) {
      setNames(pull(names, data.row.customerItemName));
    }
    if (data.row.customerItemNumber !== null) {
      setNumbers(pull(numbers, data.row.customerItemNumber));
    }
  };

  const onSaveEditedItems = (isSingle: boolean) => {
    setTimeout(
      () =>
        bulkUpdateItems({ body: editModel, companyId: +customerId! })
          .unwrap()
          .then(() => {
            setIsEdit(false);
            setEditModel([]);
            if (isSingle) {
              setIsSingleEdit(false);
              dispatch(setFilterValue({ field: 'number', value: [] }));
            }
            enqueueSnackbar(t('items.updateSuccess'), { variant: 'success' });
          })
          .catch(),
      250
    );
  };

  const handleEditButtonClick = (params?: GridRenderCellParams) => {
    if (params) {
      dispatch(setFilterValue({ field: 'number', value: [params.row.number] }));
      setIsSingleEdit(true);
    }

    setIsEdit(true);
    if (initial) {
      setNames(itemsNames?.map((name) => name.trim()) || []);
      setNumbers(itemsNumbers?.map((number) => number.trim()) || []);
      setInitial(false);
    }
  };

  const onCancelEdit = (isSingle: boolean) => {
    setIsEdit(false);
    setInitial(true);
    setEditModel([]);
    if (isSingle) {
      setIsSingleEdit(false);
      dispatch(setFilterValue({ field: 'number', value: [] }));
    }
  };

  return (
    <Grid container>
      <CustomerItemsHeader />
      {(isUninitialized || isUpdateItemsLoading) && <Loader data-testid="loader" />}
      <CustomerItemsFilter disabled={isEdit} />

      <Grid container className="margin-top-16">
        <Grid item xs>
          <h2>{t('items.table.title')}</h2>
        </Grid>

        {!isEdit && (
          <>
            <Grid item className="margin-8">
              <Button onClick={() => onBulkActivate(true)} disabled={selectionModel.length === 0}>
                {t('items.table.activate')}
              </Button>
            </Grid>

            <Grid item className="margin-8">
              <Button onClick={() => onBulkActivate(false)} disabled={selectionModel.length === 0}>
                {t('items.table.deactivate')}
              </Button>
            </Grid>

            <Grid item className="margin-left-8 margin-top-8">
              <Button onClick={() => handleEditButtonClick()} startIcon={<Icon>edit</Icon>}>
                {t('items.table.edit')}
              </Button>
            </Grid>
          </>
        )}

        {isEdit && !isSingleEdit && (
          <>
            <Grid item className="margin-8">
              <Button variant="outlined" onClick={() => onCancelEdit(false)}>
                {t('items.table.cancel')}
              </Button>
            </Grid>

            <Grid item className="margin-8">
              <Button onClick={() => onSaveEditedItems(false)}>{t('items.table.save')}</Button>
            </Grid>
          </>
        )}
      </Grid>

      <Grid container>
        <Grid item xs sx={{ height: '60vh' }}>
          <DataGridPro
            loading={isLoading || isFetching}
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            filterMode="server"
            pagination={!isEdit}
            paginationMode="server"
            sortingMode="server"
            sortModel={sort}
            columnHeaderHeight={46}
            rowHeight={40}
            rowCount={data?.totalElements || 0}
            rows={(data?.content || []) as GridRowsProp}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            disableVirtualization={process.env.NODE_ENV === 'test'}
            checkboxSelection={!isEdit}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
            processRowUpdate={handleRowChange}
            onCellClick={handleCellClick}
            editMode="row"
            apiRef={apiRef}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
