import { FrequencyMap, WithVendor } from './index';
import { MaterialReactTableProps, MRT_FilterFn, MRT_RowData } from 'material-react-table';
import { TreeViewBaseItem } from '@mui/x-tree-view';

type ListFilterInput = ((row: any) => string) | string;

export const listFilter =
  (keyAccessor: ListFilterInput): MRT_FilterFn<MRT_RowData> =>
  (row, id, filterValue): boolean => {
    if (!filterValue?.length) {
      return true;
    }

    const filteredValuesSet = new Set(filterValue);
    const key =
      keyAccessor instanceof Function ? keyAccessor(row.original) : row.original[keyAccessor];
    return filteredValuesSet.has(key);
  };

export const extractVendors = (rows: WithVendor[]): TreeViewBaseItem[] => {
  const map = new Map<string, TreeViewBaseItem>();

  rows?.forEach((row) => {
    const vendor: TreeViewBaseItem = { label: row.vendor, id: row.vendor, children: [] };
    if (row.vendorGroup) {
      const vendorGroup =
        map.get(row.vendorGroup) ||
        ({ label: row.vendorGroup, id: row.vendorGroup, children: [] } as TreeViewBaseItem);
      if (!vendorGroup.children?.some((c) => c.id === row.vendor)) {
        vendorGroup.children = [...(vendorGroup.children || []), vendor];
      }
      map.set(row.vendorGroup, vendorGroup);
    } else if (!map.has(row.vendor)) {
      map.set(row.vendor, vendor);
    }
  });

  return Array.from(map.values());
};

export const combineFrequencyMaps = (frequencyMaps?: FrequencyMap[]): FrequencyMap => {
  const result: FrequencyMap = {};

  frequencyMaps?.forEach((fm) =>
    Object.entries(fm || {}).forEach(
      ([score, frequency]) => (result[score] = (result[score] || 0) + frequency)
    )
  );

  return result;
};

export const standardDeviationFromFrequencyMap = (dataSet: FrequencyMap): number => {
  const frequencySum = Object.values(dataSet).reduce(sum, 0);
  const fxSum = Object.entries(dataSet)
    .map(([score, frequency]) => Number(score) * frequency)
    .reduce(sum, 0);

  const fxSquare = Object.entries(dataSet)
    .map(([score, frequency]) => Math.pow(Number(score), 2) * frequency)
    .reduce(sum, 0);

  return Math.sqrt(fxSquare / frequencySum - Math.pow(fxSum / frequencySum, 2));
};

export const maxScoreFromFrequencyMap = (dataSet: FrequencyMap): number | undefined => {
  return Math.max(...Object.keys(dataSet).map((key) => Number(key)));
};

export const averageFromFrequencyMap = (dataSet: FrequencyMap): number | undefined => {
  const total = Object.values(dataSet).reduce(sum, 0);

  if (total > 0) {
    const buffer = Object.entries(dataSet)
      .map(([score, frequency]) => Number(score) * frequency)
      .reduce(sum, 0);

    return buffer / total;
  }

  return undefined;
};

export const round = (value: number | undefined, decimalPlaces: number = 1): number => {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round((value || 0) * multiplier + Number.EPSILON) / multiplier;
};

export const sum = (a: number, b: number) => a + b;

export const splitValueOrEmptyArray = (value?: string) => (!!value?.length ? value.split(',') : []);

export const materialReactTableBaseProps: any = {
  layoutMode: 'grid',
  columnFilterDisplayMode: 'popover',
  enableTopToolbar: false,
  enableExpanding: true,
  enablePagination: true,
  manualPagination: true,
  enableColumnResizing: true,
  filterFromLeafRows: true,
  enableRowPinning: false,
  enableColumnDragging: false,
  paginateExpandedRows: false,
  enableExpandAll: true,
  manualExpanding: false,
  enableColumnActions: false,
  defaultColumn: {
    muiTableHeadCellProps: { className: 'wordwrap' },
    filterVariant: 'multi-select',
    enableColumnFilter: false,
    enableHiding: false,
    enableSorting: false,
    grow: true
  },
  muiTableBodyCellProps: ({ cell }) => ({
    sx: { padding: { paddingTop: '3px', paddingBottom: '3px' } }
  }),
  displayColumnDefOptions: {
    'mrt-row-expand': { size: 30, maxSize: 50, grow: false, enableResizing: false }
  },
  muiExpandButtonProps: (props) => (!props.row.subRows?.length ? { className: 'hidden' } : {})
} as Partial<MaterialReactTableProps<any>>;
