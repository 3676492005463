import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Button, Chip, Grid, Icon, IconButton, Switch, Typography } from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid-pro';
import {
  useGetEmployeeAutocompleteQuery,
  useGetEmployeesQuery,
  useInviteMutation,
  useSyncEmployeesMutation,
  useUpdateRoleMutation
} from '../api/apiSlice';
import {
  clearFilter,
  EmployeeFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setSort
} from './employeeSlice';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { StatusType } from '../../domain/StatusType';
import { EMPLOYEE_ROLES, RoleType } from '../../domain/RoleType';
import { useSnackbar } from 'notistack';
import { Filter } from '../../components/Filter';
import { FilterArea } from '../../components/FilterArea';
import { Loader } from '../../components/Loader';
import Tooltip from '@mui/material/Tooltip';

export const Employees = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.employees);
  const { data, isUninitialized, isLoading, isFetching } = useGetEmployeesQuery({
    page,
    pageSize,
    sort,
    filter
  });
  const [updateRole, { isLoading: isUpdateLoading }] = useUpdateRoleMutation();
  const { data: nameOptions, isFetching: isNameOptionsLoading } =
    useGetEmployeeAutocompleteQuery('name');
  const { data: emailOptions, isFetching: isEmailOptionsLoading } =
    useGetEmployeeAutocompleteQuery('email');
  const [syncEmployees, { isLoading: isSyncLoading }] = useSyncEmployeesMutation();
  const [invite, { isLoading: isInviteLoading }] = useInviteMutation();

  const onUpdateRole = (employeeId: number, isAdmin: boolean) => {
    updateRole({ employeeId, isAdmin })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('employees.updateRoleSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onSyncEmployees = () => {
    syncEmployees()
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('employees.syncSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const onInvite = (personId: number) => {
    invite(personId)
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('employees.inviteSuccess'), { variant: 'success' });
      })
      .catch(() => {});
  };

  const isPending: GridValueGetter = (value, row, column, apiRef): boolean =>
    row.status === StatusType.PENDING && row.email;

  const handleFilterChange = (
    field: keyof EmployeeFilter,
    event: any,
    value: string[] | boolean | StatusType[]
  ) => dispatch(setFilterValue({ field, value }));

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('employees.table.name'), flex: 2 },
    { field: 'email', headerName: t('employees.table.email'), flex: 2 },
    { field: 'phone', headerName: t('employees.table.phone'), flex: 2 },
    {
      field: 'roles',
      headerName: t('employees.table.role'),
      description: t('employees.table.roleDescription'),
      flex: 2,
      renderCell: (params: GridRenderCellParams<RoleType[]>) =>
        params.value?.map((role) => (
          <Tooltip followCursor title={t(`employees.table.roleDescription${role}`)}>
            <Chip label={t(`roleType.${role}`)} size="small" variant="filled" />
          </Tooltip>
        ))
    },
    {
      field: 'status',
      headerName: t('employees.table.status'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, StatusType>) => (
        <Chip label={t(`statusType.${params.value}`)} size="small" variant="filled" />
      )
    },
    {
      field: 'isAdmin',
      headerName: t('employees.table.isAdmin'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <Switch
          disabled={params.row.id === user?.personId}
          checked={params.row.roles?.includes(RoleType.ADMIN)}
          size="small"
          data-testid={`switch-${params.row.id}`}
          onClick={(event: any) => onUpdateRole(params.row.employeeId, event.target.checked)}
        />
      )
    },
    {
      field: 'isPending',
      headerName: '',
      flex: 0.5,
      align: 'right',
      sortable: false,
      valueGetter: isPending,
      renderCell: (params: GridRenderCellParams<any, boolean>) =>
        params.value && (
          <IconButton onClick={() => onInvite(params.row.id)}>
            <Icon>email</Icon>
          </IconButton>
        )
    }
  ];

  return (
    <Grid container>
      {(isUninitialized || isUpdateLoading || isSyncLoading || isInviteLoading) && <Loader />}
      <FilterArea onClear={() => dispatch(clearFilter())}>
        <Filter
          id="name"
          label={t('employees.table.name')}
          loading={isNameOptionsLoading}
          minWidth="260px"
          multiple
          options={nameOptions}
          value={filter.name || []}
          onChange={handleFilterChange.bind(this, 'name')}
        />
        <Filter
          id="email"
          label={t('employees.table.email')}
          loading={isEmailOptionsLoading}
          minWidth="260px"
          multiple
          options={emailOptions}
          value={filter.email || []}
          onChange={handleFilterChange.bind(this, 'email')}
        />
        <Filter
          id="roles"
          label={t('employees.table.role')}
          minWidth="180px"
          optionLabelPrefix="roleType."
          options={EMPLOYEE_ROLES}
          value={filter.role}
          onChange={handleFilterChange.bind(this, 'role')}
        />
        <Filter
          id="status"
          label={t('employees.table.status')}
          minWidth="180px"
          multiple
          optionLabelPrefix="statusType."
          options={Object.values(StatusType)}
          value={filter.status || []}
          onChange={handleFilterChange.bind(this, 'status')}
        />
      </FilterArea>

      <Grid container justifyContent="space-between" alignItems="center" className="margin-top-16">
        <Grid item>
          <h2>{t('employees.table.title')}</h2>
        </Grid>

        <Grid item className="margin-bottom-8">
          <Button onClick={() => onSyncEmployees()} startIcon={<Icon>cached</Icon>}>
            <Typography variant="button">{t('employees.table.sync')}</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs sx={{ height: '65vh' }}>
          <DataGridPro
            loading={isLoading || isFetching}
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            filterMode="server"
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={sort}
            columnHeaderHeight={46}
            rowHeight={40}
            rowCount={data?.totalElements || 0}
            rows={(data?.content || []) as GridRowsProp}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            disableVirtualization={process.env.NODE_ENV === 'test'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
