import { Popper } from '@mui/material';

export const ResizingPopper = (props: any): JSX.Element => {
  return (
    <Popper
      {...props}
      placement="bottom-start"
      modifiers={[
        {
          name: 'resizeAfterParent',
          enabled: true,
          phase: 'beforeRead',
          fn({ state }) {
            state.styles.popper.width = `${(state.elements.reference as Element).clientWidth}px`;
          }
        }
      ]}
    />
  );
};
