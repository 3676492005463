import jwtDecode from 'jwt-decode';
import { Permission } from '../domain/Permission';
import { RouteType } from '../domain/RouteType';
import { User } from '../features/auth/authSlice';

export const hasAnyPermission = (
  requiredPermissions: Permission[] | undefined,
  permissions: Permission[] | undefined
): boolean => {
  return (
    !requiredPermissions ||
    requiredPermissions.length === 0 ||
    requiredPermissions.some((permission) => permissions?.includes(permission))
  );
};

export const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const user = jwtDecode<User>(token);
  return Date.now() < user.exp * 1000;
};

export const getDefaultRouteByPermission = (permissions: Permission[] | undefined): RouteType => {
  if (hasAnyPermission([Permission.CUSTOMER], permissions)) {
    return RouteType.CUSTOMERS;
  } else {
    return RouteType.ORDERS;
  }
};
