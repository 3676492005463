import { GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from '../../domain/StatusType';
import { UnitType } from '../../domain/UnitType';

export interface Customer {
  id: number;
  name?: string;
  number?: string;
  country?: string;
  salesperson?: string;
  salesCoordinator?: string;
  unit?: UnitType;
  status: StatusType;
  activeItems?: number;
}

export interface CustomerState {
  filter: CustomerFilter;
  page: number;
  pageSize: number;
  sort: GridSortModel;
}

export interface CustomerFilter {
  number?: string;
  country?: string;
  salesperson?: string;
  salesCoordinator?: string;
  status?: StatusType[];
}

export interface UpdateUnitRequest {
  companyId: number;
  unit: UnitType;
}

const slice = createSlice({
  name: 'customers',
  initialState: {
    filter: {},
    page: 0,
    pageSize: 25,
    sort: [{ field: 'number', sort: 'asc' }]
  } as CustomerState,
  reducers: {
    clearFilter: (state) => {
      state.filter = {};
    },
    setFilterValue: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: keyof CustomerFilter; value: any }>
    ) => {
      state.filter[field] = value;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
      state.sort = payload;
    }
  }
});

export default slice.reducer;
export const { clearFilter, setFilterValue, setPage, setPageSize, setSort } = slice.actions;
