import React, { useMemo, useState } from 'react';
import { Box, Button, Checkbox, TextField } from '@mui/material';
import { TreeViewBaseItem } from '@mui/x-tree-view';
import { MRT_Column, MRT_Header, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import { useTranslation } from 'react-i18next';

export const FILTER_WINDOW_MIN_HEIGHT = 100;
export const FILTER_WINDOW_MAX_HEIGHT = 300;
export const FILTER_WINDOW_MIN_WIDTH = 300;
export const FILTER_SX_VALUE = {
  minHeight: FILTER_WINDOW_MIN_HEIGHT,
  maxHeight: FILTER_WINDOW_MAX_HEIGHT,
  minWidth: FILTER_WINDOW_MIN_WIDTH,
  overflowY: 'scroll'
};

export type TreeSelectProps<TData extends MRT_RowData, TValue> = {
  column: MRT_Column<TData, TValue>;
  header: MRT_Header<TData>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<TData>;
  values: TreeViewBaseItem[];
};

const matchesSearch = (row: TreeViewBaseItem, criteria: RegExp): boolean => {
  return (
    criteria.test(row.label) || row.children?.some((cRow) => matchesSearch(cRow, criteria)) || false
  );
};

export const TreeFilter = <TData extends MRT_RowData, TValue>(
  props: TreeSelectProps<TData, TValue>
) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const regex = useMemo(() => new RegExp(search, 'i'), [search]);
  const filteredItems = useMemo(() => {
    return search ? props.values.filter((row) => matchesSearch(row, regex)) : props.values;
  }, [search, props.values, regex]);
  const selectedItems = props.column.getFilterValue() as string[];
  const selectedItemsSet = new Set(selectedItems);

  const renderChildren = (item: TreeViewBaseItem, depth: number) => {
    const checked = item?.children?.length
      ? item?.children?.every((i) => selectedItemsSet.has(i.id))
      : selectedItemsSet.has(item.id);
    const intermediate = item?.children && item?.children?.some((i) => selectedItemsSet.has(i.id));

    const onChange = (checked: boolean) => {
      if (item.children?.length) {
        if (checked) {
          item.children.forEach((i) => selectedItemsSet.add(i.id));
        } else {
          item.children.forEach((i) => selectedItemsSet.delete(i.id));
        }
      } else {
        if (checked) {
          selectedItemsSet.add(item.id);
        } else {
          selectedItemsSet.delete(item.id);
        }
      }

      props.column.setFilterValue(Array.from(selectedItemsSet.values()));
    };

    return (
      <div key={item.id} style={{ marginLeft: `${depth * 15}px` }}>
        <div>
          <label>
            <Checkbox
              checked={checked}
              indeterminate={!checked && intermediate}
              onChange={(event, checked) => onChange(checked)}
            />
            {item.label}
          </label>
        </div>
        {item?.children?.length
          ? item.children.map((ch) => renderChildren(ch, depth + 1))
          : undefined}
      </div>
    );
  };

  return (
    <div>
      <div>
        <TextField
          type="search"
          size="small"
          value={search}
          fullWidth={true}
          placeholder="Otsi"
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <Box sx={FILTER_SX_VALUE} style={{ marginTop: '5px', marginBottom: '10px' }}>
        <div>{filteredItems.map((p) => renderChildren(p, 0))}</div>
      </Box>
      <div style={{ display: 'flex' }}>
        <Button size="small" onClick={() => props.column.setFilterValue(undefined)}>
          {t('button.reset')}
        </Button>
      </div>
    </div>
  );
};
