import { MRT_Row, MRT_RowModel } from 'material-react-table';
import { ProductionOrderAnalyticsRow } from './index';

type NoInfer<T> = [T][T extends any ? 0 : never];

const memo = <TDeps extends readonly any[], TDepArgs, TResult>(
  getDeps: (depArgs?: TDepArgs) => [...TDeps],
  fn: (...args: NoInfer<[...TDeps]>) => TResult,
  opts: {
    key: any;
    debug?: () => any;
    onChange?: (result: TResult) => void;
  }
): ((depArgs?: TDepArgs) => TResult) => {
  let deps: any[] = [];
  let result: TResult | undefined;

  return (depArgs) => {
    const newDeps = getDeps(depArgs);

    const depsChanged =
      newDeps.length !== deps.length ||
      newDeps.some((dep: any, index: number) => deps[index] !== dep);

    if (!depsChanged) {
      return result!;
    }

    deps = newDeps;

    result = fn(...newDeps);
    opts?.onChange?.(result);

    return result!;
  };
};

const expandRows = <T extends ProductionOrderAnalyticsRow>(
  rowModel: MRT_RowModel<T>
): MRT_RowModel<T> => {
  const expandedRows: Array<MRT_Row<T>> = [];

  const handleRow = (row: MRT_Row<T>): void => {
    expandedRows.push(row);

    if (row.subRows?.length && row.getIsExpanded()) {
      row.subRows.forEach(handleRow);
    }
  };

  rowModel.rows.forEach(handleRow);

  return {
    rows: expandedRows,
    flatRows: rowModel.flatRows,
    rowsById: rowModel.rowsById
  };
};

// The table parameter should have type MRT_Table, but it is not exported
// @ts-ignore
export const getExpandedRowModel = (table) =>
  memo(
    () => [
      table.getState().expanded,
      table.getPreExpandedRowModel(),
      table.options.paginateExpandedRows,
      table.options.manualPagination
    ],
    (expanded, rowModel, paginateExpandedRows, manualPagination) => {
      if (!rowModel?.rows?.length || (expanded !== true && !Object.keys(expanded ?? {}).length)) {
        return rowModel;
      }

      // Here is the fix
      if (!paginateExpandedRows && !manualPagination) {
        // Only expand rows at this point if they are being paginated
        return rowModel;
      }

      return expandRows(rowModel);
    },
    {
      key: 'getExpandedRowModel',
      debug: () => table.options.debugAll ?? table.options.debugTable
    }
  );
