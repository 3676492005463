import React from 'react';
import enUSLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Order, OrderUpdateRequest } from './orderSlice';
import { useGetShipToAddressesQuery } from '../api/apiSlice';
import { useUpdateOrderMutation } from '../api/orderApi';
import { Controller, useForm } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { formatAddressString } from '../../utils/addressUtils';
import { useSnackbar } from 'notistack';
import { DATE_FORMAT } from '../../utils/dateUtils';
import { Loader } from '../../components/Loader';
import { isValid } from 'date-fns';
import { Language } from '../../utils/langUtils';

export interface OrderDeliveryProps {
  order: Order;
}

export const OrderDelivery = ({ order }: OrderDeliveryProps): JSX.Element => {
  const {
    i18n: { language },
    t
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, register, handleSubmit } = useForm({ mode: 'onBlur' });
  const [updateOrder] = useUpdateOrderMutation();
  const {
    data: addresses,
    isUninitialized: isShipToUninitialized,
    isLoading: isShipToLoading
  } = useGetShipToAddressesQuery();

  const onFieldChange = (orderUpdateRequest: { [key: string]: any }) => {
    updateOrder({
      ...orderUpdateRequest,
      address: orderUpdateRequest.address
        ? JSON.parse(orderUpdateRequest.address)
        : order.address
          ? order.address
          : undefined,
      orderId: order.id
    } as OrderUpdateRequest)
      .unwrap()
      .then(() => enqueueSnackbar(t('order.delivery.successfulSave'), { variant: 'success' }))
      .catch(() => {});
  };

  return (
    <form onSubmit={handleSubmit(onFieldChange)} noValidate className="margin-top-8 width-100">
      {(isShipToUninitialized || isShipToLoading) && <Loader />}
      <Grid container>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required>{t('order.delivery.shipTo')}</InputLabel>
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) =>
                    addresses?.length ? (
                      <Select
                        value={
                          !!order.address.code ? JSON.stringify({ ...order.address, id: null }) : ''
                        }
                        label={t('order.delivery.shipTo')}
                        onChange={(value) => {
                          field.onChange(value);
                          handleSubmit(onFieldChange)();
                        }}
                      >
                        {addresses.map((address, index) => (
                          <MenuItem key={index} value={JSON.stringify(address)}>
                            {formatAddressString(address)}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <></>
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="requestedDelivery"
                  defaultValue={order.requestedDelivery}
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={language === Language.ET ? etLocale : enUSLocale}
                    >
                      <DatePicker
                        format={DATE_FORMAT}
                        label={t('order.delivery.requestedDelivery')}
                        minDate={new Date()}
                        value={field.value ? new Date(field.value) : undefined}
                        onChange={(date) => {
                          field.onChange(date);
                          if (date && isValid(date) && date > new Date()) {
                            handleSubmit(onFieldChange)();
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                id="additionalInfo"
                label={t('order.delivery.additionalInfo')}
                fullWidth
                multiline
                rows={3}
                defaultValue={order.additionalInfo}
                {...register('additionalInfo')}
                onBlur={handleSubmit(onFieldChange)}
              />
            </Grid>
            <Grid item>
              <TextField
                id="internalNumber"
                label={t('order.delivery.internalNumber')}
                fullWidth
                defaultValue={order.internalNumber}
                {...register('internalNumber')}
                onBlur={handleSubmit(onFieldChange)}
                helperText={t('order.delivery.internalNumberInfo')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
