import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from '../components/errors/NotFound';
import { Login } from '../features/auth/Login';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Employees } from '../features/employee/Employees';
import { Box, Icon, Toolbar } from '@mui/material';
import { getDefaultRouteByPermission } from '../utils/authUtils';
import { Users } from '../features/user/Users';
import { Customers } from '../features/customer/Customers';
import { CustomerItems } from '../features/customerItem/CustomerItems';
import { useSelector } from 'react-redux';
import { Permission } from '../domain/Permission';
import { RootState } from '../store';
import { RouteType } from '../domain/RouteType';
import { Orders } from '../features/order/Orders';
import { NewPassword } from '../features/auth/NewPassword';
import { Order } from '../features/order/Order';
import { Stock } from '../features/stock/Stock';
import { MoistureAnalytics } from '../features/analytics/moisture/MoistureAnalytics';
import { PackageTracing } from '../features/analytics/packageTracing/PackageTracing';
import { RawMaterialAnalytics } from '../features/analytics/rawMaterial/RawMaterialAnalytics';
import { ProductionAnalytics } from '../features/analytics/production/ProductionAnalytics';
import { RawMaterialAnalyticsDetails } from '../features/analytics/rawMaterial/details/RawMaterialAnalyticsDetails';
import { ProductionOrderDetailsPage } from '../features/analytics/production/details/ProductionOrderDetailsPage';

export interface RouteProps {
  key: string;
  path: string;
  icon?: JSX.Element;
  element: JSX.Element;
  permissions?: Permission[];
  children?: RouteProps[];
}

export const ROUTES: RouteProps[] = [
  {
    key: 'ORDERS',
    path: RouteType.ORDERS,
    icon: <Icon>shopping_cart</Icon>,
    element: <Orders />,
    permissions: [Permission.ORDER_VIEW],
    children: [
      {
        key: 'ORDER_DETAILS',
        path: RouteType.ORDER_DETAILS,
        permissions: [Permission.ORDER_VIEW],
        element: <Order />
      }
    ]
  },
  {
    key: 'CUSTOMERS',
    path: RouteType.CUSTOMERS,
    icon: <Icon>settings</Icon>,
    element: <Customers />,
    permissions: [Permission.CUSTOMER],
    children: [
      {
        key: 'CUSTOMER_DETAILS',
        path: RouteType.CUSTOMER_DETAILS,
        permissions: [Permission.CUSTOMER_ITEM],
        element: <CustomerItems />
      }
    ]
  },
  {
    key: 'USERS',
    path: RouteType.USERS,
    icon: <Icon>group</Icon>,
    element: <Users />,
    permissions: [Permission.USER]
  },
  {
    key: 'EMPLOYEES',
    path: RouteType.EMPLOYEES,
    icon: (
      <Icon>
        <i className="ri-user-star-line" />
      </Icon>
    ),
    element: <Employees />,
    permissions: [Permission.EMPLOYEE]
  },
  {
    key: 'STOCK',
    path: RouteType.STOCK,
    icon: <Icon>warehouse</Icon>,
    element: <Stock />,
    permissions: [Permission.STOCK_VIEW]
  },
  {
    key: 'RAW_MATERIAL_ANALYTICS',
    path: RouteType.RAW_MATERIAL_ANALYTICS,
    icon: <Icon>analytics</Icon>,
    element: <RawMaterialAnalytics />,
    permissions: [Permission.ANALYTICS_VIEW],
    children: [
      {
        key: 'RAW_MATERIAL_ANALYTICS_COMPARISON',
        path: RouteType.RAW_MATERIAL_ANALYTICS_COMPARISON,
        element: <RawMaterialAnalyticsDetails />,
        permissions: [Permission.ANALYTICS_VIEW]
      }
    ]
  },
  {
    key: 'PRODUCTION_ANALYTICS',
    path: RouteType.PRODUCTION_ANALYTICS,
    icon: <Icon>analytics</Icon>,
    element: <ProductionAnalytics />,
    permissions: [Permission.ANALYTICS_VIEW],
    children: [
      {
        key: 'PRODUCTION_ANALYTICS_ERRORS',
        path: RouteType.PRODUCTION_ANALYTICS_ERRORS,
        icon: <Icon>analytics</Icon>,
        element: <ProductionOrderDetailsPage />,
        permissions: [Permission.ANALYTICS_VIEW]
      }
    ]
  },
  {
    key: 'MOISTURE_ANALYTICS',
    path: RouteType.MOISTURE_ANALYTICS,
    icon: <Icon>analytics</Icon>,
    element: <MoistureAnalytics />,
    permissions: [Permission.ANALYTICS_VIEW]
  },
  {
    key: 'PACKAGE_TRACING',
    path: RouteType.PACKAGE_TRACING,
    icon: <Icon>analytics</Icon>,
    element: <PackageTracing />,
    permissions: [Permission.ANALYTICS_VIEW]
  }
];

export const RenderRoutes = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate replace to={getDefaultRouteByPermission(user?.permissions)} />}
      />
      <Route path={RouteType.LOGIN} element={<Login />} />
      <Route path={RouteType.NEW_PASSWORD} element={<NewPassword />} />
      {ROUTES.map((route) => (
        <React.Fragment key={route.key}>
          <Route path={route.path} element={<Layout element={route.element} />} />
          {route.children?.map((child) => (
            <Route key={child.key} path={child.path} element={<Layout element={child.element} />} />
          ))}
        </React.Fragment>
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Layout = ({ element }: { element: JSX.Element }): JSX.Element => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar />
        <Box component="main" className="padding-16">
          {element}
        </Box>
      </Box>
    </Box>
  );
};
